import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import api from '../../api';
import qs from 'qs';
import { usePrevious } from '../../hooks';
import AppContext from '../../AppContext';
import SubmitSelect from './SubmitSelect';
import { formatPercentToOneDecimal, formatToString, timeOfPreviousPeriod, calcPercentDiff,
  htmlAttrsForIdentifier, fixedAttributeFormatter, compileQueryString } from '../../utils';
import ArrowGridItem from './ArrowGridItem';
import ReplayLinkGridItem from '../../Components/ReplayLinkGridItem';
import SkeletonValue from './SkeletonValue';
import SkeletonField from './SkeletonField';
import InfoPopover from './InfoPopover';
import { VscWarning } from 'react-icons/vsc';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { AiOutlineLineChart } from 'react-icons/ai';
import SessionReplayCTAModal from '../../Components/SessionReplayCTAModal';
import ChartTip from '../../Components/ChartTip';
import { emptySessionFilters } from '../SessionReplay/SessionsQueryNav';
import './Headlines.scss';

const Headlines = ({mixpanel,
  submitFieldChangeFromSelect, setSubmitFieldChangeFromSelect,
  setSubmitField, submitField, prevSubmitField,
  topAbandonData, topAbandonLoading, topAbandonError,
  topFieldReturnsGapData, topFieldReturnsGapLoading, highestReturnRateField, topFieldReturnsGapError,
  topFieldsAfterSubmitData, topFieldsAfterSubmitLoading, topFieldsAfterSubmitError,
  setTopFieldsAfterSubmitLoading, loadTopFieldsAfterSubmit,
}) => {
  const {
    query,
    formsGroupedByFormUuid,
  } = useContext(AppContext);
  const prevQuery = usePrevious(query);
  const prevTopAbandonData = usePrevious(topAbandonData);
  const prevHighestReturnRateField = usePrevious(highestReturnRateField);
  const location = useLocation();
  const { form: formInParams} = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [views, setViews] = useState();
  const [viewsLoading, setViewsLoading] = useState(true);
  const [viewsError, setViewsError] = useState();
  const [stcRate, setStcRate] = useState();
  const [stcRateLoading, setStcRateLoading] = useState(true);
  const [stcRateError, setStcRateError] = useState();
  const [stcRateDesktop, setStcRateDesktop] = useState();
  const [stcRateDesktopLoading, setStcRateDesktopLoading] = useState(true);
  const [stcRateDesktopError, setStcRateDesktopError] = useState();
  const [stcRateMobile, setStcRateMobile] = useState();
  const [stcRateMobileLoading, setStcRateMobileLoading] = useState(true);
  const [stcRateMobileError, setStcRateMobileError] = useState();
  const [submissions, setSubmissions] = useState();
  const [submissionsLoading, setSubmissionsLoading] = useState(true);
  const [submissionsError, setSubmissionsError] = useState();


  const [topAbandonPreviousData, setTopAbandonPreviousData] = useState();
  const [topAbandonPreviousLoading, setTopAbandonPreviousLoading] = useState();
  const [returnRatePreviousData, setReturnRatePreviousData] = useState();
  const [returnRatePreviousLoading, setReturnRatePreviousLoading] = useState();

  const [showSessionReplayCTAModal, setShowSessionReplayCTAModal] = useState(false);

  const loadSubmissions = useCallback(async () => {
    try {
      setSubmissionsLoading(true);
      setSubmissions(null);
      setSubmissionsError(null);

      const { form, time, filters, submitFieldIdentifier } = query;

      // Current
      const { data: currentData } = await api.get('/visualisations/failed-submissions', {
        params: {
          formUuid: form.uuid,
          startTime: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          endTime: time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          submitFieldIdentifier: submitFieldIdentifier || submitField.identifier, // submitField.identifier is only used for a default selection
          granularity: 'day', // Required by the API
          timeZone: 'UTC', // Required by the API
          filters: filters.reduce((acc, {key, label: value}) => {
            if (!acc.hasOwnProperty(key)) acc[key] = [];
            acc[key].push(value);
            return acc;
          }, {}),
        },
      });

      // Previous
      const previousTime = timeOfPreviousPeriod({
        start: time.start.clone(),
        end: time.end.clone(),
      });
      const { data: previousData } = await api.get('/visualisations/failed-submissions', {
        params: {
          formUuid: form.uuid,
          startTime: previousTime.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          endTime: previousTime.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          submitFieldIdentifier: submitFieldIdentifier || submitField.identifier, // submitField.identifier is only used for a default selection
          granularity: 'day', // Required by the API
          timeZone: 'UTC', // Required by the API
          filters: filters.reduce((acc, {key, label: value}) => {
            if (!acc.hasOwnProperty(key)) acc[key] = [];
            acc[key].push(value);
            return acc;
          }, {}),
        },
      });

      const current = currentData?.datasets[0]?.data.reduce((acc, item) => acc + item, 0) || null;
      const previous = previousData?.datasets[0]?.data.reduce((acc, item) => acc + item, 0) || null;

      setSubmissions({
        current,
        previous,
        percentDiff: calcPercentDiff(current, previous),
      });
    } catch (e) {
      setSubmissionsError(true);
    } finally {
      setSubmissionsLoading(false);
    }
  }, [query, submitField?.identifier]);

  const handleResetSubmissions = () => {
    setSubmissionsLoading(false);
    setSubmissions(null);
    setTopFieldsAfterSubmitLoading(false);
  };

  const triggerloadVisData = useCallback(() => {
    loadSubmissions();
    loadTopFieldsAfterSubmit();
  }, [loadSubmissions, loadTopFieldsAfterSubmit]);

  // Once a new query has been set - proceed to fetch data
  useEffect(() => {
    if (
      query?.form?.uuid && query?.time?.start && query?.time?.end && // Required
      (!formInParams || (formInParams && (query?.form?.uuid === formInParams.uuid))) && // Only fetch when query has been updated by params e.g. when coming from Dashboard
      (!prevQuery || (prevQuery && ( // Differences
        (prevQuery?.form?.uuid !== query?.form?.uuid) ||
        (JSON.stringify(prevQuery.filters) !== JSON.stringify(query.filters)) ||
        (!prevQuery.time || (prevQuery.time &&
          (JSON.stringify(prevQuery.time.start) !== JSON.stringify(query.time.start) ||
          (JSON.stringify(prevQuery.time.end) !== JSON.stringify(query.time.end)))))
      ))) &&
      !submitFieldChangeFromSelect // Don't reload all charts if only the submit field has changed
    ) {
      const { form, time, filters } = query;

      // Load VSCA
      (async () => {
        try {
          setViewsLoading(true);
          setViews(null);
          setViewsError(null);

          const { data } = await api.get('/visualisations/vsca-chart', {
            params: {
              form: {uuid: form.uuid},
              timePeriod: {start: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'), end: time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]')},
              timeZone: form?.organisation?.timeZone,
              filters: filters.reduce((acc, {key, label: value}) => {
                if (!acc.hasOwnProperty(key)) acc[key] = [];
                acc[key].push(value);
                return acc;
              }, {}),
            },
          });
          const isData = (data && data.chart.labels.length > 0);
          if (!isData) {
            // TODO: what to do if no data? Show 0?
          } else {
            setViews(data.stats.views);
          }
        } catch (e) {
          setViewsError(true);
        } finally {
          setViewsLoading(false);
        }
      })();

      // loadVscRates
      (async () => {
        try {
          setStcRateLoading(true);
          setStcRate(null);
          setStcRateError(null);

          const { data } = await api.get('/visualisations/vsc-rates', {
            params: {
              form: {uuid: form.uuid},
              timePeriod: {start: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'), end: time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]')},
              timeZone: form?.organisation?.timeZone,
              filters: filters.reduce((acc, {key, label: value}) => {
                if (!acc.hasOwnProperty(key)) acc[key] = [];
                acc[key].push(value);
                return acc;
              }, {}),
            },
          });

          const isData = (data && data.chart.labels.length > 0);
          if (!isData) {
            // TODO: what to do if no data? Show -?
          } else {
            setStcRate(data.stats.startersCompletions);
          }
        } catch (e) {
          setStcRateError(true);
        } finally {
          setStcRateLoading(false);
        }
      })();

      // loadVscRates for desktop
      (async () => {
        try {
          setStcRateDesktopLoading(true);
          setStcRateDesktop(null);
          setStcRateDesktopError(null);

          // Leave desktop value blank if a different deviceType is selected
          if (filters?.filter(f => f.key === 'deviceType' && f.label !== 'desktop')?.length) return;

          const { data } = await api.get('/visualisations/vsc-rates', {
            params: {
              form: {uuid: form.uuid},
              timePeriod: {start: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'), end: time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]')},
              timeZone: form?.organisation?.timeZone,
              filters: fixedAttributeFormatter({filters, fixedAttribute: {key: 'deviceType', label: 'desktop'}}),
            },
          });

          const isData = (data && data.chart.labels.length > 0);
          if (!isData) {
            // TODO: what to do if no data? Show -?
          } else {
            setStcRateDesktop(data.stats.startersCompletions);
          }
        } catch (e) {
          setStcRateDesktopError(null);
        } finally {
          setStcRateDesktopLoading(false);
        }
      })();

      // loadVscRates for mobile
      (async () => {
        try {
          setStcRateMobileLoading(true);
          setStcRateMobile(null);
          setStcRateMobileError(null);

          // Leave mobile value blank if a different deviceType is selected
          if (filters?.filter(f => f.key === 'deviceType' && f.label !== 'mobile')?.length) return;

          const { data } = await api.get('/visualisations/vsc-rates', {
            params: {
              form: {uuid: form.uuid},
              timePeriod: {start: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'), end: time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]')},
              timeZone: form?.organisation?.timeZone,
              filters: fixedAttributeFormatter({filters, fixedAttribute: {key: 'deviceType', label: 'mobile'}}),
            },
          });

          const isData = (data && data.chart.labels.length > 0);
          if (!isData) {
            // TODO: what to do if no data? Show -?
          } else {
            setStcRateMobile(data.stats.startersCompletions);
          }
        } catch (e) {
          setStcRateMobileError(null);
        } finally {
          setStcRateMobileLoading(false);
        }
      })();

      setSubmissionsLoading(true);
      setSubmissions(null);
      setSubmissionsError(null);

      setTopAbandonPreviousLoading(true);
      setTopAbandonPreviousData(null);
      setReturnRatePreviousLoading(true);
      setReturnRatePreviousData(null);
    }
  }, [prevQuery, query, submitFieldChangeFromSelect, formInParams]);

  // The first time a selected submit field is set (either set by the defualt or from the params) - load submissions
  useEffect(() => {
    if (!prevSubmitField && submitField?.identifier && formsGroupedByFormUuid) { // TODO: need formsgropued here too?
      loadSubmissions();
    }
  }, [prevSubmitField, loadSubmissions, submitField?.identifier, formsGroupedByFormUuid]);

  // When submit field is changed - load submissions
  useEffect(() => {
    if (prevSubmitField && submitField && (prevSubmitField.identifier !== submitField.identifier) &&
        query?.form?.uuid && // Required
        prevQuery && (prevQuery?.form?.uuid === query?.form?.uuid)) {
      setSubmitFieldChangeFromSelect(false);
      loadSubmissions();
    }
  }, [prevSubmitField, submitField, loadSubmissions, setSubmitFieldChangeFromSelect, query, prevQuery]);

  useEffect(() => {
    // load Abandon previous
    if (
      topAbandonData && !prevTopAbandonData &&
      (!formInParams || (formInParams && (query?.form?.uuid === formInParams.uuid))) // Only fetch when query has been updated by params e.g. when coming from Dashboard
    ) {
      (async () => {
        const { form, time, filters } = query;
        try {
          setTopAbandonPreviousLoading(true);
          setTopAbandonPreviousData(null);

          const previousTime = timeOfPreviousPeriod({
            start: time.start.clone(),
            end: time.end.clone(),
          });
          const res = await api.get('/visualisations/abandoned-fields', {
            params: {
              formUuid: form?.uuid,
              startTime: previousTime.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
              endTime: previousTime.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
              filter: filters?.reduce((acc, {key, label: value}) => {
                if (!acc.hasOwnProperty(key)) acc[key] = [];
                acc[key].push(value);
                return acc;
              }, {}),
            },
          });

          let data = res.data;
          const isData = (data && Array.isArray(data) && data.length > 0) ? true : false;

          if (!isData) {
            setTopAbandonPreviousData({
              volume: null,
              volumePercentDiff: null,
              rate: null,
              ratePercentDiff: null,
            });
          }

          const currentTopAbandonVolumeIdentifier = topAbandonData.volume.chart.fieldIdentifiers[0];
          const currentTopAbandonRateIdentifier = topAbandonData.rate.chart.fieldIdentifiers[0];

          const previousTopAbandonVolume = (data
            .filter(field => field.identifier === currentTopAbandonVolumeIdentifier)?.[0]?.abandonCount) ?? null;
          const previousTopAbandonRate = (data
            .filter(field => field.identifier === currentTopAbandonRateIdentifier)?.[0]?.abandonRate) ?? null;

          setTopAbandonPreviousData({
            volume: previousTopAbandonVolume,
            volumePercentDiff: calcPercentDiff(topAbandonData?.volume.chart.data.datasets[0].data[0], previousTopAbandonVolume),
            rate: previousTopAbandonRate,
            ratePercentDiff: calcPercentDiff(topAbandonData?.rate.chart.data.datasets[0].data[0], previousTopAbandonRate),
          });
        } catch (e) {
          // Simply don't show the previous data
        } finally {
          setTopAbandonPreviousLoading(false);
        }
      })();
    }
  }, [topAbandonData, query, prevTopAbandonData, formInParams]);

  useEffect(() => {
    // load return rate previous
    if (
      highestReturnRateField && !prevHighestReturnRateField
    ) (async () => {
      const { form, time, filters } = query;
      try {
        setReturnRatePreviousLoading(true);
        setReturnRatePreviousData(null);

        const previousTime = timeOfPreviousPeriod({
          start: time.start.clone(),
          end: time.end.clone(),
        });
        const res = await api.get('/visualisations/return-to-field', {
          params: {
            formUuid: form.uuid,
            startTime: previousTime.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
            endTime: previousTime.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
            filter: filters?.reduce((acc, {key, label: value}) => {
              if (!acc.hasOwnProperty(key)) acc[key] = [];
              acc[key].push(value);
              return acc;
            }, {}),
          },
        });

        const data = res.data;
        const isData = (data && Array.isArray(data) && data.length > 0) ? true : false;

        if (!isData) {
          setReturnRatePreviousData({
            allSessionsReturned: null,
            percentDiff: null,
          });
        }

        const currentFieldIdentifier = highestReturnRateField.identifier;
        const previousAllSessionsReturned = (data
          .filter(field => field.identifier === currentFieldIdentifier)?.[0]?.allSessionsReturned) ?? null;
        setReturnRatePreviousData({
          allSessionsReturned: previousAllSessionsReturned,
          percentDiff: calcPercentDiff(highestReturnRateField.allSessionsReturned, previousAllSessionsReturned),
        });
      } catch (e) {
        // Simply don't show the previous data
      } finally {
        setReturnRatePreviousLoading(false);
      }
    })();
  }, [highestReturnRateField, prevHighestReturnRateField, query]);

  return (
    <Row className="g-0 mb-4">
      <Card className="w-100" id="headlines">
        <Card.Body className="d-flex flex-column">
          <Row className="g-0 card-title-row">
            <Col className="p-0">
              <Card.Title as="h3">Headlines</Card.Title>
            </Col>
          </Row>
          <Row className="g-0">
            <Col className="p-0 col-auto">
              <h4 className="card-tagline mb-0">Start here to get a quick overview of your form and some problem fields.</h4></Col>
          </Row>
          <div className="d-grid headlines-card-grid pt-3">
            <Card className="headlines-inner-card">
              <Card.Body className="d-flex flex-column">
                <Row className="g-0 card-title-row">
                  <Col className="p-0 justify-content-center text-center">
                    <Card.Title as="h3">Form Data</Card.Title>
                  </Col>
                </Row>
                {(viewsError || stcRateError || stcRateDesktopError || stcRateMobileError || submissionsError) &&
                  <Row className="alert-row g-0 info-alert">
                    <Alert variant="outline-danger" className="my-2">
                      <div className="page-alert-svg-icon d-flex"><VscWarning size="100%"/></div>
                      <p className="alert-text m-0 p-2">Sorry there was a problem loading all headline form data.</p>
                    </Alert>
                  </Row>}
                <div className="d-grid mb-2 mt-1 headlines-stats-grid">
                  {!viewsError && <>
                    <div className="headlines-stats-grid-item">
                      <p className="mb-0 current-stat">The number of <span className="strong-text">Views</span> of the form over the period was <SkeletonValue loading={viewsLoading} value={formatToString(views?.current)}/></p>
                      <p className="mb-0 previous-stat">This compares to <SkeletonValue loading={viewsLoading} value={formatToString(views?.previous)}/> over the previous period</p>
                    </div>
                    <ArrowGridItem loading={viewsLoading} percentDiff={views?.percentDiff} />
                    <div className="headlines-stats-grid-item">
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="page-link-tooltip" className="fs-6">Open chart to see <i>Views</i> over time</Tooltip>}>
                        <Link to={`/form_aggregate${(query?.form && query?.time) ? compileQueryString({
                          form: query.form, time: query.time, granularity: query.granularity, filters: query.filters, submitFieldIdentifier: query.submitFieldIdentifier}) : ''}#vsca`}
                        className={`${(viewsLoading) ? 'pe-none' : ''}`}>
                          <AiOutlineLineChart size="20px" className={`grey-icon ${viewsLoading ? 'skeleton-color' : ''}`}/></Link>
                      </OverlayTrigger>
                    </div>
                  </>}
                  {!stcRateError && <>
                    <div className="headlines-stats-grid-item">
                      <p className="mb-0">The <span className="strong-text">Starter to Completion rate</span> was <SkeletonValue loading={stcRateLoading} value={formatPercentToOneDecimal(stcRate?.current)}/></p>
                      <p className="mb-0 previous-stat">(Vs <SkeletonValue loading={stcRateLoading} value={formatPercentToOneDecimal(stcRate?.previous)}/> in the previous period)</p>
                    </div>
                    <ArrowGridItem loading={stcRateLoading} percentDiff={stcRate?.percentDiff} />
                    <div className="headlines-stats-grid-item">
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="page-link-tooltip" className="fs-6">Open <i>Starter to Completion rate</i> chart</Tooltip>}>
                        <Link to={`/form_aggregate${(query?.form && query?.time) ? compileQueryString({
                          form: query.form, time: query.time, granularity: query.granularity, filters: query.filters, submitFieldIdentifier: query.submitFieldIdentifier}) : ''}#vsc-rates`}
                        className={`${(stcRateLoading) ? 'pe-none' : ''}`}>
                          <AiOutlineLineChart size="20px" className={`grey-icon ${stcRateLoading ? 'skeleton-color' : ''}`}/></Link>
                      </OverlayTrigger>
                    </div>
                  </>}
                  {!stcRateDesktopError && <>
                    <div className="headlines-stats-grid-item">
                      <p className="mb-0">The <span className="strong-text">Completion rate for desktop</span> visitors was <SkeletonValue loading={stcRateDesktopLoading} value={formatPercentToOneDecimal(stcRateDesktop?.current)}/></p>
                      <p className="mb-0 previous-stat">(Vs <SkeletonValue loading={stcRateDesktopLoading} value={formatPercentToOneDecimal(stcRateDesktop?.previous)}/> in the previous period)</p>
                    </div>
                    <ArrowGridItem loading={stcRateDesktopLoading} percentDiff={stcRateDesktop?.percentDiff} />
                    <ReplayLinkGridItem
                      loading={stcRateDesktopLoading}
                      tooltipText={<>Open <i>Session Replay</i> for desktop visitors</>}
                      setShowSessionReplayCTAModal={setShowSessionReplayCTAModal}
                      replayEnabled={query?.form?.organisation?.replayEnabled}
                      queryStringObj={(query?.form && query?.time) ? {form: query.form, time: query.time,
                        filters: (query.filters || []).filter(({key}) => key !== 'deviceType').concat({key: 'deviceType', label: 'desktop'}),
                        sessionOutcomes: [], sessionFilters: {},
                      } : null}
                    />
                  </>}
                  {!stcRateMobileError && <>
                    <div className="headlines-stats-grid-item">
                      <p className="mb-0">The <span className="strong-text">Completion rate for mobile</span> visitors was <SkeletonValue loading={stcRateMobileLoading} value={formatPercentToOneDecimal(stcRateMobile?.current)}/></p>
                      <p className="mb-0 previous-stat">(Vs <SkeletonValue loading={stcRateMobileLoading} value={formatPercentToOneDecimal(stcRateMobile?.previous)}/> in the previous period)</p>
                    </div>
                    <ArrowGridItem loading={stcRateMobileLoading} percentDiff={stcRateMobile?.percentDiff} />
                    <ReplayLinkGridItem
                      loading={stcRateMobileLoading}
                      tooltipText={<>Open <i>Session Replay</i> for mobile visitors</>}
                      setShowSessionReplayCTAModal={setShowSessionReplayCTAModal}
                      replayEnabled={query?.form?.organisation?.replayEnabled}
                      queryStringObj={(query?.form && query?.time) ? {form: query.form, time: query.time,
                        filters: (query.filters || []).filter(({key}) => key !== 'deviceType').concat({key: 'deviceType', label: 'mobile'}),
                        sessionOutcomes: [], sessionFilters: {},
                      } : null}
                    />
                  </>}
                </div>
              </Card.Body>
            </Card>
            <Card className="headlines-inner-card row-span-2">
              <Card.Body className="d-flex flex-column">
                <Row className="g-0 card-title-row">
                  <Col className="p-0 justify-content-center text-center">
                    <Card.Title as="h3">Field Data</Card.Title>
                  </Col>
                </Row>
                {((topAbandonError && topAbandonError !== 'No data to display') ||
                  (topFieldReturnsGapError && (topFieldReturnsGapError !== 'No big behaviour differences found' && topFieldReturnsGapError !== 'No data to display'))) &&
                  <Row className="alert-row g-0 info-alert">
                    <Alert variant="outline-danger" className="my-2">
                      <div className="page-alert-svg-icon d-flex"><VscWarning size="100%"/></div>
                      <p className="alert-text m-0 p-2">Sorry there was a problem loading all headline field data.</p>
                    </Alert>
                  </Row>}
                <div className="grid d-grid mb-2 mt-1 headlines-stats-grid">
                  {!topAbandonError && (topAbandonLoading || (!topAbandonLoading && topAbandonData?.volume.chart.data.labels?.length > 0)) && <>
                    <div className="headlines-stats-grid-item" data-testid="abandon-count">
                      <p className="mb-0">The field with the <span className="strong-text">most abandonments</span> was <SkeletonField loading={topAbandonLoading}
                        field={{
                          label: topAbandonData?.volume.chart.data.labels[0],
                          ...htmlAttrsForIdentifier(topAbandonData?.volume.chart.fieldIdentifiers[0]),
                        }}/> with <SkeletonValue loading={topAbandonLoading} value={formatToString(topAbandonData?.volume.chart.data.datasets[0].data[0])}/></p>
                      <p className="mb-0 previous-stat">(<SkeletonValue loading={topAbandonPreviousLoading} value={formatToString(topAbandonPreviousData?.volume)}/> in the previous period)</p>
                    </div>
                    <ArrowGridItem loading={topAbandonPreviousLoading} percentDiff={topAbandonPreviousData?.volumePercentDiff} greenDown={true} />
                    <ReplayLinkGridItem
                      loading={topAbandonLoading}
                      tooltipText={<>Open <i>Session Replay</i> for most abandonments on {topAbandonData?.volume.chart.data.labels[0]}</>}
                      setShowSessionReplayCTAModal={setShowSessionReplayCTAModal}
                      replayEnabled={query?.form?.organisation?.replayEnabled}
                      queryStringObj={(query?.form && query?.time) ? {form: query.form, time: query.time, filters: query.filters,
                        sessionOutcomes: [{value: 'abandoned'}],
                        sessionFilters: {
                          ...emptySessionFilters,
                          abandonedFieldIdentifier: topAbandonData?.volume.chart.fieldIdentifiers[0],
                        }
                      } : null}
                    />
                    <div className="headlines-stats-grid-item">
                      <p className="mb-0">The field with the <span className="strong-text">highest abandonment rate</span> was <SkeletonField loading={topAbandonLoading}
                        field={{
                          label: topAbandonData?.rate.chart.data.labels[0],
                          ...htmlAttrsForIdentifier(topAbandonData?.rate.chart.fieldIdentifiers[0]),
                        }}/> with <SkeletonValue loading={topAbandonLoading} value={formatPercentToOneDecimal(topAbandonData?.rate.chart.data.datasets[0].data[0])}/></p>
                      <p className="mb-0 previous-stat">(<SkeletonValue loading={topAbandonPreviousLoading} value={formatPercentToOneDecimal(topAbandonPreviousData?.rate)}/> in the previous period)</p>
                    </div>
                    <ArrowGridItem loading={topAbandonPreviousLoading} percentDiff={topAbandonPreviousData?.ratePercentDiff} greenDown={true} />
                    <ReplayLinkGridItem
                      loading={topAbandonLoading}
                      tooltipText={<>Open <i>Session Replay</i> for highest abandonment rate on {topAbandonData?.rate.chart.data.labels[0]}</>}
                      setShowSessionReplayCTAModal={setShowSessionReplayCTAModal}
                      replayEnabled={query?.form?.organisation?.replayEnabled}
                      queryStringObj={(query?.form && query?.time) ? {form: query.form, time: query.time, filters: query.filters,
                        sessionOutcomes: [{value: 'abandoned'}],
                        sessionFilters: {
                          ...emptySessionFilters,
                          abandonedFieldIdentifier: topAbandonData?.rate.chart.fieldIdentifiers[0],
                        }
                      } : null}
                    />
                  </>}
                  {(topAbandonError === 'No data to display' || topAbandonData?.volume.chart.data.labels?.length === 0) && <p className="col-span-3">No abandons in the selected timeframe</p>}

                  {(!topFieldReturnsGapError || (topFieldReturnsGapError && topFieldReturnsGapError === 'No big behaviour differences found')) && <>
                    <div className="headlines-stats-grid-item">
                      <p className="mb-0">The field with the <span className="strong-text">highest return rate</span> was <SkeletonField loading={topFieldReturnsGapLoading}
                        field={highestReturnRateField}/> with <SkeletonValue loading={topFieldReturnsGapLoading} value={formatPercentToOneDecimal(highestReturnRateField?.allSessionsReturned)}/></p>
                      <p className="mb-0 previous-stat">(<SkeletonValue loading={returnRatePreviousLoading} value={formatPercentToOneDecimal(returnRatePreviousData?.allSessionsReturned)}/> in the previous period)</p>
                    </div>
                    <ArrowGridItem loading={returnRatePreviousLoading} percentDiff={returnRatePreviousData?.percentDiff} greenDown={true} />
                    <ReplayLinkGridItem
                      loading={topFieldReturnsGapLoading}
                      tooltipText={<>Open <i>Session Replay</i> for highest return rate on {highestReturnRateField?.label}</>}
                      setShowSessionReplayCTAModal={setShowSessionReplayCTAModal}
                      replayEnabled={query?.form?.organisation?.replayEnabled}
                      queryStringObj={(query?.form && query?.time && highestReturnRateField) ? {form: query.form, time: query.time, filters: query.filters,
                        sessionOutcomes: [],
                        sessionFilters: {
                          ...emptySessionFilters,
                          metrics: [{
                            for: highestReturnRateField.identifier,
                            operator: 'gte',
                            type: 'rtf_count',
                            value: '1'
                          }],
                        }
                      } : null}
                    />
                  </>}
                  {(topFieldReturnsGapError === 'No data to display' || (!topFieldReturnsGapLoading && !highestReturnRateField)) && <p className="col-span-3">No field returns in the selected timeframe</p>}

                  {!topFieldReturnsGapError &&
                    <div className="headlines-stats-grid-item col-span-3" data-testid="return-difference">
                      <p className="mb-0">The fields with a <span className="strong-text">difference in return behaviour</span> between abandoners and completers are:</p>
                      <ul className="mb-0">
                        {(topFieldReturnsGapLoading || topFieldReturnsGapData?.chart.data.labels[0]) &&
                          <li><SkeletonField loading={topFieldReturnsGapLoading} field={{
                            label: topFieldReturnsGapData?.chart.data.labels[0],
                            ...htmlAttrsForIdentifier(topFieldReturnsGapData?.chart.fieldIdentifiers[0]),
                          }}/> with <SkeletonValue loading={topFieldReturnsGapLoading}
                            value={formatPercentToOneDecimal(topFieldReturnsGapData?.chart.data.datasets[0].data[0])}/> {topFieldReturnsGapData ?
                            topFieldReturnsGapData?.chart.outcomes[0].abandoned > topFieldReturnsGapData?.chart.outcomes[0].completed ?
                              'more abandoners' : null : null}
                          <InfoPopover loading={topFieldReturnsGapLoading}>
                            <small>
                              {formatPercentToOneDecimal(topFieldReturnsGapData?.chart.outcomes[0].abandoned)} returned in abandoned sessions <br></br>
                              {formatPercentToOneDecimal(topFieldReturnsGapData?.chart.outcomes[0].completed)} returned in completed sessions
                            </small>
                          </InfoPopover>
                          </li>}
                        {(topFieldReturnsGapLoading || topFieldReturnsGapData?.chart.data.labels[1]) &&
                          <li><SkeletonField loading={topFieldReturnsGapLoading} field={{
                            label: topFieldReturnsGapData?.chart.data.labels[1],
                            ...htmlAttrsForIdentifier(topFieldReturnsGapData?.chart.fieldIdentifiers[1]),
                          }}/> with <SkeletonValue loading={topFieldReturnsGapLoading}
                            value={formatPercentToOneDecimal(topFieldReturnsGapData?.chart.data.datasets[0].data[1])}/> {topFieldReturnsGapData ?
                            topFieldReturnsGapData?.chart.outcomes[1].abandoned > topFieldReturnsGapData?.chart.outcomes[1].completed ?
                              'more abandoners' : null : null} <InfoPopover loading={topFieldReturnsGapLoading}>
                            <small>
                              {formatPercentToOneDecimal(topFieldReturnsGapData?.chart.outcomes[1].abandoned)} returned in abandoned sessions <br></br>
                              {formatPercentToOneDecimal(topFieldReturnsGapData?.chart.outcomes[1].completed)} returned in completed sessions
                            </small>
                          </InfoPopover>
                          </li>}
                        {(topFieldReturnsGapLoading || topFieldReturnsGapData?.chart.data.labels[2]) &&
                          <li><SkeletonField loading={topFieldReturnsGapLoading} field={{
                            label: topFieldReturnsGapData?.chart.data.labels[2],
                            ...htmlAttrsForIdentifier(topFieldReturnsGapData?.chart.fieldIdentifiers[2]),
                          }}/> with <SkeletonValue loading={topFieldReturnsGapLoading}
                            value={formatPercentToOneDecimal(topFieldReturnsGapData?.chart.data.datasets[0].data[2])}/> {topFieldReturnsGapData ?
                            topFieldReturnsGapData?.chart.outcomes[2].abandoned > topFieldReturnsGapData?.chart.outcomes[2].completed ?
                              'more abandoners' : null : null}
                          <InfoPopover loading={topFieldReturnsGapLoading}>
                            <small>
                              {formatPercentToOneDecimal(topFieldReturnsGapData?.chart.outcomes[2].abandoned)} returned in abandoned sessions <br></br>
                              {formatPercentToOneDecimal(topFieldReturnsGapData?.chart.outcomes[2].completed)} returned in completed sessions
                            </small>
                          </InfoPopover>
                          </li>}
                      </ul>
                    </div>}
                  {topFieldReturnsGapError === 'No big behaviour differences found' && <p className="col-span-3">No behaviour differences found in the selected timeframe</p>}
                </div>
              </Card.Body>
            </Card>
            <Card className="headlines-inner-card">
              <Card.Body className="d-flex flex-column">
                <Row className="g-0 card-title-row">
                  <Col></Col>
                  <Col className="p-0 justify-content-center text-center text-nowrap">
                    <Card.Title as="h3">Submit Data</Card.Title>
                  </Col>
                  <Col className="p-0 justify-content-end text-nowrap">
                    <ChartTip
                      dataLoading={submissionsLoading}
                      isData={!!submissions}
                      mixpanel={mixpanel}
                      organisation={query?.form?.organisation}
                      page={'Insights'}
                      vis={'Submit Data'}>
                      {submissions?.current && submitField?.label && <>
                        <p>Visitors are clicking this button, then failing to submit:</p>
                        <p className="tip-field">{submitField?.label}</p>
                        <p className="mb-0">
                          <Link to={`/field-flow${(query?.form && query?.time) ? compileQueryString({form: query.form, time: query.time, filters: query.filters,
                            sessionOutcomes: [{value: 'abandoned'}], fieldFlow: {
                              flowFieldIdentifier: submitField?.identifier,
                            }}) : ''}`} target="_blank" onClick={() => {mixpanel.track('Clicked Quick Tip Link', { page: 'Insights', vis: 'Submit Data' });}}>Open the <i>Field Flow</i> report</Link> to find out what visitors in abandoned sessions are doing after this field.</p>
                      </>}
                      {!submissions?.current &&
                        <p className="mb-0">This tip looks at failed submissions, but there are none in your current timeframe.</p>}
                    </ChartTip>
                  </Col>
                </Row>
                <div className="pb-3">
                  <SubmitSelect
                    mixpanel={mixpanel}
                    submitFieldChangeFromSelect={submitFieldChangeFromSelect}
                    setSubmitFieldChangeFromSelect={setSubmitFieldChangeFromSelect}
                    submitField={submitField}
                    prevSubmitField={prevSubmitField}
                    setSubmitField={setSubmitField}
                    handleResetData={handleResetSubmissions}
                    triggerloadVisData={triggerloadVisData}
                    isData={!!submissions} />
                </div>
                {(submissionsError ||
                  (topFieldsAfterSubmitError && topFieldsAfterSubmitError !== 'No data to display')) &&
                  <Row className="alert-row g-0 info-alert">
                    <Alert variant="outline-danger" className="my-2">
                      <div className="page-alert-svg-icon d-flex"><VscWarning size="100%"/></div>
                      <p className="alert-text m-0 p-2">Sorry there was a problem loading all headline submit data.</p>
                    </Alert>
                  </Row>}
                <div className="d-grid mb-2 mt-1 headlines-stats-grid">
                  {!submissionsError && <>
                    <div className="headlines-stats-grid-item">
                      <p className="mb-0">The volume of <span className="strong-text">failed submissions</span> was <SkeletonValue loading={submissionsLoading} value={formatToString(submissions?.current || null)}/></p>
                      <p className="mb-0 previous-stat">(Vs <SkeletonValue loading={submissionsLoading} value={formatToString(submissions?.previous || null)}/> in the previous period)</p>
                    </div>
                    <ArrowGridItem loading={submissionsLoading} percentDiff={submissions?.percentDiff || null} greenDown={true} />
                    <ReplayLinkGridItem
                      loading={submissionsLoading}
                      tooltipText={<>Open <i>Session Replay</i> for failed submissions</>}
                      setShowSessionReplayCTAModal={setShowSessionReplayCTAModal}
                      replayEnabled={query?.form?.organisation?.replayEnabled}
                      queryStringObj={(query?.form && query?.time) ? {form: query.form, time: query.time, filters: query.filters,
                        sessionOutcomes: [{value: 'abandoned'}],
                        sessionFilters: {
                          ...emptySessionFilters,
                          interactedWithFieldIdentifiers: [submitField?.identifier],
                        }
                      } : null}
                    />
                  </>}

                  {!topFieldsAfterSubmitError &&
                    <div className="headlines-stats-grid-item col-span-3" data-testid="failed-submission-flow">
                      <p className="mb-0">After a failed submission, the fields that <span className="strong-text">abandoners jump back to</span> most are:</p>
                      <ul className="mb-0">
                        {(topFieldsAfterSubmitLoading || topFieldsAfterSubmitData?.chart.data.labels[0]) &&
                          <li><SkeletonField loading={topFieldsAfterSubmitLoading} field={{
                            label: topFieldsAfterSubmitData?.chart.data.labels[0],
                            ...htmlAttrsForIdentifier(topFieldsAfterSubmitData?.chart.fieldIdentifiers[0]),
                          }}/> with <SkeletonValue loading={topFieldsAfterSubmitLoading} value={formatToString(topFieldsAfterSubmitData?.chart.data.datasets[0].data[0])}/> <InfoPopover loading={topFieldsAfterSubmitLoading}>
                            <small>
                              {formatPercentToOneDecimal(topFieldsAfterSubmitData?.chart.percentOfFlows[0])} of failed submissions that lead to abandonment jump back to this field
                            </small>
                          </InfoPopover>
                          </li>}
                        {(topFieldsAfterSubmitLoading || topFieldsAfterSubmitData?.chart.data.labels[1]) &&
                        <li><SkeletonField loading={topFieldsAfterSubmitLoading} field={{
                          label: topFieldsAfterSubmitData?.chart.data.labels[1],
                          ...htmlAttrsForIdentifier(topFieldsAfterSubmitData?.chart.fieldIdentifiers[1]),
                        }}/> with <SkeletonValue loading={topFieldsAfterSubmitLoading} value={formatToString(topFieldsAfterSubmitData?.chart.data.datasets[0].data[1])}/> <InfoPopover loading={topFieldsAfterSubmitLoading}>
                          <small>
                            {formatPercentToOneDecimal(topFieldsAfterSubmitData?.chart.percentOfFlows[1])} of failed submissions that lead to abandonment jump back to this field
                          </small>
                        </InfoPopover>
                        </li>}
                        {(topFieldsAfterSubmitLoading || topFieldsAfterSubmitData?.chart.data.labels[2]) &&
                          <li><SkeletonField loading={topFieldsAfterSubmitLoading} field={{
                            label: topFieldsAfterSubmitData?.chart.data.labels[2],
                            ...htmlAttrsForIdentifier(topFieldsAfterSubmitData?.chart.fieldIdentifiers[2]),
                          }}/> with <SkeletonValue loading={topFieldsAfterSubmitLoading} value={formatToString(topFieldsAfterSubmitData?.chart.data.datasets[0].data[2])}/> <InfoPopover loading={topFieldsAfterSubmitLoading}>
                            <small>
                              {formatPercentToOneDecimal(topFieldsAfterSubmitData?.chart.percentOfFlows[2])} of failed submissions that lead to abandonment jump back to this field
                            </small>
                          </InfoPopover>
                          </li>}
                      </ul>
                    </div>}
                </div>
              </Card.Body>
            </Card>
          </div>
        </Card.Body>
      </Card>
      <SessionReplayCTAModal show={showSessionReplayCTAModal} organisation={query?.form?.organisation} handleCloseModal={() => setShowSessionReplayCTAModal(false)}/>
    </Row>);

};

export default Headlines;