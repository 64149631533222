import { Link, useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import moment from 'moment-timezone';
import AttributesIcons from '../SessionReplay/AttributesIcons.tsx';
import {
  formatMillisecondsToTimeString,
} from '../../utils';

const AttributesList = ({session, form}) => {
  const location = useLocation();

  return (
    <dl className="attributes">
      <div className="data-pair">
        <dt>Time</dt>
        <dd>{moment(session.startTime).format('llll')}</dd>
      </div>
      <div className="data-pair">
        <dt>Outcome</dt>
        <dd className="text-capitalize">{session.outcome}</dd>
      </div>
      <div className="data-pair">
        <dt>Session Duration</dt>
        <dd>{formatMillisecondsToTimeString(session.duration)}</dd>
      </div>
      <div className="data-pair">
        <dt>Session Field Returns</dt>
        <dd>{session.totalFieldReturns}</dd>
      </div>
      <div className="custom-attributes-wrapper mb-1">
        <AttributesIcons
          deviceType={session.defaultAttributes['deviceType']}
          browserFamily={session.defaultAttributes['browserFamily']}
          operatingSystem={session.defaultAttributes['Operating System']}
          visitorType={session.defaultAttributes['Visitor Type']}
          autofillTriggered={session.defaultAttributes['autofillTriggered']}
          trafficMedium={session.defaultAttributes['trafficMedium']}
        />
        <div className="attributes-icons">
          {Object.entries(session.customAttributes).map(([key, value], index) => (
            <div className="data-pair" key={`${key}-${value}`}>
              <span className="badge bg-light text-dark fw-normal">
                <dt>{key}</dt>
                <dd>{value?.toString()}</dd>
              </span>
            </div>
          ))}
        </div>
      </div>
      {session.replayIds?.length &&
        <div className="data-pair d-flex">
          <dt>Replay{session.replayIds?.length > 1 && 's'}</dt>
          <dd>{session.replayIds.map(id =>
            <Link
              to={{
                pathname: `/session-replay/${id}`,
                state: {form, session, prevPath: location.pathname}
              }} key={id}>
              <Button className="view-replay-cta text-nowrap mx-0 me-1">View Replay</Button>
            </Link>)}</dd>
        </div>}
    </dl>
  );
};

export default AttributesList;
