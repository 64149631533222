import React, { useContext, useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ExplorerContext } from './explorerContext.tsx';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import moment from 'moment-timezone';
import 'font-awesome/css/font-awesome.css';
import 'line-awesome/dist/line-awesome/css/line-awesome.css';
import { FaCog, FaInfoCircle, FaLongArrowAltRight, FaSpinner } from "react-icons/fa";
import { VscChromeClose, VscInfo } from "react-icons/vsc";
import NoFormsMsg from '../../Components/NoFormsMsg';
import AppAlerts from '../../Components/AppAlerts';
import FeedbackRow from '../../Components/FeedbackRow';
import SessionsQueryNav from '../SessionReplay/SessionsQueryNav';
import CopyUrlIcon from '../../Components/CopyUrlIcon';
import AppContext from '../../AppContext';
import './SessionExplorer.scss';
import NavImg from '../../images/SessionExplorer-nav.png';
import FlurryImg from '../../images/SessionExplorer-flurry.png';
import CustomEventsImg from '../../images/SessionExplorer-custom-events.png';
import AttributesList from './AttributesList';
import {
  compileQueryString,
  formatMillisecondsToTimeString,
  orgDetailsForMixpanel,
} from '../../utils';
import Forms from '../../forms';

const SessionsSearch = ({mixpanel}) => {
  const {
    formsGroupedByOrg,
    formsLoading,
    formsLoadingError,
    query,
    currentUser,
  } = useContext(AppContext);
  const { sessions, sessionsLoading, sessionsError, customEventsInSessions } = React.useContext(ExplorerContext);

  const {
    time,
    form,
    filters,
    sessionOutcomes,
    sessionExplorer,
    sessionFilters,
  } = query || {};

  const {
    interactedWithFieldIdentifiers,
  } = sessionFilters || {};

  const [showInfo, setShowInfo] = useState(false);
  const [reportVisible, setReportVisible] = useState(true);

  const pageInfoAreaRef = useRef(null);
  const pageInfoIconAreaRef = useRef(null);

  const handleCloseOverlay = (e) => {
    if (pageInfoIconAreaRef.current && !pageInfoIconAreaRef.current.contains(e.target) && pageInfoAreaRef.current && !pageInfoAreaRef.current.contains(e.target)) {
      setShowInfo(null);
    }
  };

  useEffect(() => {
    if (sessions?.length) mixpanel.track('Loaded sessions', {page: 'SessionExplorer', ...orgDetailsForMixpanel(form?.organisation)});
  }, [mixpanel, sessions, form?.organisation]);

  useEffect(() => {
    if(!currentUser.accountManager) setReportVisible(!!form?.organisation?.reportAccessEnabled);
  }, [currentUser.accountManager, form?.organisation?.reportAccessEnabled]);

  return (<>
    <SessionsQueryNav page={'SessionExplorer'} mixpanel={mixpanel} />
    <div className="scroll-wrapper main-content" onClick={handleCloseOverlay}>
      <div className="pb-1 pe-3 stick-in-place">
        <FeedbackRow
          classList={['allow-scroll-under-nav']}
          mixpanel={mixpanel}
          page={'SessionExplorer'}
          org={form?.organisation}
          messageContent={'Session Explorer'} />
      </div>
      <div className="stick-in-place"><AppAlerts showOrgAlerts={true} /></div>
      <Row className={`g-0 title stick-in-place pe-3 ${showInfo ? 'hide' : ''}`}>
        <Col className={`original-content ${showInfo ? 'background' : ''}`}>
          <h1 id="title">
            {form?.organisation?.name && form?.label && form?.url && <>
              {`${form.organisation.name} | ${form.label} | `}
              <a href={form.url} target="_blank" rel="noopener noreferrer">{form.url}</a>
            </>}
          </h1>
        </Col>
        <Col className={`title-tooltips text-end original-content ${showInfo ? 'background' : ''}`}>
          {form && <>
            <CopyUrlIcon
              queryString={compileQueryString({form, time, filters, sessionOutcomes, sessionExplorer, sessionFilters})}/>
            <Link to={`/forms/${form.uuid}/edit`}><FaCog size="20px" className="grey-icon settings-icon" title="Form settings"/></Link>
          </>}
          <span ref={pageInfoIconAreaRef}>
            <FaInfoCircle id="first-info-icon" size="20px" className="info-circle-icon" onClick={() => {
              setShowInfo(true);
              mixpanel.track('Clicked Session Explorer info', { page: 'SessionExplorer', ...orgDetailsForMixpanel(form?.organisation) });
            }}/>
          </span>
        </Col>
        <div className={`page-info-card ${showInfo ? '' : 'd-none'} ${showInfo ? 'open' : 'closed'}`} ref={pageInfoAreaRef}>
          <div className="card-contents">
            <Row className="g-0 card-title-row">
              <Col className="p-0">
                <Card.Title as="h3">Session Explorer</Card.Title>
              </Col>
              <Col className="p-0 text-end card-tooltip">
                <VscChromeClose size="20px" className="grey-icon" onClick={() => setShowInfo(false)} title="Return to page"/>
              </Col>
            </Row>
            <Row className="g-0 text-content">
              <Col lg={7} className="ps-0">
                <Card.Text className="mb-3 subtitle">In this data, you can examine individual visitor journeys through your form.</Card.Text>
                <Card.Text>Set up the report by using the navigation bar. Select the time period you want to examine, pick the form and select any filters you would like (often filtering by <code>sessionOutcome: Abandoned</code> sessions
                    gives the greatest insight). You can then open <i>More filters</i> to select an abandonment point or only look at sessions where visitors have interacted with a field. Finally, you can filter further
                    based on a metric rules and create your own limits for 'Returns over the Total session', or 'Time spent in a specific field'.</Card.Text>
                <figure className="text-center img-wrapper">
                  <img src={NavImg} alt="Session-Explorer-nav" width="90%" height="auto" className="card-info-img"/>
                </figure>
                <Card.Text>The data will then display below with each column representing a single session. The metadata, such as session outcome, browser and device used is shown at the top of each column.
                    Underneath that, you can follow the flow of each interaction with the session to understand the time spent, keypresses, clicks and value changes the visitor has in each field.</Card.Text>
                <Card.Text>Any field returns in a session are indicated by a red border and if you have searched for interactions with a specific input it will be highlighted in yellow.</Card.Text>
                <figure className="text-center img-wrapper">
                  <img src={FlurryImg} alt="Session-Explorer-flurry" width="200px" height="auto" className="card-info-img"/>
                </figure>
                <Card.Text>You can choose to view the custom events that occur during the session. This gives a clear overview on what is happening during the visitor journey.</Card.Text>
                <figure className="text-center img-wrapper">
                  <img src={CustomEventsImg} alt="Session-Explorer-custom-events" width="350px" height="auto" className="card-info-img"/>
                </figure>
                <Card.Text>Learn more about <a target="_blank" rel="noopener noreferrer" href="https://docs.zuko.io/knowledge-base/installation/#tracking-custom-events">setting up custom events</a>.</Card.Text>
              </Col>
              <Col lg={5}>
                <div className="card-tip-box">
                  <h4 className="pb-3">How to use this and what to look for</h4>
                  <Card.Text>This tool provides an understanding of the journey a visitor has taken to complete your form including any frustrations they may have experienced. It enables you to find
                      those sessions where visitors have struggled and to display those difficulties without the need to spend the time watching session replays. It is great for bringing visitor frustrations
                      to life for your managers and clients. These are real customers who can’t get where they want to be because of your form.</Card.Text>
                  <Card.Text className="mb-0">The best way to tease out these frustrations is to filter by <code>sessionOutcome: Abandoned</code> sessions and pick a field with a high abandon count.</Card.Text>
                  <Row className="g-0 m-3">
                    <Col xs={1} className="p-0"><FaLongArrowAltRight size="20px"/></Col>
                    <Col className="p-0 align-content-start">
                      <Card.Text className="info-pointer">Find a high abandon count field in the <i>Fields Overview</i> or <i>Abandoned Fields Overview</i> charts in <Link to="/field_aggregate">Field Data</Link>.</Card.Text>
                    </Col>
                  </Row>
                  <Card.Text>You can then see what happens in visitor sessions after they have interacted with the problem field - where do they go next, do they abandon, etc?</Card.Text>
                  <Card.Text>Specifically, analysing your “Submit” button will almost certainly provide your deepest insight. Interacting with the submit button is normally the trigger for any error messages
                      so by looking at what the visitor does after trying to submit the form you can isolate which fields they are having trouble with. These are customers who want to complete your form but
                      can’t - so solving these issues will have the greatest impact on your conversion rate.</Card.Text>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Row>
      {(!formsLoading && !formsLoadingError && formsGroupedByOrg && !Forms.length > 0) && <NoFormsMsg mixpanel={mixpanel} page={'SessionExplorer'}/>}
      {customEventsInSessions === false &&
          <Row className={`alert-row g-0 custom-events-alert original-content ${showInfo ? 'background' : ''}`}>
            <Alert variant={'info'}>
              <div className="page-alert-svg-icon d-flex"><VscInfo size="100%"/></div>
              <p className="alert-text m-0">No custom events occurred in the selected sessions. Try a different query, or to know which field to select - check which field leads to custom events in <Link to="/field-flow" target="_blank">Field Flow</Link>. Learn more about <a target="_blank" rel="noopener noreferrer" href="https://docs.zuko.io/knowledge-base/installation/#tracking-custom-events">setting up custom events</a>.</p>
            </Alert>
          </Row>}
      {customEventsInSessions &&
          <Row className={`alert-row g-0 custom-events-alert original-content ${showInfo ? 'background' : ''}`}>
            <Alert variant={'info'}>
              <div className="page-alert-svg-icon d-flex"><VscInfo size="100%"/></div>
              <p className="alert-text m-0">Custom events occurred during the sessions</p>
            </Alert>
          </Row>}
      <div className={`sessions-container original-content ${showInfo ? 'background' : ''} ${reportVisible ? '' : 'blurred-report'}`}>
        {sessionsLoading && <FaSpinner size="22px" className="spinning-icon" title="Loading sessions"/>}
        {sessionsError && <p>{sessionsError}</p>}
        {!sessionsLoading && !sessionsError && !sessions.length > 0 && <p>No sessions found</p>}
        {(sessions?.length > 0) && sessions.map((session, index) =>
          (<div className="session-container" key={index} data-testid={`session-container-${index}`}>
            <div className="header-box">
              <span className="index">{index + 1}</span>
              <AttributesList form={form} session={session} />
            </div>
            <div className="flurry-list">
              {session.flurries.reduce((acc, { type, eventType, label, returnIndex, timeSpentInteracting, totalKeypresses,
                totalClicks, valueChanges, timeSinceLastFlurry, identifier, htmlTagName, htmlType, htmlName, htmlId,
              }, index) => {
                if (type === 'field') {
                  if (index !== 0) {
                    acc.push(
                      <div className="flurry-separator" key={`${index}-flurry-separator`}>
                        <i className="fa fa-ellipsis-v"/>
                        <span>{formatMillisecondsToTimeString(timeSinceLastFlurry === undefined ? 0 : timeSinceLastFlurry)}</span>
                      </div>,
                    );
                  }
                  acc.push(<div
                    className={`flurry ${returnIndex > 0 ? 'field-return' : ''} ${interactedWithFieldIdentifiers?.length > 0 && interactedWithFieldIdentifiers.includes(identifier) ? 'selectedField' : ''}`}
                    key={`${index}-flurry`}>
                    <h5 title={htmlTagName || htmlType || htmlName || htmlId ?
                        `HTML Tag Name: ${htmlTagName}, HTML Type: ${htmlType}, HTML Name: ${htmlName}, HTML ID: ${htmlId}`: null}>{label}</h5>
                    <dl className="metrics">
                      <div className="data-pair">
                        <dt>Time spent interacting</dt>
                        <dd>{formatMillisecondsToTimeString(timeSpentInteracting)}</dd>
                      </div>
                      <div className="data-pair">
                        <dt>Total keypresses</dt>
                        <dd>{Math.round(totalKeypresses)}</dd>
                      </div>
                      <div className="data-pair">
                        <dt>Total clicks</dt>
                        <dd>{Math.round(totalClicks)}</dd>
                      </div>
                      <div className="data-pair">
                        <dt>Value changes</dt>
                        <dd>{Math.round(valueChanges)}</dd>
                      </div>
                      {returnIndex > 0 &&
                        <span className="return-count">{moment.localeData().ordinal(returnIndex)} return</span>}
                    </dl>
                  </div>);
                }

                if (type === 'custom_event' && eventType) {
                  acc.push(<div
                    className={`custom-event`}
                    key={`${index}-custom-event`}>
                    <h5 className="mb-0">{eventType}</h5>
                  </div>);
                }
                return acc;
              }, [])}
            </div>
            <div className="footer-box">
              <span className="index">{index + 1}</span>
              <AttributesList form={form} session={session} />
            </div>
          </div>)
        )}
      </div>
    </div>
  </>
  );
};

export default SessionsSearch;
