import React, { useState, useEffect, useContext } from 'react';
import AppContext from '../../AppContext';
import { ReplaySessionsContextType, ReplaySession } from '../../types/sessions';
import { useAppQuery, usePrevious } from '../../hooks';
import api from '../../api';

export const ReplayContext = React.createContext<ReplaySessionsContextType | null>(null);

const ReplayProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
  const [sessions, setSessions] = useState<ReplaySession[]>();
  const [sessionsLoading, setSessionsLoading] = useState<boolean>(true);
  const [sessionsLoadingProgress, setSessionsLoadingProgress] = useState(20);
  const [sessionsError, setSessionsError] = useState<string | null>(null);
  const [trackedSessionsWithoutReplays, setTrackedSessionsWithoutReplays] = useState<boolean>();
  const [sessionsTablePage, setSessionsTablePage] = useState<number>(0);

  const prevSessionsLoading = usePrevious(sessionsLoading);

  const {
    query,
    prevQuery
  } = useAppQuery();
  const {
    formsLoading,
    formsLoadingError,
  } = useContext(AppContext);

  const defaultError = 'Sorry, something went wrong loading the sessions data. Please refresh the page.';
  const noDataError = 'No sessions with replays in selected timeframe, or with selected filters';

  // Fetch all sessions with replayIDs
  useEffect(() => {
    if (
      query?.form?.uuid && query?.time?.start && query?.time?.end && // Required
      ((prevQuery && ( // Differences relevant to this context
        (prevQuery?.form?.uuid !== query?.form?.uuid) ||
        (JSON.stringify(prevQuery.filters) !== JSON.stringify(query.filters)) ||
        (JSON.stringify(prevQuery.sessionOutcomes) !== JSON.stringify(query.sessionOutcomes)) ||
        (!prevQuery.time || (prevQuery.time.start !== query.time.start) ||
          (prevQuery.time &&
            (JSON.stringify(prevQuery.time.start) !== JSON.stringify(query.time.start) ||
            (JSON.stringify(prevQuery.time.end) !== JSON.stringify(query.time.end))))) ||
        (prevQuery.sessionFilters && (
          (prevQuery?.sessionFilters?.abandonedFieldIdentifier !== query?.sessionFilters?.abandonedFieldIdentifier) ||
          (prevQuery?.sessionFilters?.interactedWithFieldIdentifiers !== query?.sessionFilters?.interactedWithFieldIdentifiers) || // TODO: improve this match
          (prevQuery.sessionFilters.customEventTypes !== query.sessionFilters.customEventTypes) || // TODO: improve this match
          (prevQuery?.sessionFilters?.metrics !== query?.sessionFilters?.metrics) // TODO: improve this match
        ))
      )))
    ) {
      (async () => {
        const progressID = setInterval(() =>
          setSessionsLoadingProgress((prevProgress) => prevProgress <= 100 ? prevProgress + 20 : prevProgress), 200);
        try {
          setSessionsLoading(true);
          setSessionsError(null);
          setTrackedSessionsWithoutReplays(false);

          const {
            form, time, filters, sessionOutcomes,
            sessionFilters: {
              interactedWithFieldIdentifiers = null, abandonedFieldIdentifier = null, metrics = null, customEventTypes = null,
            } = {},
          } = query;
          const { data } = await api.get('/replays/sessions', {
            params: {
              formUuid: form.uuid,
              time: {
                start: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
                end: time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
              },
              filters: filters?.reduce((acc, {key, label: value}) => {
                if (!acc.hasOwnProperty(key)) acc[key] = [];
                acc[key].push(value);
                return acc;
              }, {}),
              sessionOutcome: sessionOutcomes?.map(({value}) => value),
              ...abandonedFieldIdentifier && {abandonedFieldIdentifier},
              ...interactedWithFieldIdentifiers && {interactedWithFieldIdentifiers},
              ...metrics && {metrics},
              ...customEventTypes && {customEventTypes},
            }
          });
          const sessions: ReplaySession[] = data.sessions;
          setSessionsLoadingProgress(100);
          if (!sessions.length) setSessionsError(noDataError);
          setSessions(sessions.map(s => ({...s, activeDuration: (s.completedAt || s.abandonedAt) - s.startedAt})));
        } catch (e) {
          setSessionsLoadingProgress(100);
          switch (e?.response?.status) {
          case 401:
            setSessionsError('Not logged in');
            break;
          case 404:
            setSessionsError('Form not found');
            break;
          default:
            setSessionsError(defaultError);
          }
        } finally {
          clearInterval(progressID);
          setSessionsLoadingProgress(0);
          setSessionsLoading(false);
        }
      })();
    }
  }, [prevQuery, query]);

  useEffect(() => {
    if (!formsLoading && formsLoadingError) {
      setSessionsLoading(false);
      setSessionsError(formsLoadingError);
    }
  }, [formsLoading, formsLoadingError]);

  // Check if there are replays for tracked sessions when an org has replayEnabled
  useEffect(() => {
    if (query?.form?.organisation?.replayEnabled && prevSessionsLoading && !sessionsLoading && !sessions?.length) {
      (async () => {
        try {
          const {data: {count}} = await api.get('/data/sessions/stats', {
            params: {
              formUuid: query?.form?.uuid,
              metric: 'count',
              timePeriod: {
                start: query?.time?.start.clone().utc().format('x'), // Requires date math or a unix timestamp
                end: query?.time?.end.clone().utc().format('x'), //  Requires date math or a unix timestamp
              },
            },
          });
          setTrackedSessionsWithoutReplays(!!count);
        } catch {
          // Do nothing - the info message won't show
        }
      })();
    }

  }, [query?.form?.organisation, query?.form?.uuid, query?.time, sessionsLoading, prevSessionsLoading, sessions]);

  return <ReplayContext.Provider value={{ sessions, sessionsLoading, sessionsLoadingProgress, sessionsError, trackedSessionsWithoutReplays,
    sessionsTablePage, setSessionsTablePage,
  }}>{children}</ReplayContext.Provider>;
};

export default ReplayProvider;
