import React, { useContext, useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import Alert from 'react-bootstrap/Alert';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Card from 'react-bootstrap/Card';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import {
  LineController, LineElement, PointElement, LinearScale, TimeSeriesScale, // For time series line charts
  BarController, BarElement, CategoryScale, // For bar charts
  Tooltip, Legend, Filler // Additional utils
} from 'chart.js';
import { ReactChart } from 'chartjs-react';
import { Chart } from 'chart.js';
import 'chartjs-adapter-moment';
//TODO: remove these library imports once happy with the transfer to imported icons
import 'font-awesome/css/font-awesome.css';
import 'line-awesome/dist/line-awesome/css/line-awesome.css';
import { VscChromeClose, VscWarning, VscCheck } from "react-icons/vsc";
import { FaCog, FaInfoCircle, FaSpinner, FaCaretDown, FaCaretUp } from "react-icons/fa";
import { GrDocumentPdf } from "react-icons/gr";
import { BsFiletypePng } from "react-icons/bs";
import Select from 'react-select';
import moment from 'moment-timezone';
import VSCAPointsImg from '../../images/VSCA-chart-points.png';
import VSCAStatsImg from '../../images/VSCA-stats.png';
import CustomEventsTotal from '../../images/CustomEventsTotal.png';
import CustomEventsOverview from '../../images/CustomEventsOverview.png';
import CustomEventsOverviewSessionSplit from '../../images/CustomEventsOverviewSessionSplit.png';
import CompletionPathImg from '../../images/CompletionPathImg.png';

import NavBar from '../../NavBar';
import DatePicker from '../../Components/DatePicker';
import DataTable from '../../Components/DataTable';
import NoFormsMsg from '../../Components/NoFormsMsg';
import AppAlerts from '../../Components/AppAlerts';
import PrintPageHeader from '../../Components/PrintPageHeader';
import ScrollToTop from '../../Components/ScrollToTop';
import ScrollToElement from '../../Components/ScrollToElement';
import FeedbackRow from '../../Components/FeedbackRow';
import CopyUrlIcon from '../../Components/CopyUrlIcon';
import FiltersSelect from '../../Components/Select/FiltersSelect';
import ReplayLinkGridItem from '../../Components/ReplayLinkGridItem';
import SessionReplayCTAModal from '../../Components/SessionReplayCTAModal';
import ChartTip from '../../Components/ChartTip';
import CompletionPathChart from './CompletionPathChart';
import { generatePdfDownload, maxLabels } from '../../helpers/pdf';
import AppContext from '../../AppContext';
import api from '../../api';
import { usePrevious, useAppQuery, useAppForms } from '../../hooks';
import {
  getSession,
  updateSession,
  formatFiltersForSelectDropDown,
  compileQueryString,
  formatSecondsToTimeString,
  labelForField,
  defaultAttributes,
  formatFormSelectOptions,
  formatToCurrencyWithDecimals,
  orgDetailsForMixpanel,
} from '../../utils';
import { acceptedGranularity } from '../../App';
import Forms from '../../forms';
import htmlLegendPlugin from '../../plugins/chartjs-plugin-html-legend';

import './FormData.scss';

ReactChart.register(LineController, LineElement, PointElement, LinearScale, BarController,  BarElement, CategoryScale, Tooltip, Legend, TimeSeriesScale, Filler);

const vscaColours = {
  Views: '#05B2DA',
  Starters: '#EA2E5D',
  Completions: '#0267BF',
  Abandons: '#F0764A',
  'Views to Starters %': '#05B2DA',
  'Starters to Completions %': '#EA2E5D',
  'Views to Completions %': '#0267BF',
};

const FormData = ({mixpanel}) => {
  const history = useHistory();
  const { hash } = useLocation();
  useAppForms();
  const {
    setQuery,
  } = useContext(AppContext);
  const { prevQuery } = useAppQuery(); // We still need prevQuery from the hook to maintain the query's 'prev' state between page changes

  const { currentUser, formsGroupedByOrg, formsGroupedByFormUuid, formsLoading, formsLoadingError, query } = useContext(AppContext);

  const { form, time, granularity, filters, submitFieldIdentifier } = query || {};

  // Query selections
  const [selectedForm, setSelectedForm] = useState();
  const [selectedTime, setSelectedTime] = useState();
  const [selectedFilters, setSelectedFilters] = useState();
  const [selectedGranularity, setSelectedGranularity] = useState();
  const [vscaSplitByAttrMetric, setVscaSplitByAttrMetric] = useState({});
  const [vscaSplitByAttrKey, setVscaSplitByAttrKey] = useState({});
  const [submitField, setSubmitField] = useState();
  const [submitFieldChangeFromChart, setSubmitFieldChangeFromChart] = useState(false);

  const [cancelButtonEnabled, setCancelButtonEnabled] = useState(false);
  const [applyButtonEnabled, setApplyButtonEnabled] = useState(false);

  // Data that changes when form changes
  const [availableFilters, setAvailableFilters] = useState();
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [filtersLoadingError, setFiltersLoadingError] = useState(null);
  const [attributesKeysOptions, setAttributesKeysOptions] = useState([]);
  const [attributesKeysLoading, setAttributesKeysLoading] = useState(false);
  const [attributesKeysLoadingError, setAttributesKeysLoadingError] = useState(null);
  const [vscaSplitByAttrKeyValidated, setVscaSplitByAttrKeyValidated] = useState(false);
  const [trackedFields, setTrackedFields] = useState([]);
  const [trackedFieldsLoading, setTrackedFieldsLoading] = useState(false);

  // Vis data
  const [vscaData, setVscaData] = useState(null);
  const [vscaLoading, setVscaLoading] = useState(true);
  const [vscaProgress, setVscaProgress] = useState(20);
  const [vscaError, setVscaError] = useState(null);
  const [vscaSplitByAttrData, setVscaSplitByAttrData] = useState(null);
  const [vscaSplitByAttrLoading, setVscaSplitByAttrLoading] = useState(true);
  const [vscaSplitByAttrProgress, setVscaSplitByAttrProgress] = useState(20);
  const [vscaSplitByAttrError, setVscaSplitByAttrError] = useState(null);
  const [vscRatesData, setVscRatesData] = useState(null);
  const [vscRatesLoading, setVscRatesLoading] = useState(true);
  const [vscRatesProgress, setVscRatesProgress] = useState(20);
  const [vscRatesError, setVscRatesError] = useState(null);
  const [medianDurationData, setMedianDurationData] = useState(null);
  const [medianDurationLoading, setMedianDurationLoading] = useState(true);
  const [medianDurationProgress, setMedianDurationProgress] = useState(20);
  const [medianDurationError, setMedianDurationError] = useState(null);
  const [avgFieldReturnsData, setAvgFieldReturnsData] = useState(null);
  const [avgFieldReturnsLoading, setAvgFieldReturnsLoading] = useState(true);
  const [avgFieldReturnsProgress, setAvgFieldReturnsProgress] = useState(20);
  const [avgFieldReturnsError, setAvgFieldReturnsError] = useState(null);
  const [failedSubmissionsData, setFailedSubmissionsVisData] = useState(null);
  const [failedSubmissionsLoading, setFailedSubmissionsVisLoading] = useState(true);
  const [failedSubmissionsProgress, setFailedSubmissionsVisProgress] = useState(0);
  const [failedSubmissionsError, setFailedSubmissionsVisError] = useState('');
  const [customEventsTotalData, setCustomEventsTotalData] = useState(null);
  const [customEventsTotalLoading, setCustomEventsTotalLoading] = useState(true);
  const [customEventsTotalProgress, setCustomEventsTotalProgress] = useState(20);
  const [customEventsTotalError, setCustomEventsTotalError] = useState(null);
  const [customEventsOverviewData, setCustomEventsOverviewData] = useState(null);
  const [customEventsOverviewLoading, setCustomEventsOverviewLoading] = useState(true);
  const [customEventsOverviewProgress, setCustomEventsOverviewProgress] = useState(20);
  const [customEventsOverviewError, setCustomEventsOverviewError] = useState(null);
  const [completionPathData, setCompletionPathData] = useState(null);
  const [completionPathLoading, setCompletionPathLoading] = useState(true);
  const [completionPathProgress, setCompletionPathProgress] = useState(20);
  const [completionPathError, setCompletionPathError] = useState(null);

  // Vis info flags
  const [showCompletionPathInfo, setShowCompletionPathInfo] = useState(null);
  const [showVscaInfo, setShowVscaInfo] = useState(null);
  const [showVscaSplitByInfo, setShowVscaSplitByInfo] = useState(null);
  const [showVscRatesInfo, setShowVscRatesInfo] = useState(null);
  const [showMedianSessionInfo, setShowMedianSessionInfo] = useState(null);
  const [showAvgFieldReturnsInfo, setShowAvgFieldReturnsInfo] = useState(null);
  const [showFailedSubmissionsInfo, setShowFailedSubmissionsInfo] = useState(false);
  const [showCustomEventsTotalInfo, setShowCustomEventsTotalInfo] = useState(null);
  const [showCustomEventsOverviewInfo, setShowCustomEventsOverviewInfo] = useState(null);

  // Image URLs and charts loaded status for PDF export
  const [dataLoadedForPDF, setDataLoadedForPDF] = useState(false);
  const [vscaChartImgUrl, setVscaChartImgUrl] = useState(null);
  const [vscaSplitByChartImgUrl, setVscaSplitByChartImgUrl] = useState(null);
  const [vscRatesChartImgUrl, setVscRatesChartImgUrl] = useState(null);
  const [medianDurationChartImgUrl, setMedianDurationChartImgUrl] = useState(null);
  const [avgFieldReturnsChartImgUrl, setAvgFieldReturnsChartImgUrl] = useState(null);
  const [customEventsTotalChartImgUrl, setCustomEventsTotalChartImgUrl] = useState(null);
  const [failedSubmissionsChartImgUrl, setFailedSubmissionsChartImgUrl] = useState(null);
  const [pdfRequested, setPdfRequested] = useState(false);
  const [exportError, setExportError] = useState();

  const [totalCompletions, setTotalCompletions] = useState();
  const [completionValueCurrencyCode, setCompletionValueCurrencyCode] = useState();
  const [completionValue, setCompletionValue] = useState();
  const [completionValueSaved, setCompletionValueSaved] = useState();
  const [completionValueError, setCompletionValueError] = useState();
  const [lostOpportunitySessions, setLostOpportunitySessions] = useState();
  const [saveCompletionValueEnabled, setSaveCompletionValueEnabled] = useState();

  const [showSessionReplayCTAModal, setShowSessionReplayCTAModal] = useState(false);

  const [reportVisible, setReportVisible] = useState(true);

  const prevForm = usePrevious(form);
  const prevTime = usePrevious(time);
  const prevGranularity = usePrevious(granularity);
  const prevFilters = usePrevious(filters);
  const prevSelectedTime = usePrevious(selectedTime);
  const prevSelectedForm = usePrevious(selectedForm);
  const prevSubmitField = usePrevious(submitField);
  const prevTrackedFieldsLoading = usePrevious(trackedFieldsLoading);
  const prevVscaSplitByAttrMetric = usePrevious(vscaSplitByAttrMetric);
  const prevVscaSplitByAttrKey = usePrevious(vscaSplitByAttrKey);
  const prevAttributesKeysOptions = usePrevious(attributesKeysOptions);
  const prevVscaSplitByAttrKeyValidated = usePrevious(vscaSplitByAttrKeyValidated);
  const prevDataLoadedForPDF = usePrevious(dataLoadedForPDF);

  const [hasScrolledToFailedSubmissions, setHasScrolledToFailedSubmissions] = useState(false);
  const [hasScrolledToAverageFieldReturns, setHasScrolledToAverageFieldReturns] = useState(false);
  const failedSubmissionsChartRef = useRef();
  const averageFieldReturnsChartRef = useRef();
  const vscaRef = useRef(null);
  const vscRatesRef = useRef(null);

  const submitFieldIdentifierForQS = useCallback(() => {
    // Provide the field if requesting the same form, otherwise allow it to be unset
    return (!selectedForm || (selectedForm.uuid === form.uuid)) ? submitField?.identifier: null;
  },[selectedForm, form?.uuid, submitField?.identifier]);

  const initiatePdfDownload = useCallback(async () => {
    try {
      await generatePdfDownload({
        page: 'FormData',
        title: 'Form Data',
        orgName: form?.organisation?.name,
        form, time, filters,
        queryString: compileQueryString({form, time, granularity, filters, submitFieldIdentifier: submitFieldIdentifierForQS()}),
        data: {
          vscaChartImgUrl, vscaSplitByChartImgUrl, vscaData, vscaSplitByAttrData, vscRatesChartImgUrl, avgFieldReturnsChartImgUrl, customEventsTotalData, customEventsTotalChartImgUrl, medianDurationChartImgUrl,
          avgFieldReturnsData, medianDurationData, vscRatesData, customEventsOverviewData, failedSubmissionsChartImgUrl, submitField,
        }
      });
    } catch (e) {
      setExportError(true);
    } finally {
      setPdfRequested(false);
    }
  }, [vscaChartImgUrl, vscaSplitByChartImgUrl, vscaData, vscaSplitByAttrData, vscRatesChartImgUrl, avgFieldReturnsChartImgUrl, customEventsTotalData, customEventsTotalChartImgUrl, medianDurationChartImgUrl,
    avgFieldReturnsData, medianDurationData, vscRatesData, customEventsOverviewData, failedSubmissionsChartImgUrl, submitField, form, time, granularity, filters,
    submitFieldIdentifierForQS,
  ]);

  const loadVscaSplitByAttr = useCallback(async () => {
    const progressID = setInterval(() => setVscaSplitByAttrProgress((prevProgress) => prevProgress + 30), 50);
    try {
      setVscaSplitByAttrLoading(true);
      setVscaSplitByAttrData(null);
      setVscaSplitByAttrError(null);
      setVscaSplitByChartImgUrl(null);
      setDataLoadedForPDF(false);

      const { data } = await api.get('/visualisations/vsca-split-by-attribute', {
        params: {
          form: {uuid: form.uuid},
          timePeriod: {start: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'), end: time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]')},
          granularity,
          timeZone: form?.organisation?.timeZone,
          filters: filters.reduce((acc, {key, label: value}) => {
            if (!acc.hasOwnProperty(key)) acc[key] = [];
            acc[key].push(value);
            return acc;
          }, {}),
          metric: vscaSplitByAttrMetric.value,
          attributeKey: vscaSplitByAttrKey.value,
        },
      });
      setVscaSplitByAttrProgress(100);
      const isData = (data && data.chart.labels.length > 0);
      if (!isData) {
        setVscaSplitByAttrError('No data to display');
      } else {
        data.chart.datasets = formatVscaSplitByAttrChartData(data.chart.datasets);
        data.stats = formatChartStats(data.stats);
        setVscaSplitByAttrData(data);
      }
      setVscaSplitByAttrLoading(false);
      setVscaSplitByAttrProgress(0);
      clearInterval(progressID);
    } catch (e) {
      setVscaSplitByAttrProgress(100);
      setVscaSplitByAttrError((e.response && (e.response.status === 404)) ? 'Form not found' :
        (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
      setVscaSplitByAttrLoading(false);
      setVscaSplitByAttrProgress(0);
      clearInterval(progressID);
    }
  },[form?.uuid, form?.organisation?.timeZone, granularity, filters, time?.end, time?.start, vscaSplitByAttrMetric.value, vscaSplitByAttrKey.value]);

  const loadCompletionPath = useCallback(async () => {
    setCompletionPathProgress(0);
    const progressID = setInterval(() => setCompletionPathProgress((prevProgress) => prevProgress + 30), 50);
    try {
      setCompletionPathLoading(true);
      setCompletionPathData(null);
      setCompletionPathError(null);

      const { data: { stages } } = await api.get('/visualisations/completion_path', {
        params: {
          formUuid: form.uuid,
          startTime: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          endTime: time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          submitFieldIdentifier: submitFieldIdentifier || submitField.identifier, // submitField.identifier is only used for a default selection
          filters: filters.reduce((acc, {key, label: value}) => {
            if (!acc.hasOwnProperty(key)) acc[key] = [];
            acc[key].push(value);
            return acc;
          }, {}),
        },
      });

      setCompletionPathProgress(100);
      if (!stages || !stages.length) {
        setCompletionPathError('No data to display');
        setLostOpportunitySessions(null);
      } else {
        setCompletionPathData(stages);
        setLostOpportunitySessions([
          stages[1][1][0],
          stages[2][1][0],
          stages[4][1][0],
        ]);
      }
    } catch (e) {
      setLostOpportunitySessions(null);
      setCompletionPathProgress(100);
      setCompletionPathError((e.response && (e.response.status === 404)) ? 'Form not found' :
        (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
    } finally {
      setCompletionPathLoading(false);
      setCompletionPathProgress(0);
      clearInterval(progressID);
    }
  }, [form?.uuid, filters, time?.end, time?.start, submitFieldIdentifier, submitField]);

  const loadFailedSubmissions = useCallback(async () => {
    setFailedSubmissionsVisProgress(0);
    const progressID = setInterval(() => setFailedSubmissionsVisProgress((prevProgress) => prevProgress + 30), 50);
    try {
      setFailedSubmissionsVisLoading(true);
      setFailedSubmissionsVisData(null);
      setFailedSubmissionsVisError(null);
      setFailedSubmissionsChartImgUrl(null);
      setDataLoadedForPDF(false);
      const {data} = await api.get('/visualisations/failed-submissions', {
        params: {
          formUuid: form.uuid,
          startTime: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          endTime: time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          submitFieldIdentifier: submitFieldIdentifier || submitField.identifier, // submitField.identifier is only used for a default selection
          timeZone: form?.organisation?.timeZone,
          granularity,
          filters: filters.reduce((acc, {key, label: value}) => {
            if (!acc.hasOwnProperty(key)) acc[key] = [];
            acc[key].push(value);
            return acc;
          }, {}),
        },
      });
      setFailedSubmissionsVisProgress(100);
      if (!(data && data.labels && data.labels.length > 0)) {
        setFailedSubmissionsVisError('No data to display');
      } else {
        data.datasets = data.datasets.map((dataset) => ({
          label: dataset.label,
          data: dataset.data,
          backgroundColor: '#F0764A',
          borderColor: '#F0764A',
          fill: false,
          borderWidth: 1,
          pointRadius: 1,
          pointHoverBorderWidth: 1,
        }));
        setFailedSubmissionsVisData(data);
      }
      setFailedSubmissionsVisLoading(false);
      setFailedSubmissionsVisProgress(0);
      clearInterval(progressID);
    } catch (e) {
      setFailedSubmissionsVisProgress(100);
      setFailedSubmissionsVisError((e.response && (e.response.status === 404)) ? 'Form not found' :
        (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
      setFailedSubmissionsVisLoading(false);
      setFailedSubmissionsVisProgress(0);
      clearInterval(progressID);
    }
  }, [form?.uuid, form?.organisation?.timeZone, granularity, filters, time?.end, time?.start, submitFieldIdentifier, submitField]);

  const handleSaveCompletionValue = async (e) => {
    e.preventDefault();
    try {
      setCompletionValueSaved(false);
      setCompletionValueError(null);

      await Forms.update(form.uuid, {
        completionValue,
        completionValueCurrencyCode,
      }, form.organisation);

      setQuery((prev) => ({...prev, form: {...prev.form, completionValue: Number(completionValue), completionValueCurrencyCode}}));
      setCompletionValueSaved(true);
      setSaveCompletionValueEnabled(false);
      mixpanel.track('Changed Completion Value', { page: 'FormData', ...orgDetailsForMixpanel(form?.organisation)});
    } catch (e) {
      switch (e?.response?.status) {
      case 401:
        setCompletionPathError('Not logged in');
        break;
      case 403:
        history.replace('/');
        break;
      default:
        setCompletionValueError('Something went wrong. The value has not been saved.');
      }
    }
  };

  useEffect(() => {
    setCompletionValueCurrencyCode(form?.completionValueCurrencyCode || 'usd');
  }, [form?.completionValueCurrencyCode]);

  useEffect(() => {
    setCompletionValue(form?.completionValue);
  }, [form?.completionValue]);

  // Once a new query has been set - proceed to fetch data
  useEffect(() => {
    if (prevQuery && query && (prevQuery !== query) && // The query has been set on page load or replaced via query params
        !submitFieldChangeFromChart &&
        query.form?.uuid && query.form.organisation?.timeZone && query.time?.start && query.time?.end && query.granularity && // Required
        ((query.form?.uuid !== prevQuery.form?.uuid) ||
        ((query.form?.uuid === prevQuery.form?.uuid) &&
        (query.form?.completionValue === prevQuery.form?.completionValue) && // excluding changes to form completion value
        (query.form?.completionValueCurrencyCode === prevQuery.form?.completionValueCurrencyCode))) // excluding changes to form completion value currency code
    ) {
      const {form, time, filters, granularity } = query;

      // loadVsca();
      (async () => {
        const progressID = setInterval(() => setVscaProgress((prevProgress) => prevProgress + 30), 50);
        try {
          setVscaLoading(true);
          setVscaData(null);
          setVscaError(null);
          setVscaChartImgUrl(null);
          setDataLoadedForPDF(false);

          const { data } = await api.get('/visualisations/vsca-chart', {
            params: {
              form: {uuid: form.uuid},
              timePeriod: {start: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'), end: time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]')},
              granularity,
              timeZone: form?.organisation?.timeZone,
              filters: filters.reduce((acc, {key, label: value}) => {
                if (!acc.hasOwnProperty(key)) acc[key] = [];
                acc[key].push(value);
                return acc;
              }, {}),
            },
          });
          setVscaProgress(100);
          const isData = (data && data.chart.labels.length > 0);
          if (!isData) {
            setVscaError('No data to display');
          } else {
            setTotalCompletions(data.stats.completions.current);

            data.chart.datasets = formatVscaChartsData(data.chart.datasets);
            data.stats = formatChartStats(data.stats);
            setVscaData(data);
          }
          setVscaLoading(false);
          setVscaProgress(0);
          clearInterval(progressID);
        } catch (e) {
          setVscaProgress(100);
          setVscaError((e.response && (e.response.status === 404)) ? 'Form not found' :
            (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
          setVscaLoading(false);
          setVscaProgress(0);
          clearInterval(progressID);
        }
      })();

      // NB. Data for Completion Path and Failed Submission can only load when the tracked fields have loaded and one is selected
      // Clear the data early to start the progress bar asap, and to ensure the PDF only receives the updated chart img
      setCompletionPathLoading(true);
      setCompletionPathError(null);
      setCompletionPathData(null);
      setCompletionPathProgress(10);
      setCompletionValueError(null);
      setLostOpportunitySessions(null);

      setFailedSubmissionsVisLoading(true);
      setFailedSubmissionsVisError(null);
      setFailedSubmissionsVisData(null);
      setFailedSubmissionsVisProgress(10);
      setFailedSubmissionsChartImgUrl(null);
      setDataLoadedForPDF(false);

      // loadTrackedFields
      (async () => {
        try {
          setTrackedFieldsLoading(true);
          setTrackedFields([]);
          const {data: {fields: trackedFields}} = await api.get(`/data/sessions/fields`, {
            params: {
              formUuid: form.uuid,
              time: {
                start: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
                end: time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
              },
              filters: filters.reduce((acc, {key, label: value}) => {
                if (!acc.hasOwnProperty(key)) acc[key] = [];
                acc[key].push(value);
                return acc;
              }, {}),
            },
          });
          setTrackedFields(
            trackedFields
              .map(field => ({...field, label: labelForField(field), value: field.identifier}))
              .filter(field => field.hasOwnProperty('hidden') ? !field.hidden : true)
              .sort(({order: orderA}, {order: orderB}) => (orderA === null || orderA === '') - (orderB === null || orderB === '') || (orderA > orderB) - (orderA < orderB)));
        } catch (e) {
        } finally {
          setTrackedFieldsLoading(false);
        }
      })();

      // NB. Data for VSCA Split By chart is only loaded when the attributes keys have loaded and one is selected
      // Clear the data early to start the progress bar asap, and to ensure the PDF only receives the updated chart img
      setVscaSplitByAttrLoading(true);
      setVscaSplitByAttrData(null);
      setVscaSplitByAttrProgress(10);
      setVscaSplitByChartImgUrl(null);
      setDataLoadedForPDF(false);

      // fetchAttributeKeys
      (async () => {
        try {
          setAttributesKeysLoading(true);
          setAttributesKeysLoadingError(null);
          setVscaSplitByAttrKeyValidated(false);

          const { data } = await api.get('/session/attribute_keys', {
            params: {
              form: {uuid: form.uuid},
              timePeriod: {start: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'), end: time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]')},
              filters: filters.reduce((acc, {key, label: value}) => {
                if (!acc.hasOwnProperty(key)) acc[key] = [];
                acc[key].push(value);
                return acc;
              }, {}),
            },
          });
          setAttributesKeysLoading(false);
          setAttributesKeysOptions(data.map(({id}) => ({label: id, value: id})));
        } catch (e) {
          setAttributesKeysLoading(false);
          setAttributesKeysLoadingError((e.response && (e.response.status === 404)) ? 'Form not found' :
            (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Error fetching attributes keys');
        }
      })();

      // loadVscRates
      (async () => {
        const progressID = setInterval(() => setVscRatesProgress((prevProgress) => prevProgress + 30), 50);
        try {
          setVscRatesLoading(true);
          setVscRatesData(null);
          setVscRatesError(null);
          setVscRatesChartImgUrl(null);
          setDataLoadedForPDF(false);

          const { data } = await api.get('/visualisations/vsc-rates', {
            params: {
              form: {uuid: form.uuid},
              timePeriod: {start: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'), end: time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]')},
              granularity,
              timeZone: form?.organisation?.timeZone,
              filters: filters.reduce((acc, {key, label: value}) => {
                if (!acc.hasOwnProperty(key)) acc[key] = [];
                acc[key].push(value);
                return acc;
              }, {}),
            },
          });
          setVscRatesProgress(100);
          const isData = (data && data.chart.labels.length > 0);
          if (!isData) {
            setVscRatesError('No data to display');
          } else {
            data.chart.datasets = formatVscaChartsData(data.chart.datasets);
            data.stats = formatChartStats(data.stats);
            setVscRatesData(data);
          }
          setVscRatesLoading(false);
          setVscRatesProgress(0);
          clearInterval(progressID);
        } catch (e) {
          setVscRatesProgress(100);
          setVscRatesError((e.response && (e.response.status === 404)) ? 'Form not found' :
            (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
          setVscRatesLoading(false);
          setVscRatesProgress(0);
          clearInterval(progressID);
        }
      })();

      // loadMedianDuration
      (async () => {
        const progressID = setInterval(() => setMedianDurationProgress((prevProgress) => prevProgress + 30), 50);
        try {
          setMedianDurationLoading(true);
          setMedianDurationData(null);
          setMedianDurationError(null);
          setMedianDurationChartImgUrl(null);
          setDataLoadedForPDF(false);

          const res = await api.get('/visualisations/median-session-duration-chart', {
            params: {
              formUuid: form.uuid,
              startTime: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
              endTime: time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
              granularity,
              timeZone: form?.organisation?.timeZone,
              filter: filters.reduce((acc, {key, label: value}) => {
                if (!acc.hasOwnProperty(key)) acc[key] = [];
                acc[key].push(value);
                return acc;
              }, {}),
            },
          });

          const data = res.data;
          setMedianDurationProgress(100);
          const isData = (data && data.chart.range.length > 0);
          if (!isData) {
            setMedianDurationError('No data to display');
          } else {
            data.chart = formatMedianDurationData(data.chart);
            data.stats = formatChartStats(data.stats);
            setMedianDurationData(data);
          }
          setMedianDurationLoading(false);
          setMedianDurationProgress(0);
          clearInterval(progressID);
        } catch (e) {
          setMedianDurationProgress(100);
          setMedianDurationError((e.response && (e.response.status === 404)) ? 'Form not found' :
            (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
          setMedianDurationLoading(false);
          setMedianDurationProgress(0);
          clearInterval(progressID);
        }
      })();

      // loadAvgFieldReturns
      (async () => {
        const progressID = setInterval(() => setAvgFieldReturnsProgress((prevProgress) => prevProgress + 30), 50);
        try {
          setAvgFieldReturnsLoading(true);
          setAvgFieldReturnsData(null);
          setAvgFieldReturnsError(null);
          setAvgFieldReturnsChartImgUrl(null);
          setDataLoadedForPDF(false);

          const res = await api.get('/visualisations/avg-field-returns-chart', {
            params: {
              formUuid: form.uuid,
              startTime: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
              endTime: time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
              granularity,
              timeZone: form?.organisation?.timeZone,
              filter: filters.reduce((acc, {key, label: value}) => {
                if (!acc.hasOwnProperty(key)) acc[key] = [];
                acc[key].push(value);
                return acc;
              }, {}),
            },
          });

          const data = res.data;
          setAvgFieldReturnsProgress(100);
          const isData = (data && data.chart.range.length > 0);
          if (!isData) {
            setAvgFieldReturnsError('No data to display');
          } else {
            data.chart = formatAvgFieldReturnsData(data.chart);
            data.stats = formatChartStats(data.stats);
            setAvgFieldReturnsData(data);
          }
          setAvgFieldReturnsLoading(false);
          setAvgFieldReturnsProgress(0);
          clearInterval(progressID);
        } catch (e) {
          setAvgFieldReturnsProgress(100);
          setAvgFieldReturnsError((e.response && (e.response.status === 404)) ? 'Form not found' :
            (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
          setAvgFieldReturnsLoading(false);
          setAvgFieldReturnsProgress(0);
          clearInterval(progressID);
        }
      })();

      // loadCustomEventsTotal
      (async () => {
        const progressID = setInterval(() => setCustomEventsTotalProgress((prevProgress) => prevProgress + 30), 50);
        try {
          setCustomEventsTotalLoading(true);
          setCustomEventsTotalData(null);
          setCustomEventsTotalError(null);
          setCustomEventsTotalChartImgUrl(null);
          setDataLoadedForPDF(false);

          const res = await api.get('/visualisations/custom-events-chart', {
            params: {
              formUuid: form.uuid,
              startTime: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
              endTime: time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
              filter: filters.reduce((acc, {key, label: value}) => {
                if (!acc.hasOwnProperty(key)) acc[key] = [];
                acc[key].push(value);
                return acc;
              }, {}),
            },
          });
          const data = res.data;
          setCustomEventsTotalProgress(100);
          const isData = (data && data.events.length > 0);
          if (!isData) {
            setCustomEventsTotalError('No data to display');
          } else {
            setCustomEventsTotalData({
              labels: data.events,
              datasets: [
                {
                  label: data.metrics[0],
                  data: data.abandonedTotals,
                  backgroundColor: 'rgb(240, 118, 74, 0.8)',
                  borderColor:'rgb(240, 118, 74, 0.6)',
                  maxBarThickness: 100,
                },
                {
                  label: data.metrics[1],
                  data: data.completedTotals,
                  backgroundColor: 'rgb(2, 103, 191, 0.8)',
                  borderColor:'rgb(2, 103, 191, 0.6)',
                  maxBarThickness: 100,
                }
              ]
            });
          }
          setCustomEventsTotalLoading(false);
          setCustomEventsTotalProgress(0);
          clearInterval(progressID);
        } catch (e) {
          setCustomEventsTotalProgress(100);
          setCustomEventsTotalError((e.response && (e.response.status === 404)) ? 'Form not found' :
            (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
          setCustomEventsTotalLoading(false);
          setCustomEventsTotalProgress(0);
          clearInterval(progressID);
        }
      })();

      // loadCustomEventsOverview
      (async () => {
        const progressID = setInterval(() => setCustomEventsOverviewProgress((prevProgress) => prevProgress + 30), 50);
        try {
          setCustomEventsOverviewLoading(true);
          setCustomEventsOverviewData(null);
          setCustomEventsOverviewError(null);
          setDataLoadedForPDF(false);

          const res = await api.get('/visualisations/custom-events-table', {
            params: {
              formUuid: form.uuid,
              startTime: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
              endTime: time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
              filter: filters.reduce((acc, {key, label: value}) => {
                if (!acc.hasOwnProperty(key)) acc[key] = [];
                acc[key].push(value);
                return acc;
              }, {}),
            },
          });

          const data = res.data;
          setCustomEventsOverviewProgress(100);
          const isData = (data && Array.isArray(data) && data.length > 0);
          const columns = [
            {
              Header: 'Event',
              accessor: 'eventType',
            },
            {
              Header: 'Total Count',
              columns: [
                {
                  Header: 'All',
                  accessor: 'combinedCount',
                  Cell: formatCellToStr,
                },
                {
                  Header: 'Abandoned',
                  accessor: 'abandonedCount',
                  Cell: formatCellToStr,
                },
                {
                  Header: 'Completed',
                  accessor: 'completedCount',
                  Cell: formatCellToStr,
                }
              ]
            },
            {
              Header: '% Sessions Occurred In',
              columns: [
                {
                  Header: 'All',
                  accessor: 'combinedProportion',
                  Cell: formatCellToPercent,
                },
                {
                  Header: 'Abandoned',
                  accessor: 'abandonedProportion',
                  Cell: formatCellToPercent,
                },
                {
                  Header: 'Completed',
                  accessor: 'completedProportion',
                  Cell: formatCellToPercent,
                }
              ]
            },
            {
              Header: 'Mean Times Occurred',
              columns: [
                {
                  Header: 'All',
                  accessor: 'combinedMeanTimesPerSession',
                  Cell: formatCellToStr,
                },
                {
                  Header: 'Abandoned',
                  accessor: 'abandonedMeanTimesPerSession',
                  Cell: formatCellToStr,
                },
                {
                  Header: 'Completed',
                  accessor: 'completedMeanTimesPerSession',
                  Cell: formatCellToStr,
                }
              ]
            }
          ];

          if (!isData) {
            setCustomEventsOverviewError('No data to display');
          } else {
            setCustomEventsOverviewData({data, columns});
          }
          setCustomEventsOverviewLoading(false);
          setCustomEventsOverviewProgress(0);
          clearInterval(progressID);
        } catch (e) {
          setCustomEventsOverviewProgress(100);
          setCustomEventsOverviewError((e.response && (e.response.status === 404)) ? 'Form not found' :
            (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
          setCustomEventsOverviewLoading(false);
          setCustomEventsOverviewProgress(0);
          clearInterval(progressID);
        }
      })();
    }
  }, [prevQuery, query, submitFieldChangeFromChart,
    formsGroupedByFormUuid // Even though this isn't used in this effect - we need to re-evaluate the query when it IS set (I think?!)
  ]);

  // Set the field now we have the identifier
  useEffect(() => {
    if (submitFieldIdentifier && trackedFields.length &&
      (!submitField || (submitField.identifier !== submitFieldIdentifier))) {
      const fieldsByIdentifier = trackedFields.reduce((acc, field) => {
        acc[field.identifier] = field;
        return acc;
      }, {});
      const field = fieldsByIdentifier[submitFieldIdentifier];
      if (field) setSubmitField(field);
    }
  }, [trackedFields, submitField, submitFieldIdentifier]);

  // Remove download error message
  useEffect(() => {
    if (exportError) setTimeout(() => {setExportError(null);}, 4000);
  }, [exportError]);

  // Allow PDF to be downloaded once all charts have image URLs
  useEffect(() => {
    if (!prevDataLoadedForPDF && vscaChartImgUrl && vscRatesChartImgUrl && vscaSplitByChartImgUrl && medianDurationChartImgUrl && avgFieldReturnsChartImgUrl) {
      // If these visualisations all have data then enable the PDF
      if (customEventsTotalChartImgUrl && customEventsOverviewData && failedSubmissionsChartImgUrl) setDataLoadedForPDF(true);

      // If some data is missing then still enable PDF download
      if (!customEventsTotalChartImgUrl && !customEventsTotalLoading && !customEventsTotalData && !customEventsOverviewLoading && !customEventsOverviewData &&
          (failedSubmissionsChartImgUrl || (!failedSubmissionsChartImgUrl && !failedSubmissionsLoading))) setDataLoadedForPDF(true);
    }
  },[prevDataLoadedForPDF, vscaChartImgUrl, vscRatesChartImgUrl, vscaSplitByChartImgUrl, medianDurationChartImgUrl, avgFieldReturnsChartImgUrl, customEventsTotalChartImgUrl,
    customEventsTotalLoading, customEventsTotalData, customEventsOverviewData, customEventsOverviewLoading, failedSubmissionsChartImgUrl, failedSubmissionsLoading]);

  // Allow PDF to be downloaded even when no data
  useEffect(() => {
    // No data for VSCA means the time frame requested does not contain any sessions. We can assume that if there wasn't a response for VSCA then there won't be any data in other charts
    if (!prevDataLoadedForPDF && !vscaChartImgUrl && vscaError && !vscaLoading) setDataLoadedForPDF(true);
  }, [prevDataLoadedForPDF, vscaChartImgUrl, vscaError, vscaLoading]);

  // Initiate pdf download if requested, and once charts are ready
  useEffect(() => {
    if (dataLoadedForPDF && pdfRequested) initiatePdfDownload();
  }, [dataLoadedForPDF, pdfRequested, initiatePdfDownload]);

  useEffect(() => {
    // Identify the mixpanel user on page load for any subsequent tracking
    mixpanel.identify(currentUser.email);
  }, [mixpanel, currentUser.email]);

  // Send the page view mixpanel event each time the  app-wide form is updated - which reloads the charts
  useEffect(() => {
    if (form && form.organisation) {
      mixpanel.track('Page View', Object.assign({ page: 'FormData' }, orgDetailsForMixpanel(form?.organisation)));
    }
  }, [mixpanel, form]);

  useEffect(() => {
    if (!formsLoading && formsLoadingError) {
      setVscaLoading(false);
      setVscaError(formsLoadingError);
      setCompletionPathLoading(false);
      setCompletionPathError(formsLoadingError);
      setVscaSplitByAttrLoading(false);
      setVscaSplitByAttrError(formsLoadingError);
      setVscRatesLoading(false);
      setVscRatesError(formsLoadingError);
      setMedianDurationLoading(false);
      setMedianDurationError(formsLoadingError);
      setAvgFieldReturnsLoading(false);
      setAvgFieldReturnsError(formsLoadingError);
      setFailedSubmissionsVisLoading(false);
      setFailedSubmissionsVisError(formsLoadingError);
      setCustomEventsTotalLoading(false);
      setCustomEventsTotalError(formsLoadingError);
      setCustomEventsOverviewLoading(false);
      setCustomEventsOverviewError(formsLoadingError);
    }
  }, [formsLoading, formsLoadingError]);

  // Select the VSCA split by metric and attribute key
  useEffect(() => {
    const { vscaSplitByAttr } = getSession();
    setVscaSplitByAttrMetric((vscaSplitByAttr && vscaSplitByAttr.metric) ? vscaSplitByAttr.metric : {label: 'Abandons', value: 'abandons'});
    setVscaSplitByAttrKey((vscaSplitByAttr && vscaSplitByAttr.attrKey) ? vscaSplitByAttr.attrKey :  {label: 'deviceType', value: 'deviceType'});
  }, []);

  // Select default submit field when fields have loaded
  useEffect(() => {
    if (prevTrackedFieldsLoading && !trackedFieldsLoading && trackedFields.length > 0 &&
      (!submitField || // Either there was none, or the submitFieldIdentifier from params wasn't able to set a valid field
        (submitField && !submitFieldIdentifier)) // The submitFieldIdentifier will have been unset
    ) {
      // Select the first submit field or the first field that has 'submit' in the label
      setSubmitField(trackedFields.find(({htmlType, label}) =>
        (htmlType && htmlType.toLowerCase() === 'submit') || (label && label.match(/submit/i))) ||
        trackedFields[0] // Use default if no match found
      );
    }
  }, [prevTrackedFieldsLoading, trackedFieldsLoading, trackedFields, submitField, submitFieldIdentifier]);

  const observer = useMemo(() => new IntersectionObserver((entries) => {
    const entry = entries[0];
    if (entry.isIntersecting) {
      if (entry.target.innerText === 'Failed Submissions Over Time') setHasScrolledToFailedSubmissions(true);
      if (entry.target.innerText === 'Average Field Returns') setHasScrolledToAverageFieldReturns(true);
    }
  }), []);

  useEffect(() => {
    if (averageFieldReturnsChartRef.current) observer.observe(averageFieldReturnsChartRef.current);
  }, [averageFieldReturnsChartRef, observer]);

  useEffect(() => {
    if (failedSubmissionsChartRef.current) observer.observe(failedSubmissionsChartRef.current);
  }, [failedSubmissionsChartRef, observer]);

  useEffect(() => {
    if (hasScrolledToFailedSubmissions) mixpanel.track('Scrolled', { page: 'FormData', vis: 'Failed Submissions', ...orgDetailsForMixpanel(form?.organisation)});
  }, [hasScrolledToFailedSubmissions, mixpanel, form]);

  useEffect(() => {
    if (hasScrolledToAverageFieldReturns) mixpanel.track('Scrolled', { page: 'FormData', vis: 'Average Field Returns', ...orgDetailsForMixpanel(form?.organisation)});
  }, [hasScrolledToAverageFieldReturns, mixpanel, form]);

  // The first time a submit field is set (either set by the defualt or from the params) - load completion path and failed submissions charts
  useEffect(() => {
    if (!prevSubmitField && submitField?.identifier && formsGroupedByFormUuid) {
      loadCompletionPath();
      loadFailedSubmissions();
    }
  }, [prevSubmitField, submitField?.identifier, loadCompletionPath, loadFailedSubmissions, formsGroupedByFormUuid]);

  // When submit field is changed (in completion path or failed submissions vis) update the vis data
  useEffect(() => {
    if (prevSubmitField && submitField && (prevSubmitField !== submitField)) {
      setSubmitFieldChangeFromChart(false);
      loadCompletionPath();
      loadFailedSubmissions();
    }
  }, [prevSubmitField, submitField, loadCompletionPath, loadFailedSubmissions]);

  // When fields change (loadTrackedFields is called each time a new query is set), then fetch completion path chart
  useEffect(() => {
    if (prevTrackedFieldsLoading && trackedFields.length && submitFieldIdentifier &&
      submitField?.identifier && prevSubmitField?.identifier &&
      (submitField.identifier === prevSubmitField.identifier) && !completionPathData) {
      loadCompletionPath();
    }
  }, [trackedFields, prevTrackedFieldsLoading, loadCompletionPath, submitFieldIdentifier, submitField, prevSubmitField, completionPathData]);

  // When fields change (loadTrackedFields is called each time a new query is set), then fetch the failed submissions chart
  useEffect(() => {
    if (prevTrackedFieldsLoading && trackedFields.length && submitFieldIdentifier &&
      submitField?.identifier && prevSubmitField?.identifier &&
      (submitField.identifier === prevSubmitField.identifier) && !failedSubmissionsData) {
      loadFailedSubmissions();
    }
  }, [trackedFields, prevTrackedFieldsLoading, loadFailedSubmissions, submitFieldIdentifier, submitField, prevSubmitField, failedSubmissionsData]);

  // No fields, so reset the completion path and failed submissions charts
  useEffect(() => {
    if (prevTrackedFieldsLoading && !trackedFieldsLoading && !trackedFields.length) {
      setCompletionPathProgress(0);
      setCompletionPathLoading(false);
      setCompletionPathError('No data to display');
      setCompletionPathData(null);
      setFailedSubmissionsVisProgress(0);
      setFailedSubmissionsVisLoading(false);
      setFailedSubmissionsVisError('No data to display');
      setFailedSubmissionsVisData(null);
    }
  }, [prevTrackedFieldsLoading, trackedFieldsLoading, trackedFields]);

  // With new attribute keys, validate selected VSCA attribute key - setting the default if it's not available
  useEffect(() => {
    if ((prevAttributesKeysOptions && (prevAttributesKeysOptions !== attributesKeysOptions)) || (!attributesKeysLoading && attributesKeysLoadingError)) {
      if (attributesKeysOptions.length && !attributesKeyLookUp(attributesKeysOptions).includes(vscaSplitByAttrKey.value)) {
        setVscaSplitByAttrKey({label: 'deviceType', value: 'deviceType'});
      }

      setVscaSplitByAttrKeyValidated(true);
    }
  }, [attributesKeysOptions, prevAttributesKeysOptions, vscaSplitByAttrKey, vscaSplitByAttrKeyValidated, attributesKeysLoading, attributesKeysLoadingError]);

  // Once the selected VSCA attribute key is validated, load chart
  useEffect(() => {
    if (!prevVscaSplitByAttrKeyValidated && vscaSplitByAttrKeyValidated) loadVscaSplitByAttr();
  }, [vscaSplitByAttrKeyValidated, prevVscaSplitByAttrKeyValidated, loadVscaSplitByAttr]);

  // Update selected Form on browser back/forward
  useEffect(() => {
    if (prevForm?.uuid && form?.uuid && (prevForm.uuid !== form.uuid) && selectedForm?.uuid &&  (selectedForm.uuid !== form.uuid)) {
      setSelectedForm(form);
    }
  },[prevForm, form, selectedForm]);

  // Update selected time on browser back/forward
  useEffect(() => {
    if (prevTime?.start && prevTime?.end && time?.start && time?.end &&
      (prevTime.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') !== time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') ||
      (prevTime.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') !== time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'))) &&
      selectedTime && (selectedTime.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') !== time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') ||
      (selectedTime.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') !== time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]')))) {

      setSelectedTime(time);
    }
  },[prevTime, time, selectedTime]);

  // Update selected filters on browser back/forward
  useEffect(() => {
    if (prevFilters && filters && (prevFilters !== filters) && selectedFilters &&  (selectedFilters !== filters)) {
      setSelectedFilters(filters);
    }
  },[prevFilters, filters, selectedFilters]);

  // Update selected granularity on browser back/forward
  useEffect(() => {
    if (prevGranularity && granularity && (prevGranularity !== granularity) && (selectedGranularity !== granularity)) {
      setSelectedGranularity(granularity);
    }
  },[prevGranularity, granularity, selectedGranularity]);

  const fetchFilters = useCallback(async ({form, time}) => {
    try {
      setFiltersLoading(true);
      setFiltersLoadingError(false);
      const { data: { attributes } } = await api.get(`/data/sessions/attributes`, {
        params: {
          formUuid: form.uuid,
          time: {
            start: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
            end: time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          },
        },
      });
      setAvailableFilters(formatFiltersForSelectDropDown(attributes));
    } catch (e) {
      setFiltersLoadingError('Error when fetching attributes');
    } finally {
      setFiltersLoading(false);
    }
  },[]);

  // Initial load and first selections - fetch new attributes
  useEffect(() => {
    // First loaded page after login - so wait for default time to be set
    if ((!selectedForm?.uuid && !selectedTime?.start && prevForm?.uuid && form?.uuid && (prevForm.uuid === form.uuid)) && (!prevTime && time?.start)) fetchFilters({form, time});
    // Moved to page from another
    if ((!selectedForm?.uuid && !prevForm && form?.uuid) && (!selectedTime?.start && !prevTime && time?.start)) fetchFilters({form, time}); // TODO: bring this fetch up to context-level

    // Form first selected
    if (selectedForm?.uuid && (!prevSelectedForm || (prevSelectedForm.uuid !== selectedForm.uuid)) && !selectedTime) fetchFilters({form: selectedForm, time});

    // Time first selected
    if (!selectedForm && selectedTime?.start && selectedTime?.end && (!prevSelectedTime ||
     (prevSelectedTime.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') !== selectedTime.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') ||
      prevSelectedTime.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') !== selectedTime.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'))
    )) fetchFilters({form, time: selectedTime});
  }, [selectedForm, prevSelectedForm, selectedTime, prevSelectedTime, form, time, fetchFilters, prevForm, prevTime]);

  // When the selected form or time changes - fetch new attributes
  useEffect(() => {
    if (selectedForm?.uuid && selectedTime?.start && selectedTime?.end) fetchFilters({form: selectedForm, time: selectedTime});
  }, [selectedForm, selectedTime, fetchFilters]);

  // Remove completion value message
  useEffect(() => {
    if (completionValueSaved) setTimeout(() => {setCompletionValueSaved(null);}, 4000);
  }, [completionValueSaved]);


  const handleDateTimeRangeChange = (start, end) => {
    const [utcStart, utcEnd] = [start, end].map((moment) => moment.clone().utc());
    mixpanel.track('Selected Time', {
      page: 'FormData',
      start: utcStart.toISOString(),
      end: utcEnd.toISOString(),
      daysDiff: utcEnd.diff(utcStart, 'days'),
      daysFromNow: moment.utc().diff(utcStart, 'days'),
      ...orgDetailsForMixpanel(form?.organisation),
    });
    setSelectedTime({start, end});
    setCancelButtonEnabled(true);
    setApplyButtonEnabled(true);
  };

  const handleFormChange = ({value: uuid}) => {
    mixpanel.track('Selected Form', { page: 'FormData', ...orgDetailsForMixpanel(form?.organisation)});
    if (uuid !== selectedForm?.uuid) {
      setSelectedForm(formsGroupedByFormUuid[uuid]);
      setCancelButtonEnabled(true);
      setApplyButtonEnabled(true);
    }
  };

  const handleFiltersChange = useCallback((selectedFilters) => {
    const defaultSelected = selectedFilters?.filter(({key}) => defaultAttributes.includes(key));
    mixpanel.track('Selected Filters', { page: 'FormData', ...(defaultSelected?.length > 0) && {filters: defaultSelected.map(f => f.value)},
      ...orgDetailsForMixpanel(form?.organisation)});
    setSelectedFilters(selectedFilters || []);
    setCancelButtonEnabled(true);
    setApplyButtonEnabled(true);
  }, [mixpanel, form]);

  const handleGranularityChange = ({target: {value}}) => {
    mixpanel.track('Selected Granularity', { page: 'FormData', ...orgDetailsForMixpanel(form?.organisation)});
    setSelectedGranularity(value);
    setCancelButtonEnabled(true);
    setApplyButtonEnabled(true);
  };

  const handleVscaSplitMetricChange = (metric) => {
    mixpanel.track('Selected VSCA Split Metric', { page: 'FormData', ...orgDetailsForMixpanel(form?.organisation)});
    setVscaSplitByAttrMetric(metric);
  };

  const handleVscaSplitKeyChange = (key) => {
    mixpanel.track('Selected VSCA Split Attr Key', { page: 'FormData', ...orgDetailsForMixpanel(form?.organisation)});
    setVscaSplitByAttrKey(key);
  };

  const handleSelectSubmitField = (field) => {
    mixpanel.track('Selected Submit Field', { page: 'FormData', ...orgDetailsForMixpanel(form?.organisation)});
    setSubmitFieldChangeFromChart(true);
    history.push(compileQueryString({
      form,
      time,
      granularity,
      filters,
      submitFieldIdentifier: field.identifier,
    }));
    setQuery((prevQuery) => ({...prevQuery, submitFieldIdentifier: field.identifier}));
  };

  const cancelQuery = () => {
    setSelectedTime(time);
    setSelectedForm(form);
    setSelectedFilters(filters);
    setSelectedGranularity(granularity);
    setCancelButtonEnabled(false);
    setApplyButtonEnabled(false);
  };

  const handleApply = () => {
    history.push(compileQueryString({
      form: selectedForm || form,
      time: selectedTime || time,
      granularity: selectedGranularity || granularity,
      filters: selectedFilters || filters,
      submitFieldIdentifier: submitFieldIdentifierForQS(),
    }));
    setCancelButtonEnabled(false);
    setApplyButtonEnabled(false);
  };

  // VSCA Split By Attr Metric or Key has changed since validation, so reload the data and save on the session
  useEffect(() => {
    if ((prevVscaSplitByAttrKeyValidated === vscaSplitByAttrKeyValidated) &&
          prevVscaSplitByAttrMetric && prevVscaSplitByAttrKey && Object.keys(prevVscaSplitByAttrMetric).length && Object.keys(prevVscaSplitByAttrKey).length &&
          (prevVscaSplitByAttrMetric.value !== vscaSplitByAttrMetric.value || prevVscaSplitByAttrKey.value !== vscaSplitByAttrKey.value)) {
      loadVscaSplitByAttr();

      const session = getSession();
      session.vscaSplitByAttr = {
        metric: vscaSplitByAttrMetric,
        attrKey: vscaSplitByAttrKey,
      };
      updateSession(session);
    }
  }, [vscaSplitByAttrMetric, vscaSplitByAttrKey, prevVscaSplitByAttrMetric, prevVscaSplitByAttrKey, loadVscaSplitByAttr, prevVscaSplitByAttrKeyValidated, vscaSplitByAttrKeyValidated]);

  const formatGroupLabel = (group) => {
    const selectedInGroup = selectedFilters?.filter(attr => attr.key === group.label).length;

    return (<div style={{display: "flex", alignItems: "center", justifyContent: "space-between", textTransform: "none"}}>
      <span style={{ color: '#3F4047', fontSize: 14, fontWeight: 500 }}>{group.label}</span>
      <span style={{display: 'inline-flex',alignItems: 'center'}}>
        <span style={{fontSize: 10, textAlign: 'center', paddingRight: '3px'}}>
          {selectedInGroup ? `${selectedInGroup} selected` : null}
        </span>
        <span style={{
          backgroundColor: "#EBECF0", borderRadius: "2em", color: "#172B4D", display: "inline-block", fontSize: 14,
          fontWeight: "normal", lineHeight: "1", minWidth: 1, padding: "0.16666666666667em 0.5em", textAlign: "center"}}>
          {group.options.length}
        </span>
      </span>
    </div>);
  };

  const formFilterOption = ({ data: { orgLabel, orgUuid, label: formLabel, value: formUuid } }, currentSearchValue ) => (
    formLabel
      .toLocaleLowerCase()
      .includes(currentSearchValue.toLocaleLowerCase()) ||
    formUuid
      .toLocaleLowerCase()
      .includes(currentSearchValue.toLocaleLowerCase()) ||
    orgUuid
      .toLocaleLowerCase()
      .includes(currentSearchValue.toLocaleLowerCase()) ||
    orgLabel
      .toLocaleLowerCase()
      .includes(currentSearchValue.toLocaleLowerCase())
  );

  const formatVscaChartsData = (datasets) => {
    return datasets.map((dataset, i) => ({
      label: dataset.label,
      data: dataset.data,
      backgroundColor: vscaColours[dataset.label],
      borderColor: vscaColours[dataset.label],
      fill: false,
      borderWidth: 1,
      pointRadius: 1,
      pointHoverBorderWidth: 1,
    }));
  };

  const formatVscaSplitByAttrChartData = (datasets) => {
    const maxInitialDisplayLines = 10;
    const chartLineColours = [
      '#6A355C',
      '#FFAA4F',
      '#F45583',
      '#CC2C5C',
      '#EC521B',
      '#F9F871',
    ];
    const chartAreaColours = [
      'rgba(106,53,92, 0.3)',
      'rgba(255,170,79, 0.3)',
      'rgba(244,85,131, 0.3)',
      'rgba(204,44,92, 0.3)',
      'rgba(236,82,27, 0.3)',
      'rgba(249,248,113, 0.3)'
    ];

    return datasets.map((dataset, i) => {
      var colourCount = chartLineColours.length;
      var colourIndex = i < colourCount ? i : i % colourCount;
      var lineToHide = i > maxInitialDisplayLines - 1;

      return {
        label: dataset.label,
        data: dataset.data,
        backgroundColor: chartAreaColours[colourIndex],
        borderColor: chartLineColours[colourIndex],
        fill: i === 0 ? 'origin' : '-1',
        borderWidth: 2,
        pointRadius: 1,
        pointHoverBorderWidth: 1,
        pointBackgroundColor: chartLineColours[colourIndex],
        hidden: lineToHide,
      };
    });
  };

  const formatChartStats = (stats) => {
    for(const stat in stats) {
      stats[stat].current = stats[stat].current === null ? 'N/A' : stats[stat].current;
      stats[stat].icon = stats[stat].percentDiff > 0 ? 'up' : stats[stat].percentDiff < 0 ? 'down' : null;
      stats[stat].percentDiff = stats[stat].percentDiff !== null && (Math.round(stats[stat].percentDiff * 100) / 100).toLocaleString() + '%';
      stats[stat].title = stats[stat].current && stats[stat].previous && `${stats[stat].previous.toLocaleString()} vs ${stats[stat].current.toLocaleString()}`;
    }
    return stats;
  };

  const formatMedianDurationData = (data) => {
    return {
      labels: data.range,
      datasets: [
        {
          label: 'Abandons',
          data: data.abandoned,
          backgroundColor: '#f0764a',
          borderColor:'#f0764a',
          fill: false,
          borderWidth: 1,
          pointRadius: 1
        },
        {
          label: 'Completions',
          data: data.completed,
          backgroundColor: '#0267bf',
          borderColor:'#0267bf',
          fill: false,
          borderWidth: 1,
          pointRadius: 1
        }
      ],
    };
  };

  const formatAvgFieldReturnsData = (data) => {
    return {
      labels: data.range,
      datasets: [
        {
          label: 'Abandons',
          data: data.abandoned,
          backgroundColor: '#f0764a',
          borderColor:'#f0764a',
          fill: false,
          borderWidth: 1,
          pointRadius: 1
        },
        {
          label: 'Completions',
          data: data.completed,
          backgroundColor: '#0267bf',
          borderColor:'#0267bf',
          fill: false,
          borderWidth: 1,
          pointRadius: 1
        }
      ],
    };
  };

  const outcomeForStat = (stat) => {
    switch (stat) {
    case 'abandons':
      return [{value: 'abandoned'}];
    case 'completions':
      return [{value: 'completed'}];
    default:
      return [];
    }
  };

  const attributesKeyLookUp = (arr) => (arr.map(obj => obj.value));

  const formatCellToStr = (cell) => cell.value.toLocaleString();

  const formatCellToPercent = (cell) => (cell.value * 100).toLocaleString(undefined, {maximumFractionDigits: 2}) + '%';

  const horizontalBarChartMaxPdfData = (data) => {
    return {
      datasets: data.datasets.map(d => ({...d, data: d.data.slice(0, maxLabels)})),
      labels: data.labels.slice(0, maxLabels),
    };
  };
  const customEventsChartDataForPdf = customEventsTotalData && horizontalBarChartMaxPdfData(customEventsTotalData);

  const formUuid = form?.uuid;
  // TODO: get from org?
  const orgName = form?.organisation?.name;

  const ScrollTo = useCallback(() => {
    switch (hash) {
    case '#vsca':
      return <ScrollToElement elementRef={vscaRef} />;
    case '#vsc-rates':
      return <ScrollToElement elementRef={vscRatesRef} />;
    default:
      return <ScrollToTop />;
    }
  }, [hash]);

  useEffect(() => {
    if(!currentUser.accountManager) setReportVisible(!!form?.organisation?.reportAccessEnabled);
  }, [currentUser.accountManager, form?.organisation?.reportAccessEnabled]);

  const handleVSCAChartDownloadButton = useCallback(() => {
    mixpanel.track('Clicked VSCA download as PNG', { page: 'FormData', ...orgDetailsForMixpanel(form?.organisation) });
    const chartCanvas = document.getElementById('vsca-chart');
    if (chartCanvas) {
      const link = document.createElement('a');
      link.download = 'Zuko Views, Starters, Completions & Abandons.png';
      link.href = chartCanvas.toDataURL('image/png');
      link.click();
    }
  }, [mixpanel, form?.organisation]);

  return (
    <Container fluid className="form-data page">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
        <title>Form Data</title>
      </Helmet>
      <div className="nav-wrapper browser-only">
        <NavBar mixpanel={mixpanel}/>
        <Row className="g-0 nav-primary">
          <Col className="col-md-auto col-sm-12 pt-0 pe-md-1 mt-1 d-inline-flex" id="datepicker">
            <DatePicker
              startTime={(selectedTime || time)?.start}
              endTime={(selectedTime || time)?.end}
              onApply={handleDateTimeRangeChange}
              timeZone={form?.organisation?.timeZone}
              selectedFormTimeZone={selectedForm?.organisation.timeZone}
            />
          </Col>
          <Col md={3} className="pt-0 px-md-1 mt-1" id="form-select">
            <Select
              styles={{
                control: (styles, state) => ({...styles,
                  border: '1px solid #EBEDF2',
                }),
                option: (styles, state) => ({...styles,
                  color: '#3f4047',
                  backgroundColor: state.selectProps.value && (state.selectProps.value.uuid === state.value) ? "#E2E5Ec" : null,
                  '&:hover': {backgroundColor: state.isFocused ? '#F4F5F8' : null}
                }),
                menu: (styles, state) => ({...styles,
                  marginTop: '1px',
                  borderRadius: '4px',
                  border: '1px solid #EBEDF2',
                  boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
                }),
                dropdownIndicator: (styles, state) => ({...styles,
                  cursor: 'pointer',
                  transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
                  transition: 'transform .5s ease',
                }),
              }}
              options={formatFormSelectOptions({formsGroupedByOrg, currentForm: form, selectedForm, formsLoadingError, formsLoading})}
              formatGroupLabel={formatGroupLabel}
              onChange={handleFormChange}
              placeholder="Select a form..."
              value={selectedForm?.label ? selectedForm : form?.label ? form : null}
              filterOption={formsGroupedByOrg && Object.values(formsGroupedByOrg).length && formFilterOption}
              isOptionDisabled={option => option.hasOwnProperty('selectable') && !option.selectable}
            />
          </Col>
          <Col className="pt-0 ps-md-1 mt-1">
            <FiltersSelect
              form={form}
              filters={filters}
              selectedFilters={selectedFilters}
              filtersLoading={filtersLoading}
              availableFilters={availableFilters}
              filtersLoadingError={filtersLoadingError}
              handleFiltersChange={handleFiltersChange} />
          </Col>
        </Row>
        <Row className="g-0 nav-secondary justify-content-between">
          <Col className="pt-1 pb-2 col-auto d-flex align-items-center">
            <div className="granularity-buttons">
              <fieldset>
                <FormGroup className="form-group d-flex mb-0" controlId="granularity">
                  <Form.Label className="form-check-inline align-middle">Granularity: </Form.Label>
                  {acceptedGranularity.map((item) => (
                    <Form.Check inline type="radio" id={item} label={item} value={item} data-testid={`${item}-radio`} checked={item === (selectedGranularity || granularity)}
                      onChange={handleGranularityChange} key={item}/>
                  ))}
                </FormGroup>
              </fieldset>
            </div>
          </Col>
          <Col className="pt-1 pb-2 col-auto">
            <Button variant="outline-secondary" className="cancel me-1 ms-0" disabled={!cancelButtonEnabled}
              onClick={cancelQuery} data-testid="cancel-form-data-query">Cancel</Button>
            <Button className="load ms-1 me-0" disabled={!applyButtonEnabled} onClick={handleApply}>Apply</Button>
          </Col>
        </Row>
      </div>
      <ScrollTo />
      <div className="main-content">
        {(formsLoading || formsLoadingError || !formsGroupedByOrg || Forms.length > 0) ?
          <Col className="center-column justify-content-md-center">
            <div className="pb-1">
              <FeedbackRow
                classList={['allow-scroll-under-nav']}
                mixpanel={mixpanel}
                page={'FormData'}
                org={form?.organisation}
                messageContent={'Form Data'} />
            </div>
            <AppAlerts showOrgAlerts={true} />
            <Row className="title-row g-0 browser-only">
              {orgName && form.label && <>
                <Col className="p-0">
                  <h1 id="form-title" data-testid="page-title">
                    {`${orgName} | ${form.label} | `}
                    <a href={form.url} target="_blank" rel="noopener noreferrer">{form.url}</a>
                  </h1>
                </Col>
                <Col className="p-0 text-end my-auto ms-auto col-auto">
                  {exportError && <p className="mb-0 pe-2 error-text d-inline-block">Error exporting data.</p>}
                  {reportVisible && <span className="icon-overlay-container">
                    <GrDocumentPdf size="20px" title="Export to PDF" className={`pdf-export-icon ${pdfRequested ? 'generating' : 'data-loaded'}`}
                      onClick={() => {setPdfRequested(true); mixpanel.track('Clicked to export', { page: 'FormData', exportType: 'PDF', ...orgDetailsForMixpanel(form?.organisation) });}}/>
                    {pdfRequested && <FaSpinner size="18px" className="spinning-icon" title="Generating PDF..."/>}
                  </span>}
                  <CopyUrlIcon
                    queryString={compileQueryString({form, time, granularity, filters, submitFieldIdentifier: submitFieldIdentifierForQS()})}/>
                  <Link to={`/forms/${formUuid}/edit`}><FaCog size="20px" className="grey-icon" title="Form settings"/></Link>
                </Col> </>
              }
            </Row>
            <Col className={`chart-content p-0 ${reportVisible ? '' : 'blurred-report'}`}>
              <PrintPageHeader
                pageTitle={'Form Data'}
                orgName={orgName}
                formLabel={form?.label}
                formUrl={form?.url}
                startTime={time?.start}
                endTime={time?.end}
                timeZone={form?.organisation?.timeZone}
                formFilters={filters}
                searchParams={form?.uuid && time?.start && time?.end && granularity && compileQueryString({form, time, granularity, filters, submitFieldIdentifier: submitFieldIdentifierForQS()})}
              />
              <div className="flip-card" ref={vscaRef}>
                <Card id="vsca" className={`flip-card-inner ${(showVscaInfo === true) ? 'perform-flip' : ''}`}>
                  <Card.Body className="p-0 d-flex">
                    <div className={`flip-card-front d-flex flex-column flex-grow-1 ${showVscaInfo === null ? '' : showVscaInfo === true ? 'not-visble' : showVscaInfo === false ? 'visible' : ''}`}>
                      <Row className="g-0 card-title-row">
                        <Col className="p-0">
                          <Card.Title as="h3">Views, Starters, Completions & Abandons</Card.Title>
                        </Col>
                        <Col className="p-0 text-end card-tooltip">
                          <span className="icon-overlay-container">
                            <BsFiletypePng
                              size="20px"
                              onClick={handleVSCAChartDownloadButton}
                              title="Download chart as PNG"
                              className="png-download-icon data-loaded"
                            />
                          </span>

                          <FaInfoCircle id="first-info-icon" size="20px" className="info-circle-icon browser-only" onClick={() => {setShowVscaInfo(true); mixpanel.track('Clicked VSCA info', { page: 'FormData', ...orgDetailsForMixpanel(form?.organisation) });}} title="How to use"/>
                        </Col>
                      </Row>
                      {vscaData ?
                        <div className="card-vis">
                          <div data-testid="vsca-chart-wrapper">
                            <ReactChart
                              id="vsca-chart"
                              type="line"
                              data={vscaData.chart}
                              width={1216}
                              height={350}
                              options={{
                                maintainAspectRatio: false,
                                scales: {
                                  y: {
                                    title: {
                                      text: 'Count',
                                      display: true,
                                    },
                                    type: 'linear',
                                  },
                                  x: {
                                    type: 'timeseries',
                                    time: {
                                      unit: granularity,
                                      displayFormats: {
                                        hour: vscaData.chart.labels.length > 48 ? 'MMM DD' : 'HH[h]',
                                        day: 'MMM DD',
                                        week: 'MMM DD',
                                        month: 'MMM YYYY',
                                      },
                                      isoWeekday: true,
                                      tooltipFormat: granularity === 'hour' ? 'MMM D, HH:mm' : granularity === 'month' ? 'MMM YYYY' : 'MMM DD'
                                    },
                                  },
                                },
                                elements: {
                                  line: {
                                    tension: 0.05
                                  }
                                },
                                interaction: {
                                  intersect: false,
                                  mode: 'nearest',
                                },
                                plugins: {
                                  legend: {
                                    labels: {
                                      boxWidth: 20
                                    }
                                  },
                                  tooltip: {
                                    position: 'nearest',
                                  },
                                },
                              }}
                              plugins={[
                                {
                                  id: 'custom-canvas-background-colour',
                                  beforeDraw: (chart, args, options) => {
                                    const {ctx} = chart;
                                    ctx.save();
                                    ctx.globalCompositeOperation = 'destination-over';
                                    ctx.fillStyle = 'white';
                                    ctx.fillRect(0, 0, chart.width, chart.height);
                                    ctx.restore();
                                  },
                                },
                              ]}
                            />
                          </div>
                        </div>
                        : vscaError ?
                          <div className="d-flex justify-content-center flex-grow-1">
                            <p className="text-center my-auto" data-testid="vsca-chart-error">{vscaError}</p>
                          </div>
                          : vscaLoading &&
                          <div className="progress-area d-flex flex-grow-1">
                            <ProgressBar className="my-auto" animated now={vscaProgress}/>
                          </div>
                      }

                      <Row className="g-0 card-stats">
                        {vscaData && vscaData.stats && ['views', 'starters', 'completions', 'abandons'].map((stat) => (
                          <Col key={stat} className="d-flex flex-column justify-content-center">
                            <p className="mb-0 stat-label text-center">{stat}</p>
                            <div className="d-grid replay-link-grid">
                              <div></div>
                              <span className="stats-current text-center">{vscaData.stats[stat].current.toLocaleString()}</span>
                              <div className="ps-3">
                                {(stat !== 'views') && vscaData.stats[stat].current && vscaData.stats[stat].current !== 0 &&
                                  <ReplayLinkGridItem
                                    loading={vscaLoading}
                                    tooltipText={<>Open <i>Session Replay</i> for {stat}</>}
                                    setShowSessionReplayCTAModal={setShowSessionReplayCTAModal}
                                    replayEnabled={form?.organisation?.replayEnabled}
                                    queryStringObj={(query?.form && query?.time) ? {form: query.form, time: query.time, filters: query.filters,
                                      sessionOutcomes: outcomeForStat(stat),
                                      sessionFilters: {},
                                    } : null}
                                  />}
                              </div>
                            </div>
                            <div className="d-flex justify-content-center stats-previous">
                              <OverlayTrigger placement="right" trigger={['click', 'hover']}
                                overlay={
                                  <Popover>
                                    <Popover.Body className="fs-6">
                                      <p className="mb-0">Total {stat} in the previous time period: {vscaData.stats[stat].previous.toLocaleString()}</p>
                                    </Popover.Body>
                                  </Popover>}>
                                <p className="mb-0 text-center">
                                  {vscaData.stats[stat].icon === 'down' ? <FaCaretDown size="18px" /> : vscaData.stats[stat].icon === 'up' ? <FaCaretUp size="18px" /> : null}
                                  <u className="percent-diff text-more-info">{vscaData.stats[stat].percentDiff}</u>
                                </p>
                              </OverlayTrigger>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </div>
                    <div className={`flip-card-back ${showVscaInfo === true ? 'visible' : showVscaInfo === false ? 'not-visble' : ''}`}>
                      <div className="card-contents">
                        <Row className="g-0 card-title-row">
                          <Col className="p-0">
                            <Card.Title as="h3">Views, Starters, Completions & Abandons</Card.Title>
                          </Col>
                          <Col className="p-0 text-end card-tooltip">
                            <VscChromeClose size="20px" className="grey-icon" onClick={() => setShowVscaInfo(false)} title="Return to graph"/>
                          </Col>
                        </Row>
                        <Row className="g-0 text-content">
                          <Col lg={7} className="ps-0">
                            <Card.Text className="mb-3 subtitle">This graph presents a breakdown of how many visitors <i>View</i>, <i>Start</i> to interact with, and <i>Complete</i> your form and how this changes over your selected time frame.
                            It also shows the number of people that <i>Abandon</i> your form after starting it.</Card.Text>
                            <Card.Text>See the numbers in detail by hovering over the points on the graph. You can isolate metrics by clicking on the legend to exclude individual metrics from the graph.</Card.Text>
                            <figure className="text-center img-wrapper">
                              <img id="VSCA-chart-points" src={VSCAPointsImg} alt="VSCA-chart-points" className="card-info-img"></img>
                            </figure>
                            <Card.Text>Underneath the graph you can see totals for each metric, along with movement compared with the previous time period. If you are looking at this month’s data,
                            this will show you whether these numbers have risen or fallen when compared to last month. You can select the granularity in the navigation bar to group data by <i>Hour</i>, <i>Day</i> (default), <i>Week</i> or <i>Month</i>.</Card.Text>
                            <figure className="text-center img-wrapper">
                              <img id="VSCA-stats" src={VSCAStatsImg} alt="VSCA-stats" className="card-info-img"></img>
                            </figure>
                          </Col>
                          <Col lg={5} className="py-0">
                            <div className="card-tip-box">
                              <h4 className="pb-3">How to use this and what to look for</h4>
                              <Card.Text>This graph is mainly used to track trends over time. See how any changes in marketing campaigns or the form itself affect the numbers in each category.</Card.Text>
                              <Card.Text>Use the custom attribute filter to isolate whether certain audience segments are influencing this. For example, select one of the default filters <code>deviceType</code> or <code>browserType</code>, or if you have set up
                                  ‘traffic source’ as a custom attribute you can see whether a growth in form views has been driven by a particular marketing campaign.</Card.Text>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <div className="flip-card">
                <Card id="completion-path" className={`flip-card-inner ${(showCompletionPathInfo === true) ? 'perform-flip' : ''}`}>
                  <Card.Body className="p-0 d-flex">
                    <div className={`flip-card-front d-flex flex-column flex-grow-1 ${showCompletionPathInfo === null ? '' : showCompletionPathInfo === true ? 'not-visble' : showCompletionPathInfo === false ? 'visible' : ''}`}>
                      <Row className="g-0 card-title-row">
                        <Col className="p-0">
                          <Card.Title as="h3">Completion Path</Card.Title>
                        </Col>
                        <Col className="p-0 text-end card-tooltip">
                          <FaInfoCircle size="20px" className="info-circle-icon browser-only" onClick={() => {setShowCompletionPathInfo(true); mixpanel.track('Clicked Completion Path info', { page: 'FormData', ...orgDetailsForMixpanel(form?.organisation) });}} title="How to use"/>
                        </Col>
                      </Row>
                      <Row className="g-0">
                        <Col className="p-0 d-flex">
                          <h4 className="card-tagline my-auto">View the steps towards completion that visitors take after clicking your <i>Submit</i> button.</h4>
                        </Col>
                      </Row>
                      <Row className="g-0 field-select justify-content-end">
                        <Col className="p-0 col-auto d-flex align-items-center">
                          <div className="me-3">Select the field which is your 'Submit' button</div>
                          <div className="submit-field-select">
                            <Select
                              id="completion-path-field-select"
                              styles={{
                                option: (styles, state) => ({...styles,
                                  color: '#3f4047',
                                  backgroundColor: state.selectProps.value && (state.selectProps.value.identifier === state.value) ? "#E2E5EC" : null,
                                  '&:hover': {backgroundColor: state.isFocused ? '#F4F5F8' : null}
                                }),
                                menu: (styles, state) => ({...styles,
                                  marginTop: '1px',
                                  borderRadius: '4px',
                                  boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
                                }),
                                dropdownIndicator: (styles, state) => ({...styles,
                                  cursor: 'pointer',
                                  transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
                                  transition: 'transform .5s ease',
                                }),
                              }}
                              options={trackedFields}
                              onChange={handleSelectSubmitField}
                              placeholder="Select the submit button..."
                              value={submitField ? {...submitField, value: submitField.identifier} : null}
                            />
                          </div>
                        </Col>
                      </Row>
                      {completionPathData ?
                        <div className="card-vis">
                          <CompletionPathChart
                            completionPathData={completionPathData}
                            lostOpportunityValues={form?.hasOwnProperty('completionValue') && form.completionValue !== null &&
                             lostOpportunitySessions?.map(s => s * form.completionValue)}
                            currencyCode={form?.completionValueCurrencyCode}
                            setShowSessionReplayCTAModal={setShowSessionReplayCTAModal}
                            submitField={submitField}
                          />
                        </div> :
                        completionPathError ?
                          <div className="d-flex justify-content-center flex-grow-1">
                            <p className="text-center my-auto" data-testid="completion-path-chart-error">{completionPathError}</p>
                          </div> :
                          completionPathLoading &&
                      <div className="progress-area d-flex flex-grow-1">
                        <ProgressBar className="my-auto" animated now={completionPathProgress}/>
                      </div>}
                      <Row className="g-0 justify-content-center">
                        <div className="completion-value-container text-center">
                          <Col className="pt-0 px-0 pb-2 d-flex col-auto justify-content-center">
                            <h5 className="my-auto">Understand the lost opportunity cost of those visitors who did not complete by entering the average value of each completion on this form.</h5>
                          </Col>
                          {completionValueError &&
                            <Alert dismissible variant="danger" closeVariant="white" onClose={() => setCompletionValueError(false)}>
                              <div className="alert-svg-icon my-auto"><VscWarning size="20px"/></div>
                              <p className="alert-text m-0">{completionValueError}</p>
                            </Alert>}
                          <Col className="p-0 w-100">
                            <Form className="d-flex justify-content-between" onSubmit={handleSaveCompletionValue}>
                              <Col className="p-0 d-flex justify-content-end align-items-center">
                              </Col>
                              <Col className="p-0 col-auto d-flex justify-content-center align-items-center">
                                <Form.Label className="mb-0 text-nowrap">Completion Value</Form.Label>
                                <OverlayTrigger placement="left"
                                  overlay={
                                    <Popover id="hide-field-popover">
                                      <Popover.Body>
                                      This should be the lifetime value to your organisation of a successful form completion
                                        <br></br><br></br>
                                      It can be based on metrics such as average income, incremental profit or similar.
                                      </Popover.Body>
                                    </Popover>}>
                                  <div className="d-flex me-2 mb-3"><FaInfoCircle size="12px" className="label-info-icon info"/></div>
                                </OverlayTrigger>
                                <InputGroup>
                                  <Form.Select value={completionValueCurrencyCode || ''} id="completion-currency" className="ms-0 currency-select" aria-label="completion value currency code"
                                    onChange={({target: {value}}) => {
                                      setCompletionValueCurrencyCode(value);
                                      setCompletionValueSaved(false);
                                      setCompletionValueError(null);
                                      setSaveCompletionValueEnabled(true);
                                    }}>
                                    {[{label: '£', value: 'gbp'}, {label: '$', value: 'usd'}, {label: '€', value: 'eur'}].map((opt) => (<option key={opt.value} value={opt.value}>{opt.label}</option>))}
                                  </Form.Select>
                                  <Form.Control type="number" min={0} step="1" value={completionValue ?? ''} id="completion-value" aria-label="completion value"
                                    onChange={({target: {value}}) => {
                                      setCompletionValue(value);
                                      setCompletionValueSaved(false);
                                      setCompletionValueError(null);
                                      setSaveCompletionValueEnabled(true);
                                    }}/>
                                </InputGroup>
                              </Col>
                              <Col className="ps-2 pe-0 py-0 d-flex justify-content-start">
                                {saveCompletionValueEnabled &&
                                <Button variant="outline-primary" type="submit" className="ms-2 btn-less-padding text-nowrap">Save</Button>}
                                {completionValueSaved &&
                                <Alert variant="outline-success" className="mb-0">
                                  <div className="alert-svg-icon my-auto"><VscCheck size="18px"/></div>
                                  <p className="alert-text m-0 ps-3 pe-0 py-0">Value saved</p>
                                </Alert>}
                              </Col>
                            </Form>
                          </Col>
                        </div>
                      </Row>
                      <Row className="p-0 my-2 card-stats">
                        <Col className="p-2 d-flex flex-column align-items-center justify-content-center text-center">
                          <p className="mb-0 stat-label text-center">Total Lost Opportunity</p>
                          <span className="stat-number text-center">
                            {completionPathLoading ? <FaSpinner size="12px" className="spinning-icon"/> :
                              (lostOpportunitySessions?.length > 0 && form.hasOwnProperty('completionValue') && form.completionValue !== null ?
                                formatToCurrencyWithDecimals({
                                  currencyCode: form.completionValueCurrencyCode,
                                  decimalPlaces: 0,
                                  value: lostOpportunitySessions?.reduce((acc, val) => acc + val, 0) * form.completionValue,
                                }) : ' -')}</span>
                        </Col>
                        <Col className="p-2 d-flex flex-column align-items-center justify-content-center text-center">
                          <p className="mb-0 stat-label text-center">Value of 5% Increase in Completions</p>
                          <span className="stat-number text-center">
                            {completionPathLoading ? <FaSpinner size="12px" className="spinning-icon"/> :
                              (totalCompletions && form.hasOwnProperty('completionValue') && form.completionValue !== null ?
                                formatToCurrencyWithDecimals({
                                  currencyCode: form.completionValueCurrencyCode,
                                  decimalPlaces: 0,
                                  value: (totalCompletions * 0.05) * form.completionValue,
                                }) : ' -')}</span>
                        </Col>
                      </Row>
                    </div>
                    <div className={`flip-card-back ${showCompletionPathInfo === true ? 'visible' : showCompletionPathInfo === false ? 'not-visble' : ''}`}>
                      <div className="card-contents">
                        <Row className="g-0 card-title-row">
                          <Col className="p-0">
                            <Card.Title as="h3">Completion Path</Card.Title>
                          </Col>
                          <Col className="p-0 text-end card-tooltip">
                            <VscChromeClose size="20px" className="grey-icon" onClick={() => setShowCompletionPathInfo(false)} title="Return to path chart"/>
                          </Col>
                        </Row>
                        <Row className="g-0 text-content">
                          <Col lg={7} className="ps-0">
                            <Card.Text className="mb-3 subtitle">The completion path chart shows you how users move through your form and whether they interact with your submit button before abandoning or completing.</Card.Text>
                            <Card.Text>Set your 'Submit' button field using the dropdown and the chart will show you the user flow and bucket them into different categories. The different data categories are:</Card.Text>
                            <dl>
                              <dt>Views</dt>
                              <dd>How many visitors reached the form.</dd>
                              <dt>Starters</dt>
                              <dd>How many interacted with the form in some way.</dd>
                              <dt>Non-starters</dt>
                              <dd>Visitors who viewed the form but did not interact with it.</dd>
                              <dt>Submit attempted</dt>
                              <dd>The volume of people who attempted to submit the form at least once.</dd>
                              <dt>Abandoned without submitting</dt>
                              <dd>The number of visitors who started the form but did not make it to the submit button.</dd>
                              <dt>Completed on first submit</dt>
                              <dd>Visitors who successfully completed the form on their first submission attempt.</dd>
                              <dt>First submit failed</dt>
                              <dd>Visitors who tried to submit but whose submission failed (usually because of errors).</dd>
                              <dt>Completed on a further submit</dt>
                              <dd>The number of visitors who successfully completed the form after their first submission failed.</dd>
                              <dt>Abandoned after submit attempt</dt>
                              <dd>Visitors who abandoned the form after at least one failed submission attempt.</dd>
                            </dl>
                            <h4 className="mt-2">Lost opportunity</h4>
                            <Card.Text>If you add the value of a form completion into the relevant box the chart will calculate the lost opportunity of each of the
                              groups that abandoned (showing you the potential gain if you can improve the form's conversion). It also shows you how much value you
                              could secure if you use Zuko insights to improve your conversion volumes by just 5%.</Card.Text>
                            <figure className="text-center img-wrapper">
                              <img id="Completion-Path-Img" src={CompletionPathImg} alt="Completion-Path-chart" className="card-info-img"></img>
                            </figure>
                            <h4>What is completion value?</h4>
                            <Card.Text>This should be the lifetime value to your organisation of a successful form completion. It is generally an average based on
                              historic data and can be based on metrics such as average income, incremental profit or similar.</Card.Text>
                          </Col>
                          <Col lg={5} className="py-0">
                            <div className="card-tip-box">
                              <h4 className="pb-3">How to use this and what to look for</h4>
                              <Card.Text>You can use this graph to quickly identify the biggest stumbling blocks for your form visitors. Are they more
                                likely to (a) Not interact with the form at all, (b) Interact but drop off before the end or (c) Make it to the end of your
                                form but not be able to successfully submit it so abandon in frustration. This helps guide your focus on which areas of the form
                                experience you need to prioritise.</Card.Text>
                              <Card.Text>The lost opportunity figures also help you quantify the benefits from running a form optimisation project, helping you estimate the
                                potential return on any resources used to improve the form user experience.</Card.Text>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <Row className="g-0 first-grid-row">
                <Col lg={6} md={12} className="ps-0 py-0 pe-lg-3 pe-md-0 print-as-grid">
                  <div className="flip-card h-100">
                    <Card id="vsca-split-by-attr" className={`flip-card-inner ${(showVscaSplitByInfo === true) ? 'perform-flip' : ''}`}>
                      <Card.Body className="p-0 d-flex">
                        <div className={`flip-card-front d-flex flex-column flex-grow-1 ${showVscaSplitByInfo === null ? '' : showVscaSplitByInfo === true ? 'not-visible' : showVscaSplitByInfo === false ? 'visible' : ''}`}>
                          <Row className="g-0 card-title-row">
                            <Col className="p-0 justify-content-center text-center">
                              <Card.Title as="h3">Views, Starters, Completions & Abandons, Split By Attribute (Stacked)</Card.Title>
                            </Col>
                            <Col className="p-0 text-end card-tooltip">
                              <FaInfoCircle size="20px" className="info-circle-icon browser-only" onClick={() => {setShowVscaSplitByInfo(true); mixpanel.track('Clicked VSCA Split By info', { page: 'FormData', ...orgDetailsForMixpanel(form?.organisation) });}} title="How to use"/>
                            </Col>
                          </Row>
                          <Row className="g-0 justify-content-md-center vis-select-area">
                            <Col id="vsca-split-metric-select" className="py-2 px-1">
                              <Select
                                styles={{
                                  control: styles => ({...styles,
                                    minHeight: 'fit-content',
                                  }),
                                  valueContainer: styles => ({...styles,
                                    maxHeight: '25px',
                                  }),
                                  input: styles => ({...styles,
                                    maxHeight: '25px',
                                    margin: 0,
                                  }),
                                  dropdownIndicator: (styles, state) => ({...styles,
                                    padding: '2px 4px 2px 4px',
                                    cursor: 'pointer',
                                    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
                                    transition: 'transform .5s ease',
                                  }),
                                  menuList: styles => ({...styles,
                                    minHeight: 'fit-content',
                                  }),
                                  option: (styles, state) => ({...styles,
                                    color: '#3f4047',
                                    backgroundColor: state.selectProps.value && (state.selectProps.value.label === state.label) ? "#E2E5EC" : null,
                                    '&:hover': {backgroundColor: state.isFocused ? '#F4F5F8' : null},
                                    maxHeight: '25px',
                                    paddingTop: '4px',
                                    paddingBottom: '4px',
                                  }),
                                  menu: (styles, state) => ({...styles,
                                    marginTop: '1px',
                                    borderRadius: '4px',
                                    boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
                                  }),
                                }}
                                options={[{label: 'Abandons', value: 'abandons'}, {label: 'Views', value: 'views'}, {label: 'Starters', value: 'starters'}, {label: 'Completions', value: 'completions'}]}
                                onChange={handleVscaSplitMetricChange}
                                placeholder="Select a metric..."
                                value={Object.keys(vscaSplitByAttrMetric).length ? vscaSplitByAttrMetric : null}
                              />
                            </Col>
                            <Col id="vsca-split-attr-key-select" className="py-2 px-1 position-relative">
                              <Select
                                styles={{
                                  control: styles => ({...styles,
                                    minHeight: 'fit-content',
                                  }),
                                  valueContainer: styles => ({...styles,
                                    maxHeight: '25px',
                                  }),
                                  input: styles => ({...styles,
                                    maxHeight: '25px',
                                    margin: 0,
                                  }),
                                  dropdownIndicator: (styles, state) => ({...styles,
                                    padding: '2px 4px 2px 4px',
                                    cursor: 'pointer',
                                    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
                                    transition: 'transform .5s ease',
                                  }),
                                  menuList: styles => ({...styles,
                                    minHeight: 'fit-content',
                                  }),
                                  option: (styles, state) => ({...styles,
                                    color: '#3f4047',
                                    backgroundColor: state.selectProps.value && (state.selectProps.value.label === state.label) ? "#E2E5EC" : null,
                                    '&:hover': {backgroundColor: state.isFocused ? '#F4F5F8' : null},
                                    maxHeight: '25px',
                                    paddingTop: '4px',
                                    paddingBottom: '4px',
                                    whiteSpace: 'nowrap',
                                  }),
                                  menu: (styles, state) => ({...styles,
                                    marginTop: '1px',
                                    borderRadius: '4px',
                                    boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
                                  }),
                                }}
                                options={attributesKeysLoading ? [{ selectable: false, value: null, label: <><i className="fa fa-circle-o-notch fa-spin fa-fw" /> Loading...</>}] :
                                  attributesKeysLoadingError ? [] : attributesKeysOptions}
                                onChange={handleVscaSplitKeyChange}
                                placeholder="Select attribute..."
                                value={(attributesKeysOptions.length > 0 && attributesKeyLookUp(attributesKeysOptions).includes(vscaSplitByAttrKey.value)) ? vscaSplitByAttrKey : null}
                              />
                              <div className="position-absolute quick-tip-position">
                                <ChartTip
                                  dataLoading={vscaSplitByAttrLoading}
                                  isData={!!vscaSplitByAttrData}
                                  mixpanel={mixpanel}
                                  organisation={form?.organisation}
                                  buttonText={''}
                                  page={'FormData'}
                                  vis={'Split By'}>
                                  {(vscaSplitByAttrData?.chart?.datasets?.length > 0 && attributesKeysOptions?.length > 0 && vscaSplitByAttrKey) ? <>
                                    <p><Link
                                      to={{
                                        pathname: `/form-segment-comparison`,
                                        search: '?select_from_state=true',
                                        state: {
                                          form: {uuid: form.uuid},
                                          organisation: {uuid: form.organisation.uuid},
                                          comparisonName: `${vscaSplitByAttrKey?.label} for ${form.label}`.substring(0, 50), // 50 char limit on the name,
                                          segments: vscaSplitByAttrData?.chart?.datasets.map(({label}) => ({key: vscaSplitByAttrKey.value, value: label})),
                                        },
                                      }} onClick={() => {mixpanel.track('Clicked Quick Tip Link', { page: 'FormData', vis: 'Split By', ...orgDetailsForMixpanel(form?.organisation) });}}>
                                        Open the <i>Form Segment Comparison</i> report</Link> to compare key performance metrics between different audience segments for:</p>
                                    <p className="tip-field">{vscaSplitByAttrKey?.label}</p>
                                    <p className="mb-0">For example, you can see whether visitors with a different <i>{vscaSplitByAttrKey?.label}</i> convert at different rates.</p>
                                  </> : null}
                                  {!(vscaSplitByAttrData?.chart?.datasets?.length > 0 && attributesKeysOptions?.length > 0 && vscaSplitByAttrKey) &&
                                    <p className="mb-0">This tip opens a segment comparison for the selected attribute, but there isn't one in your current timeframe.</p>}
                                </ChartTip>
                              </div>
                            </Col>
                          </Row>
                          {vscaSplitByAttrData && vscaSplitByAttrData.chart ?
                            <div className="card-vis">
                              <div className="scrollable-legend">
                                <div id="legend-container"></div>
                              </div>
                              <div data-testid="vsca-split-by-attr-chart-wrapper">
                                <ReactChart
                                  id="vsca-split-by-attributes-chart"
                                  type="line"
                                  data={vscaSplitByAttrData.chart}
                                  height={285}
                                  plugins={[htmlLegendPlugin]}
                                  options={{
                                    maintainAspectRatio: false,
                                    scales: {
                                      y: {
                                        title: {
                                          text: `${vscaSplitByAttrMetric.label} Count`,
                                          display: true,
                                        },
                                        type: 'linear',
                                        stacked: true,
                                      },
                                      x: {
                                        type: 'timeseries',
                                        time: {
                                          unit: granularity,
                                          displayFormats: {
                                            hour: vscaSplitByAttrData.chart.labels.length > 48 ? 'MMM DD' : 'HH[h]',
                                            day: 'MMM DD',
                                            week: 'MMM DD',
                                            month: 'MMM YYYY',
                                          },
                                          isoWeekday: true,
                                          tooltipFormat: granularity === 'hour' ? 'MMM D, HH:mm' : granularity === 'month' ? 'MMM YYYY' : 'MMM DD'
                                        },
                                      },
                                    },
                                    elements: {
                                      line: {
                                        tension: 0.05
                                      }
                                    },
                                    interaction: {
                                      intersect: false,
                                      mode: 'index',
                                    },
                                    plugins: {
                                      htmlLegend: {
                                        containerID: 'legend-container',
                                      },
                                      legend: {
                                        display: false,
                                        maxHeight: 500,
                                        labels: {
                                          boxWidth: 20
                                        }
                                      },
                                      tooltip: {
                                        position: 'nearest',
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                            : vscaSplitByAttrError ?
                              <div className="d-flex justify-content-center flex-grow-1">
                                <p className="text-center my-auto" data-testid="vsca-split-by-attr-chart-error">{vscaSplitByAttrError}</p>
                              </div>
                              : vscaSplitByAttrLoading &&
                                <div className="progress-area d-flex flex-grow-1">
                                  <ProgressBar className="my-auto" animated now={vscaSplitByAttrProgress}/>
                                </div>
                          }
                          <Row className="g-0 card-stats">
                            {vscaSplitByAttrData && vscaSplitByAttrData.stats && vscaSplitByAttrData.stats.total &&
                          <Col className="d-flex flex-column justify-content-center">
                            <p className="mb-0 stat-label text-center">{vscaSplitByAttrData.stats.total.label}</p>
                            <span className="stats-current text-center">{vscaSplitByAttrData.stats.total.current.toLocaleString()}</span>
                            <div className="d-flex justify-content-center stats-previous" title={vscaSplitByAttrData.stats.total.title}>
                              <i className={vscaSplitByAttrData.stats.total.icon}></i>
                              <span className="percent-diff">{vscaSplitByAttrData.stats.total.percentDiff}</span>
                            </div>
                          </Col>}
                          </Row>
                        </div>
                        <div className={`flip-card-back ${showVscaSplitByInfo === true ? 'visible' : showVscaSplitByInfo === false ? 'not-visble' : ''}`}>
                          <div className="card-contents">
                            <Row className="g-0 card-title-row">
                              <Col className="p-0 justify-content-center text-center">
                                <Card.Title as="h3">Views, Starters, Completions & Abandons, Split By Attribute (Stacked)</Card.Title>
                              </Col>
                              <Col className="p-0 text-end card-tooltip">
                                <VscChromeClose size="20px" className="grey-icon" onClick={() => setShowVscaSplitByInfo(false)} title="Return to graph"/>
                              </Col>
                            </Row>
                            <Row className="g-0 text-content pt-2">
                              <Col xs={12} lg={6} className="ps-0">
                                <Card.Text className="mb-3 subtitle">This graph helps you break out individual audience attributes and plot them against the topline form metrics.</Card.Text>
                                <Card.Text>For example, you can view <i>Abandons</i> plotted over time, but also see how much each device type contributed to that total by selecting Device Type from the second drop down menu.</Card.Text>
                                <Card.Text>You can analyse <i>Views</i>, <i>Starters</i>, <i>Completions</i> and <i>Abandons</i>, split out by any custom attributes you have set up. Or by the default filters <code>deviceType</code> or <code>broswerType</code>.</Card.Text>
                              </Col>
                              <Col xs={12} lg={6}>
                                <div className="card-tip-box">
                                  <h4 className="pb-3">How to use this and what to look for</h4>
                                  <Card.Text>This graph is mainly used to break down the data to see what the trend is with a particular attribute.</Card.Text>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
                <Col lg={6} md={12} className="ps-md-0 pe-0 py-0 ps-lg-3 print-as-grid">
                  <div className="flip-card h-100 vsc-rates-flip-card" ref={vscRatesRef}>
                    <Card id="vsc-rates" className={`flip-card-inner ${showVscRatesInfo === true ? 'perform-flip' : ''}`}>
                      <Card.Body className="p-0 d-flex">
                        <div className={`flip-card-front d-flex flex-column flex-grow-1 ${showVscRatesInfo === null ? '' : showVscRatesInfo === true ? 'not-visible' : showVscRatesInfo === false ? 'visible' : ''}`}>
                          <Row className="g-0 card-title-row">
                            <Col className="p-0 justify-content-center text-center">
                              <Card.Title as="h3">View, Starter & Completion Rates</Card.Title>
                            </Col>
                            <Col className="p-0 text-end card-tooltip">
                              <FaInfoCircle size="20px" className="info-circle-icon browser-only" onClick={() => {setShowVscRatesInfo(true); mixpanel.track('Clicked VSC Rates info', { page: 'FormData', ...orgDetailsForMixpanel(form?.organisation) });}} title="How to use"/>
                            </Col>
                          </Row>
                          {vscRatesData ?
                            <div className="card-vis">
                              <div data-testid="vsc-rates-chart-wrapper">
                                <ReactChart
                                  id="vsc-rates-chart"
                                  type="line"
                                  data={vscRatesData.chart}
                                  height={350}
                                  options={{
                                    maintainAspectRatio: false,
                                    scales: {
                                      y: {
                                        title: {
                                          text: 'Rate',
                                          display: true,
                                        },
                                        type: 'linear',
                                        beginAtZero: true,
                                        ticks: {
                                          callback: (value) => value.toLocaleString(undefined, {maximumFractionDigits: 2}) + '%',
                                        },
                                      },
                                      x: {
                                        type: 'timeseries',
                                        time: {
                                          unit: granularity,
                                          displayFormats: {
                                            hour: vscRatesData.chart.labels.length > 48 ? 'MMM DD' : 'HH[h]',
                                            day: 'MMM DD',
                                            week: 'MMM DD',
                                            month: 'MMM YYYY',
                                          },
                                          isoWeekday: true,
                                          tooltipFormat: granularity === 'hour' ? 'MMM D, HH:mm' : granularity === 'month' ? 'MMM YYYY' : 'MMM DD'
                                        },
                                      },
                                    },
                                    elements: {
                                      line: {
                                        tension: 0.05
                                      }
                                    },
                                    interaction: {
                                      intersect: false,
                                      mode: 'nearest',
                                    },
                                    plugins: {
                                      legend: {
                                        labels: {
                                          boxWidth: 20
                                        }
                                      },
                                      tooltip: {
                                        position: 'nearest',
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                            : vscRatesError ?
                              <div className="d-flex justify-content-center flex-grow-1">
                                <p className="text-center my-auto" data-testid="vsc-rates-chart-error">{vscRatesError}</p>
                              </div>
                              : vscRatesLoading &&
                                <div className="progress-area d-flex flex-grow-1">
                                  <ProgressBar className="my-auto" animated now={vscRatesProgress}/>
                                </div>
                          }
                          <Row className="g-0 card-stats">
                            {vscRatesData && vscRatesData.stats &&
                          [['View to Starter Rate', 'viewsStarters'], ['View to Completion Rate', 'viewsCompletions'], ['Starter to Completion Rate', 'startersCompletions']].map(([label, stat]) => {
                            return (
                              <Col className="wide-stat d-flex flex-column justify-content-center" key={label}>
                                <p className="mb-0 mx-auto stat-label text-center">{label}</p>
                                <span className="stats-current text-center">{vscRatesData.stats[stat].current.toLocaleString()}{vscRatesData.stats[stat].current !== 'N/A' && '%'}</span>
                                <div className="d-flex justify-content-center stats-previous" title={vscRatesData.stats[stat].title}>
                                  <i className={vscRatesData.stats[stat].icon}></i>
                                  <span className="percent-diff">{vscRatesData.stats[stat].percentDiff}</span>
                                </div>
                              </Col>);
                          })}
                          </Row>
                        </div>
                        <div className={`flip-card-back ${showVscRatesInfo === true ? 'visible' : showVscRatesInfo === false ? 'not-visble' : ''}`}>
                          <div className="card-contents">
                            <Row className="g-0 card-title-row">
                              <Col className="p-0 justify-content-center text-center">
                                <Card.Title as="h3">View, Starter & Completion Rates</Card.Title>
                              </Col>
                              <Col className="p-0 text-end card-tooltip">
                                <VscChromeClose size="20px" className="grey-icon" onClick={() => setShowVscRatesInfo(false)} title="Return to graph"/>
                              </Col>
                            </Row>
                            <Row className="g-0 text-content">
                              <Col xs={12} lg={6}  className="ps-0">
                                <Card.Text className="mb-3 subtitle">This graph plots the Views to Starters %, Starters to Completions % and Views to Completions %, and shows how these
                                rates change over your selected time frame.</Card.Text>
                                <dl>
                                  <dt>View to Starter Rate</dt>
                                  <dd>What % of visitors who visited the form page started to interact with it?</dd>
                                  <dt>View to Completion Rate</dt>
                                  <dd>What % of visitors who visited the form page successfully submitted it?</dd>
                                  <dt>Starter to Completion Rate</dt>
                                  <dd>What % of visitors who started to interact with the form successfully submitted it?</dd>
                                </dl>
                                <Card.Text>You can also isolate individual metrics by clicking on the legend to exclude individual metrics from the graph.</Card.Text>
                                <Card.Text>The average rates for each metric are shown underneath the graph, comparing them to the previous time period.</Card.Text>
                              </Col>
                              <Col xs={12} lg={6} className="py-0">
                                <div className="card-tip-box">
                                  <h4 className="pb-3">How to use this and what to look for</h4>
                                  <Card.Text>Use this graph to identify whether you have an issue with getting visitors to actually start the form or if they tend to start but not finish (or a combination of both).</Card.Text>
                                  <Card.Text>You can also track whether changes to the form or hosting page improve the metrics over time.</Card.Text>
                                  <Card.Text>Remember to filter on different attributes (For example, traffic source or <code>deviceType</code>) to see whether a particular audience segment is contributing to the overall trend.</Card.Text>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              </Row>
              <Row className="g-0 second-grid-row">
                <Col lg={6} md={12} className="ps-0 py-0 pe-lg-3 pe-md-0 print-as-grid">
                  <div className="flip-card">
                    <Card id="median-session-duration" className={`flip-card-inner ${showMedianSessionInfo === true ? 'perform-flip' : ''}`}>
                      <Card.Body className="p-0 d-flex">
                        <div className={`flip-card-front d-flex flex-column flex-grow-1 ${showMedianSessionInfo === null ? '' : showMedianSessionInfo === true ? 'not-visible' : showMedianSessionInfo === false ? 'visible' : ''}`}>
                          <Row className="g-0 card-title-row">
                            <Col className="p-0 justify-content-center text-center">
                              <Card.Title as="h3">Median Session Duration</Card.Title>
                            </Col>
                            <Col className="p-0 text-end card-tooltip">
                              <FaInfoCircle size="20px" className="info-circle-icon browser-only" onClick={() => {setShowMedianSessionInfo(true); mixpanel.track('Clicked Median Duration info', { page: 'FormData', ...orgDetailsForMixpanel(form?.organisation) });}} title="How to use"/>
                            </Col>
                          </Row>
                          {medianDurationData ?
                            <div className="card-vis">
                              <div data-testid="median-duration-chart-wrapper">
                                <ReactChart
                                  id="median-duration-chart"
                                  type="line"
                                  data={medianDurationData.chart}
                                  height={350}
                                  options={{
                                    maintainAspectRatio: false,
                                    scales: {
                                      y: {
                                        title: {
                                          text: 'Session Duration',
                                          display: true,
                                        },
                                        type: 'linear',
                                        beginAtZero: true,
                                        ticks: {
                                          callback: formatSecondsToTimeString,
                                        },
                                      },
                                      x: {
                                        type: 'timeseries',
                                        time: {
                                          unit: granularity,
                                          displayFormats: {
                                            hour: medianDurationData.chart.labels.length > 48 ? 'MMM DD' : 'HH[h]',
                                            day: 'MMM DD',
                                            week: 'MMM DD',
                                            month: 'MMM YYYY',
                                          },
                                          isoWeekday: true,
                                          tooltipFormat: granularity === 'hour' ? 'MMM D, HH:mm' : granularity === 'month' ? 'MMM YYYY' : 'MMM DD',
                                        },
                                      },
                                    },
                                    elements: {
                                      line: {
                                        tension: 0.05
                                      }
                                    },
                                    interaction: {
                                      intersect: false,
                                      mode: 'nearest',
                                    },
                                    plugins: {
                                      legend: {
                                        labels: {
                                          boxWidth: 20
                                        }
                                      },
                                      tooltip: {
                                        position: 'nearest',
                                        callbacks: {
                                          label: (context) => {
                                            return `${context.dataset.label}: ${formatSecondsToTimeString(context.parsed.y)}`;
                                          },
                                        },
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                            : medianDurationError ?
                              <div className="d-flex justify-content-center flex-grow-1">
                                <p className="text-center my-auto" data-testid="median-duration-chart-error">{medianDurationError}</p>
                              </div>
                              : medianDurationLoading &&
                                <div className="progress-area d-flex flex-grow-1">
                                  <ProgressBar className="my-auto" animated now={medianDurationProgress}/>
                                </div>
                          }
                          <Row className="g-0 card-stats">
                            {medianDurationData && medianDurationData.stats &&
                          [['Median Started Duration', 'total'], ['Median Abandon Duration', 'abandoned'], ['Median Completion Duration', 'completed']].map(([label, stat]) => (
                            <Col key={label} className="d-flex flex-column justify-content-center">
                              <p className="mb-0 mx-auto stat-label text-center">{label}</p>
                              <span className="stats-current text-center">{medianDurationData.stats[stat].current !== 'N/A' ? formatSecondsToTimeString(medianDurationData.stats[stat].current) : medianDurationData.stats[stat].current}</span>
                              <div className="d-flex justify-content-center stats-previous" title={medianDurationData.stats[stat].title}>
                                <i className={medianDurationData.stats[stat].icon}></i>
                                <span className="percent-diff">{medianDurationData.stats[stat].percentDiff}</span>
                              </div>
                            </Col>
                          ))}
                          </Row>
                        </div>
                        <div className={`flip-card-back ${showMedianSessionInfo === true ? 'visible' : showMedianSessionInfo === false ? 'not-visble' : ''}`}>
                          <div className="card-contents">
                            <Row className="g-0 card-title-row">
                              <Col className="p-0 justify-content-center text-center">
                                <Card.Title as="h3">Median Session Duration</Card.Title>
                              </Col>
                              <Col className="p-0 text-end card-tooltip">
                                <VscChromeClose size="20px" className="grey-icon" onClick={() => setShowMedianSessionInfo(false)} title="Return to graph"/>
                              </Col>
                            </Row>
                            <Row className="g-0 text-content">
                              <Col xs={12} lg={6} className="ps-0">
                                <Card.Text className="mb-3 subtitle">This shows how long (in seconds) visitors spend in your form.</Card.Text>
                                <dl>
                                  <dt>Why median?</dt>
                                  <dd>This is the middle value out of all durations. It's the best statistic to use when the distribution of durations can be wide.</dd>
                                </dl>
                                <Card.Text>There are two lines representing two segments - those that <i>Abandoned</i> the form and those that <i>Completed</i> it.</Card.Text>
                                <dl>
                                  <dt>Median Started Duration</dt>
                                  <dd>How long all visitors spend interacting with your form once they have started?</dd>
                                  <dt>Median Abandon Duration</dt>
                                  <dd>How long visitors who abandoned your form spend in it?</dd>
                                  <dt>Median Completion Duration</dt>
                                  <dd>How long visitors who successfully submit your form spend in it?</dd>
                                </dl>
                                <Card.Text>For each of these metrics, you can also see a comparison to the previous period.</Card.Text>
                              </Col>
                              <Col xs={12} lg={6} className="py-0">
                                <div className="card-tip-box">
                                  <h4 className="pb-3">How to use this and what to look for</h4>
                                  <Card.Text>Check whether changes to the form affect the amount of time visitors spend in it.</Card.Text>
                                  <Card.Text>Pay particular attention to the difference between <i>Abandoned</i> and <i>Completed</i> sessions. If <i>Abandoned</i> sessions are significantly lower than <i>Completed</i> ones
                                    then you may have an audience issue. I.e. visitors drop out quickly as the form isn’t relevant to them. Set up ‘traffic source’ as a custom attribute to break down whether particular
                                    audience segments are driving this.</Card.Text>
                                  <Card.Text>If you have the opposite scenario (<i>Abandoned</i> sessions are longer than <i>Completed</i> ones) then you likely have a form issue - visitors are getting lost in the form and
                                    are unable to complete it.</Card.Text>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
                <Col lg={6} md={12} className="ps-md-0 pe-0 py-0 ps-lg-3 print-as-grid">
                  <div className="flip-card">
                    <Card id="avg-field-returns" className={`flip-card-inner ${showAvgFieldReturnsInfo === true ? 'perform-flip' : ''}`}>
                      <Card.Body className="p-0 d-flex">
                        <div className={`flip-card-front d-flex flex-column flex-grow-1 ${showAvgFieldReturnsInfo === null ? '': showAvgFieldReturnsInfo === true ? 'not-visible' : showAvgFieldReturnsInfo === false ? 'visible' : ''}`}>
                          <Row className="g-0 card-title-row">
                            <Col className="p-0 justify-content-center text-center">
                              <Card.Title as="h3" ref={averageFieldReturnsChartRef}>Average Field Returns</Card.Title>
                            </Col>
                            <Col className="p-0 text-end card-tooltip">
                              <FaInfoCircle size="20px" className="info-circle-icon browser-only" onClick={() => {setShowAvgFieldReturnsInfo(true); mixpanel.track('Clicked Avg Field Returns info', { page: 'FormData', ...orgDetailsForMixpanel(form?.organisation) });}} title="How to use"/>
                            </Col>
                          </Row>
                          {avgFieldReturnsData ?
                            <div className="card-vis">
                              <div data-testid="avg-field-returns-chart-wrapper">
                                <ReactChart
                                  id="avg-field-returns-chart"
                                  type="line"
                                  data={avgFieldReturnsData.chart}
                                  height={350}
                                  options={{
                                    maintainAspectRatio: false,
                                    scales: {
                                      y: {
                                        title: {
                                          text: 'Field Returns per Session',
                                          display: true,
                                        },
                                        type: 'linear',
                                      },
                                      x: {
                                        type: 'timeseries',
                                        time: {
                                          unit: granularity,
                                          displayFormats: {
                                            hour: avgFieldReturnsData.chart.labels.length > 48 ? 'MMM DD' : 'HH[h]',
                                            day: 'MMM DD',
                                            week: 'MMM DD',
                                            month: 'MMM YYYY',
                                          },
                                          isoWeekday: true,
                                          tooltipFormat: granularity === 'hour' ? 'MMM D, HH:mm' : granularity === 'month' ? 'MMM YYYY' : 'MMM DD'
                                        },
                                      },
                                    },
                                    elements: {
                                      line: {
                                        tension: 0.05
                                      }
                                    },
                                    interaction: {
                                      intersect: false,
                                      mode: 'nearest',
                                    },
                                    plugins: {
                                      legend: {
                                        labels: {
                                          boxWidth: 20
                                        }
                                      },
                                      tooltip: {
                                        position: 'nearest',
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                            : avgFieldReturnsError ?
                              <div className="d-flex justify-content-center flex-grow-1">
                                <p className="text-center my-auto" data-testid="avg-field-returns-chart-error">{avgFieldReturnsError}</p>
                              </div>
                              : avgFieldReturnsLoading &&
                                <div className="progress-area d-flex flex-grow-1">
                                  <ProgressBar className="my-auto" animated now={avgFieldReturnsProgress}/>
                                </div>
                          }
                          <Row className="g-0 card-stats">
                            {avgFieldReturnsData && avgFieldReturnsData.stats &&
                          [['Avg. Started Field Returns', 'total'], ['Avg. Abandon Field Returns', 'abandoned'], ['Avg. Completion Field Returns', 'completed']].map(([label, stat]) => (
                            <Col className="wide-stat d-flex flex-column justify-content-center" key={label}>
                              <p className="mb-0 mx-auto stat-label text-center">{label}</p>
                              <span className="stats-current text-center">{avgFieldReturnsData.stats[stat].current.toLocaleString()}</span>
                              <div className="d-flex justify-content-center stats-previous" title={avgFieldReturnsData.stats[stat].title}>
                                <i className={avgFieldReturnsData.stats[stat].icon}></i>
                                <span className="percent-diff">{avgFieldReturnsData.stats[stat].percentDiff}</span>
                              </div>
                            </Col>
                          ))}
                          </Row>
                        </div>
                        <div className={`flip-card-back ${showAvgFieldReturnsInfo === true ? 'visible' : showAvgFieldReturnsInfo === false ? 'not-visble': ''}`}>
                          <div className="card-contents">
                            <Row className="g-0 card-title-row">
                              <Col className="p-0 justify-content-center text-center">
                                <Card.Title as="h3">Average Field Returns</Card.Title>
                              </Col>
                              <Col className="p-0 text-end card-tooltip">
                                <VscChromeClose size="20px" className="grey-icon" onClick={() => setShowAvgFieldReturnsInfo(false)} title="Return to graph"/>
                              </Col>
                            </Row>
                            <Row className="g-0 text-content">
                              <Col xs={12} lg={6} className="ps-0">
                                <Card.Text className="mb-3 subtitle">This graph compares how many times, on average, visitors return to fields during <i>Abandoned</i> and <i>Completed</i> sessions.</Card.Text>
                                <Card.Text>A field return is when a visitor interacts with a field, moves on but then comes back to it again later (often driven by error messages to correct it).</Card.Text>
                                <dl>
                                  <dt>Avg. Started Field Returns</dt>
                                  <dd>How many times do visitors return to any fields and inputs?</dd>
                                  <dt>Avg. Abandon Field Returns</dt>
                                  <dd>How many times do visitors that eventually abandon your form return to any fields and inputs?</dd>
                                  <dt>Avg. Completion Field Returns</dt>
                                  <dd>How many times do visitors that eventually complete your form return to any fields and inputs?</dd>
                                </dl>
                                <Card.Text>For each of these metrics, you can also see a comparison to the previous period.</Card.Text>
                              </Col>
                              <Col xs={12} lg={6} className="py-0">
                                <div className="card-tip-box">
                                  <h4 className="pb-3">How to use this and what to look for</h4>
                                  <Card.Text>Check whether changes you make to the form make visitors more or less likely to have to return to fields. Sometimes a simple change in the instructions around a field can help visitors get
                                    it right the first time and reduce the number of field returns.</Card.Text>
                                  <Card.Text>Look at the difference between <i>Abandoned</i> and <i>Completed</i> sessions. If <i>Abandoned</i> sessions have high average field returns they are likely unable to correctly complete forms before getting
                                    frustrated and dropping out. If field returns for abandoners are low they probably aren’t getting very far before giving up.</Card.Text>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              </Row>
              <div className="flip-card">
                <Card id="failed-submissions" className={`flip-card-inner ${(showFailedSubmissionsInfo === true) ?
                  'perform-flip' : ''}`}>
                  <Card.Body className="p-0 d-flex">
                    <div className={`flip-card-front d-flex flex-column flex-grow-1 ${showFailedSubmissionsInfo === null ?
                      '' : showFailedSubmissionsInfo === true ? 'not-visble' : showFailedSubmissionsInfo === false ?
                        'visible' : ''}`}>
                      <Row className="g-0 card-title-row">
                        <Col className="p-0">
                          <Card.Title ref={failedSubmissionsChartRef} as="h3">Failed Submissions Over Time</Card.Title>
                        </Col>
                        <Col className="p-0 text-end card-tooltip">
                          <ChartTip
                            dataLoading={failedSubmissionsLoading}
                            isData={!!failedSubmissionsData}
                            mixpanel={mixpanel}
                            organisation={form?.organisation}
                            page={'FormData'}
                            vis={'Failed Submissions Over Time'}>
                            {(failedSubmissionsData?.datasets?.[0]?.data?.some(d => d) && submitField?.label) ? <>
                              <p>Visitors are clicking this button, then failing to submit:</p>
                              <p className="tip-field">{submitField?.label}</p>
                              <p className="mb-0">
                                <Link to={`/field-flow${(query?.form && query?.time) ? compileQueryString({form: query.form, time: query.time, filters: query.filters,
                                  sessionOutcomes: [{value: 'abandoned'}], fieldFlow: {
                                    flowFieldIdentifier: submitField?.identifier,
                                  }}) : ''}`} target="_blank" onClick={() => {mixpanel.track('Clicked Quick Tip Link', { page: 'FormData', vis: 'Failed Submissions Over Time', ...orgDetailsForMixpanel(form?.organisation) });}}>Open the <i>Field Flow</i> report</Link> to find out what visitors in abandoned sessions are doing after this field.</p>
                            </> : null}
                            {!failedSubmissionsData?.datasets?.[0]?.data?.some(d => d) &&
                              <p className="mb-0">This tip looks at failed submissions, but there are none in your current timeframe.</p>}
                          </ChartTip>
                          <FaInfoCircle size="20px" className="info-circle-icon browser-only"
                            onClick={() => {
                              setShowFailedSubmissionsInfo(true);
                              mixpanel.track('Clicked Failed Submissions info', { page: 'FormData', ...orgDetailsForMixpanel(form?.organisation) });
                            }}
                            title="How to use"/>
                        </Col>
                      </Row>
                      <Row className="g-0 field-select flex-row-reverse pt-2">
                        <Col className="p-0 col-auto float-end" style={{width: '300px'}} id="submit-field-select">
                          <Select
                            styles={{
                              option: (styles, state) => ({...styles,
                                color: '#3f4047',
                                backgroundColor: state.selectProps.value && (state.selectProps.value.identifier === state.value) ? "#E2E5EC" : null,
                                '&:hover': {backgroundColor: state.isFocused ? '#F4F5F8' : null}
                              }),
                              menu: (styles, state) => ({...styles,
                                marginTop: '1px',
                                borderRadius: '4px',
                                boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
                              }),
                              dropdownIndicator: (styles, state) => ({...styles,
                                cursor: 'pointer',
                                transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
                                transition: 'transform .5s ease',
                              }),
                            }}
                            options={trackedFields}
                            onChange={handleSelectSubmitField}
                            placeholder="Select a field..."
                            value={submitField ? {...submitField, value: submitField.identifier} : null}
                          />
                        </Col>
                        <Col className="p-0 col-auto float-end" style={{marginRight: '10px', lineHeight: '37px'}}>
                          Select the field which is your 'Submit' button</Col>
                      </Row>
                      {failedSubmissionsData &&
                      <div className="card-vis">
                        <div data-testid="failed-submissions-chart-wrapper">
                          <ReactChart
                            id="failed-submissions-chart"
                            type="line"
                            data={failedSubmissionsData}
                            width={1216}
                            height={350}
                            options={{
                              maintainAspectRatio: false,
                              scales: {
                                y: {
                                  title: {
                                    text: 'Count',
                                    display: true,
                                  },
                                  type: 'linear',
                                },
                                x: {
                                  type: 'timeseries',
                                  time: {
                                    unit: granularity,
                                    displayFormats: {
                                      hour: failedSubmissionsData.labels.length > 48 ? 'MMM DD' : 'HH[h]',
                                      day: 'MMM DD',
                                      week: 'MMM DD',
                                      month: 'MMM YYYY',
                                    },
                                    isoWeekday: true,
                                    tooltipFormat: granularity === 'hour' ? 'MMM D, HH:mm' : granularity === 'month' ? 'MMM YYYY' : 'MMM DD'
                                  },
                                },
                              },
                              elements: {line: {tension: 0.05}},
                              interaction: {
                                intersect: false,
                                mode: 'nearest',
                              },
                              plugins: {
                                legend: {labels: {boxWidth: 20}},
                                tooltip: {position: 'nearest'},
                              },
                            }}
                          />
                        </div>
                      </div>}
                      {!failedSubmissionsData && failedSubmissionsError &&
                      <div className="d-flex justify-content-center flex-grow-1">
                        <p className="text-center my-auto" data-testid="failed-submissions-chart-error">{failedSubmissionsError}</p>
                      </div>}
                      {failedSubmissionsLoading &&
                      <div className="progress-area d-flex flex-grow-1">
                        <ProgressBar className="my-auto" animated now={failedSubmissionsProgress}/>
                      </div>}
                    </div>
                    <div className={`flip-card-back ${showFailedSubmissionsInfo === true ? 'visible' : showFailedSubmissionsInfo === false ? 'not-visble' : ''}`}>
                      <div className="card-contents">
                        <Row className="g-0 card-title-row">
                          <Col className="p-0 justify-content-center text-center">
                            <Card.Title as="h3">Failed Submissions Over Time</Card.Title>
                          </Col>
                          <Col className="p-0 text-end card-tooltip">
                            <VscChromeClose size="20px" className="grey-icon" onClick={() => setShowFailedSubmissionsInfo(false)} title="Return to graph"/>
                          </Col>
                        </Row>
                        <Row className="g-0 text-content">
                          <Col xs={12} lg={6} className="ps-0">
                            <dl>
                              <dt>Failed Submissions</dt>
                              <dd>This graph allows you to track the volume of failed form submissions over time. A failed submission is defined as a session where the visitor interacts with the ‘Submit’ field but, ultimately, does not successfully complete the form and ends up abandoning it.</dd>
                              <dd>Zuko will automatically identify what it believes to be the ‘Submit’ (or ‘Continue’) button on the form. However, if the field it identifies is not actually the submit button, you can manually change this using the drop down menu.</dd>
                            </dl>
                            <dl>
                              <dt>How to use this and what to look for</dt>
                              <dd>Visitors that want to submit the form but ultimately fail are an important customer segment. They are potential customers who you have failed to convert due to your form.</dd>
                              <dd>This graph allows you to track the volume of these potential customers over time and identify whether any changes you make to the form successfully reduce the metric.</dd>
                            </dl>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <div className="flip-card">
                <Card id="custom-events-total" className={`flip-card-inner ${showCustomEventsTotalInfo === true ? 'perform-flip' : ''}`}>
                  <Card.Body className="p-0 d-flex">
                    <div className={`flip-card-front d-flex flex-column flex-grow-1 ${showCustomEventsTotalInfo === true ? 'not-visible' : 'visible'}`}>
                      <Row className="g-0 card-title-row">
                        <Col className="p-0">
                          <Card.Title as="h3">Total Custom Events</Card.Title>
                        </Col>
                        <Col className="p-0 text-end card-tooltip">
                          <FaInfoCircle size="20px" className="info-circle-icon browser-only" onClick={() => {setShowCustomEventsTotalInfo(true); mixpanel.track('Clicked Custom Events Total info', { page: 'FormData', ...orgDetailsForMixpanel(form?.organisation) });}} title="How to use"/>
                        </Col>
                      </Row>
                      {customEventsTotalData ?
                        <div className="card-vis">
                          <div id="scrollable-custom-events" data-testid="custom-events-total-chart-wrapper">
                            <div>
                              <ReactChart
                                id="custom-events-total-chart"
                                type="bar"
                                data={customEventsTotalData}
                                width={1216}
                                height={customEventsTotalData.labels.length * 22 > 350 ? customEventsTotalData.labels.length * 22 : 350}
                                options={{
                                  maintainAspectRatio: false,
                                  indexAxis: 'y',
                                  scales: {
                                    y: {
                                      type: 'category',
                                      stacked: true,
                                      ticks: {
                                        // Only display up to 60 characters of the event type. The full event type is displayed in the tooltip.
                                        callback: (value, index, values) => {
                                          const label = customEventsTotalData.labels[index];
                                          return (label.length > 60 ? label.substring(0, 57) + '...' : label);
                                        },
                                      },
                                    },
                                    x: {
                                      title: {
                                        text: 'Count',
                                        display: true,
                                      },
                                      type: 'linear',
                                      stacked: true,
                                    },
                                  },
                                  plugins: {
                                    legend: {
                                      labels: {
                                        boxWidth: 20
                                      }
                                    },
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        : customEventsTotalError ?
                          <div className="d-flex justify-content-center flex-grow-1">
                            <p className="text-center my-auto" data-testid="custom-events-total-chart-error">{customEventsTotalError}</p>
                          </div>
                          : customEventsTotalLoading &&
                            <div className="progress-area d-flex flex-grow-1">
                              <ProgressBar className="my-auto" animated now={customEventsTotalProgress}/>
                            </div>
                      }
                    </div>
                    <div className={`flip-card-back ${showCustomEventsTotalInfo === true ? 'visible' : showCustomEventsTotalInfo === false ? 'not-visble' : ''}`}>
                      <div className="card-contents">
                        <Row className="g-0 card-title-row">
                          <Col className="p-0">
                            <Card.Title as="h3">Total Custom Events</Card.Title>
                          </Col>
                          <Col className="p-0 text-end card-tooltip">
                            <VscChromeClose size="20px" className="grey-icon" onClick={() => setShowCustomEventsTotalInfo(false)} title="Return to chart"/>
                          </Col>
                        </Row>
                        <Row className="g-0 text-content">
                          <Col lg={7} className="ps-0">
                            <Card.Text className="mb-3 subtitle">This chart shows how many <i>Custom Events</i> occur during the chosen time range in both <i>Abandoned</i> and <i>Completed</i> sessions.</Card.Text>
                            <figure className="text-center img-wrapper">
                              <img src={CustomEventsTotal} alt="Custom-Events-Total" width="90%" height="auto" className="card-info-img"></img>
                            </figure>
                            <Card.Text><i>Custom Events</i> are a mechanism where you can track specific events that happen throughout a visitor's form session.</Card.Text>
                            <Card.Text>The most common use case for this is to track error messages but it can be adapted to log most events that may happen in a form session (such as whether
                              a chat widget appears on screen). To set them up, take a look at our <a target="_blank" rel="noopener noreferrer" href="https://www.zuko.io/guides/tracking-custom-events-in-zuko-using-google-tag-manager">guide
                              to setting up custom events using tag manager</a> or the more detailed <a target="_blank" rel="noopener noreferrer" href="https://docs.zuko.io/knowledge-base/installation/#tracking-custom-events">development documentation</a>.</Card.Text>
                          </Col>
                          <Col lg={5} className="pt-0">
                            <div className="card-tip-box">
                              <h4 className="pb-3">How to use this and what to look for</h4>
                              <Card.Text>Look at which events occur the most in your form. For example, by looking at which error messages are shown the most you can deduce which fields visitors have the most trouble with.</Card.Text>
                              <Card.Text>You should also look at how that compares between <i>Abandoned</i> and <i>Completed</i> sessions. For example, you might see that the chat widget event happens far more in <i>Completed</i> sessions,
                              indicating that the chat widget helps people complete the form, or you might see an error message that disproportionately occurs in <i>Abandoned</i> sessions, suggesting visitors that see that error find it
                              hard to complete the form.</Card.Text>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <div className="flip-card">
                <Card id="custom-events-overview" className={`flip-card-inner ${showCustomEventsOverviewInfo === true ? 'perform-flip' : ''}`}>
                  <Card.Body className="p-0 d-flex">
                    <div className={`flip-card-front d-flex flex-column flex-grow-1 ${showCustomEventsOverviewInfo === true ? 'not-visible' : 'visible'}`}>
                      <Row className="g-0 card-title-row">
                        <Col className="p-0">
                          <Card.Title as="h3">Custom Events Overview</Card.Title>
                        </Col>
                        <Col className="p-0 text-end card-tooltip">
                          <FaInfoCircle size="20px" className="info-circle-icon browser-only" onClick={() => {setShowCustomEventsOverviewInfo(true); mixpanel.track('Clicked Custom Events Overview info', { page: 'FormData', ...orgDetailsForMixpanel(form?.organisation) });}} title="How to use"/>
                        </Col>
                      </Row>
                      {customEventsOverviewData ?
                        <div className="card-vis">
                          <DataTable columns={customEventsOverviewData.columns} data={customEventsOverviewData.data} type={"customEventsOverview"} testId={"custom-events-overview-table"}/>
                        </div>
                        : customEventsOverviewError ?
                          <div className="d-flex justify-content-center flex-grow-1">
                            <p className="text-center my-auto" data-testid="custom-events-overview-table-error">{customEventsOverviewError}</p>
                          </div>
                          : customEventsOverviewLoading &&
                            <div className="progress-area d-flex flex-grow-1">
                              <ProgressBar className="my-auto" animated now={customEventsOverviewProgress}/>
                            </div>
                      }
                    </div>
                    <div className={`flip-card-back ${showCustomEventsOverviewInfo === true ? 'visible' : showCustomEventsOverviewInfo === false ? 'not-visble' : ''}`}>
                      <div className="card-contents">
                        <Row className="g-0 card-title-row">
                          <Col className="p-0">
                            <Card.Title as="h3">Custom Events Overview</Card.Title>
                          </Col>
                          <Col className="p-0 text-end card-tooltip">
                            <VscChromeClose size="20px" className="grey-icon" onClick={() => setShowCustomEventsOverviewInfo(false)} title="Return to table"/>
                          </Col>
                        </Row>
                        <Row className="g-0 text-content">
                          <Col lg={7} className="ps-0">
                            <Card.Text className="mb-3 subtitle">This table gives us a detailed breakdown of the number of times <i>Custom Events</i> occurred during all visitor sessions, the percentage
                            of sessions these events occurred in and the average number of times the event occurred for each visitor.</Card.Text>
                            <Card.Text>For example if we saw the following metrics:</Card.Text>
                            <figure className="text-center img-wrapper">
                              <img src={CustomEventsOverview} alt="Custom-Events-Overview" width="90%" height="auto" className="card-info-img"></img>
                            </figure>
                            <Card.Text>This would mean that the event occurred 20 times, but in only half of the sessions in this form, and for each of those sessions, it occurred twice.</Card.Text>
                            <Card.Text>By ticking to show <strong>Abandoned sessions</strong> or <strong>Completed sessions</strong> at the top of the table we can see how often these events occurred
                            during <i>Abandoned</i> and <i>Completed</i> sessions and how they compare to each other, for example is a particular event occurring more times in abandoned sessions than completed sessions.</Card.Text>
                            <figure className="text-center img-wrapper">
                              <img id="Custom-Events-Overview-Session-Split" src={CustomEventsOverviewSessionSplit} alt="Custom-Events-Overview-Session-Split" className="card-info-img"></img>
                            </figure>
                            <Card.Text><i>Custom Events</i> are a mechanism where you can track specific events that happen throughout a visitor's form session.</Card.Text>
                            <Card.Text>The most common use case for this is to track error messages but it can be adapted to log most events that may happen in a form session (such as whether
                              a chat widget appears on screen). To set them up, take a look at our <a target="_blank" rel="noopener noreferrer" href="https://www.zuko.io/guides/tracking-custom-events-in-zuko-using-google-tag-manager">guide
                              to setting up custom events using tag manager</a> or the more detailed <a target="_blank" rel="noopener noreferrer" href="https://docs.zuko.io/knowledge-base/installation/#tracking-custom-events">development documentation</a>.</Card.Text>
                          </Col>
                          <Col lg={5} className="pt-0">
                            <div className="card-tip-box">
                              <h4 className="pb-3">How to use this and what to look for</h4>
                              <Card.Text>Look at which events occur the most in your form. For example, by looking at which error messages are shown the most you can deduce which fields visitors have the most trouble with.</Card.Text>
                              <Card.Text>You should also look at how that compares between <i>Abandoned</i> and <i>Completed</i> sessions. For example, you might see that the chat widget event happens far more in <i>Completed</i> sessions,
                              indicating that the chat widget helps people complete the form, or you might see an error message that disproportionately occurs in <i>Abandoned</i> sessions, suggesting visitors that see that error find it
                              hard to complete the form.</Card.Text>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <div id="hidden-charts-for-pdf-print">
                {vscaData && vscaData.chart &&
                <ReactChart
                  id="vsca-chart-copy"
                  type="line"
                  data={vscaData.chart}
                  width={1050}
                  height={350}
                  options={{
                    responsive: false, // Fix size for PDF export
                    scales: {
                      y: {
                        title: {
                          text: 'Count',
                          display: true,
                        },
                        type: 'linear',
                      },
                      x: {
                        type: 'timeseries',
                        time: {
                          unit: granularity,
                          displayFormats: {
                            hour: vscaData.chart.labels.length > 48 ? 'MMM DD' : 'HH[h]',
                            day: 'MMM DD',
                            week: 'MMM DD',
                            month: 'MMM YYYY',
                          },
                          isoWeekday: true,
                        },
                      },
                    },
                    elements: {
                      line: {
                        tension: 0.05
                      }
                    },
                    plugins: {
                      legend: {
                        labels: {
                          boxWidth: 20
                        }
                      },
                    },
                    animation: {
                      onComplete: () => {
                        if (!dataLoadedForPDF) {
                          const vscaChartRef = Chart.getChart('vsca-chart-copy');
                          if (vscaChartRef) setVscaChartImgUrl(vscaChartRef.toBase64Image());
                        }
                      }
                    },
                  }}
                />}
                {vscaSplitByAttrData && vscaSplitByAttrData.chart &&
                <ReactChart
                  id="vsca-split-by-attributes-chart-copy"
                  type="line"
                  data={vscaSplitByAttrData.chart}
                  width={500}
                  height={300}
                  options={{
                    responsive: false, // Fix size for PDF export
                    scales: {
                      y: {
                        title: {
                          text: `${vscaSplitByAttrMetric.label} Count`,
                          display: true,
                        },
                        type: 'linear',
                        stacked: true,
                      },
                      x: {
                        type: 'timeseries',
                        time: {
                          unit: granularity,
                          displayFormats: {
                            hour: vscaSplitByAttrData.chart.labels.length > 48 ? 'MMM DD' : 'HH[h]',
                            day: 'MMM DD',
                            week: 'MMM DD',
                            month: 'MMM YYYY',
                          },
                          isoWeekday: true,
                        },
                      },
                    },
                    elements: {
                      line: {
                        tension: 0.05
                      }
                    },
                    plugins: {
                      legend: {
                        labels: {
                          boxWidth: 20
                        }
                      },
                    },
                    animation: {
                      onComplete: () => {
                        if (!dataLoadedForPDF) {
                          const vscaSplitByChartRef = Chart.getChart('vsca-split-by-attributes-chart-copy');
                          if (vscaSplitByChartRef) setVscaSplitByChartImgUrl(vscaSplitByChartRef.toBase64Image());
                        }
                      }
                    },
                  }}
                />}
                {vscRatesData && vscRatesData.chart &&
                <ReactChart
                  id="vsc-rates-chart-copy"
                  type="line"
                  data={vscRatesData.chart}
                  width={500}
                  height={300}
                  options={{
                    responsive: false, // Fix size for PDF export
                    scales: {
                      y: {
                        title: {
                          text: 'Rate',
                          display: true,
                        },
                        type: 'linear',
                        beginAtZero: true,
                        ticks: {
                          callback: (value) => value.toLocaleString(undefined, {maximumFractionDigits: 2}) + '%',
                        },
                      },
                      x: {
                        type: 'timeseries',
                        time: {
                          unit: granularity,
                          displayFormats: {
                            hour: vscRatesData.chart.labels.length > 48 ? 'MMM DD' : 'HH[h]',
                            day: 'MMM DD',
                            week: 'MMM DD',
                            month: 'MMM YYYY',
                          },
                          isoWeekday: true,
                        },
                      },
                    },
                    elements: {
                      line: {
                        tension: 0.05
                      }
                    },
                    plugins: {
                      legend: {
                        labels: {
                          boxWidth: 20
                        }
                      },
                    },
                    animation: {
                      onComplete: () => {
                        if (!dataLoadedForPDF) {
                          const vscRatesChartRef = Chart.getChart('vsc-rates-chart-copy');
                          if (vscRatesChartRef) setVscRatesChartImgUrl(vscRatesChartRef.toBase64Image());
                        }
                      }
                    },
                  }}
                />}
                {medianDurationData && medianDurationData.chart &&
                <ReactChart
                  id="median-duration-chart-copy"
                  type="line"
                  data={medianDurationData.chart}
                  width={500}
                  height={300}
                  options={{
                    responsive: false, // Fix size for PDF export
                    scales: {
                      y: {
                        title: {
                          text: 'Session Duration',
                          display: true,
                        },
                        type: 'linear',
                        beginAtZero: true,
                        ticks: {
                          callback: formatSecondsToTimeString,
                        },
                      },
                      x: {
                        type: 'timeseries',
                        time: {
                          unit: granularity,
                          displayFormats: {
                            hour: medianDurationData.chart.labels.length > 48 ? 'MMM DD' : 'HH[h]',
                            day: 'MMM DD',
                            week: 'MMM DD',
                            month: 'MMM YYYY',
                          },
                          isoWeekday: true,
                        },
                      },
                    },
                    elements: {
                      line: {
                        tension: 0.05
                      }
                    },
                    plugins: {
                      legend: {
                        labels: {
                          boxWidth: 20
                        }
                      },
                    },
                    animation: {
                      onComplete: () => {
                        if (!dataLoadedForPDF) {
                          const medianDurationChartRef = Chart.getChart('median-duration-chart-copy');
                          if (medianDurationChartRef) setMedianDurationChartImgUrl(medianDurationChartRef.toBase64Image());
                        }
                      }
                    }
                  }}
                />}
                {avgFieldReturnsData && avgFieldReturnsData.chart &&
                <ReactChart
                  id="avg-field-returns-chart-copy"
                  type="line"
                  data={avgFieldReturnsData.chart}
                  width={500}
                  height={300}
                  options={{
                    responsive: false, // Fix size for PDF export
                    scales: {
                      y: {
                        title: {
                          text: 'Field Returns per Session',
                          display: true,
                        },
                        type: 'linear',
                      },
                      x: {
                        type: 'timeseries',
                        time: {
                          unit: granularity,
                          displayFormats: {
                            hour: avgFieldReturnsData.chart.labels.length > 48 ? 'MMM DD' : 'HH[h]',
                            day: 'MMM DD',
                            week: 'MMM DD',
                            month: 'MMM YYYY',
                          },
                          isoWeekday: true,
                        },
                      },
                    },
                    elements: {
                      line: {
                        tension: 0.05
                      }
                    },
                    plugins: {
                      legend: {
                        labels: {
                          boxWidth: 20
                        }
                      },
                    },
                    animation: {
                      onComplete: () => {
                        if (!dataLoadedForPDF) {
                          const avgFieldReturnsChartRef = Chart.getChart('avg-field-returns-chart-copy');
                          if (avgFieldReturnsChartRef) setAvgFieldReturnsChartImgUrl(avgFieldReturnsChartRef.toBase64Image());
                        }
                      }
                    }
                  }}
                />}
                {failedSubmissionsData &&
                  <ReactChart
                    id="failed-submissions-chart-copy"
                    type="line"
                    data={failedSubmissionsData}
                    width={1050}
                    height={350}
                    options={{
                      responsive: false, // Fix size for PDF export
                      scales: {
                        y: {
                          title: {
                            text: 'Count',
                            display: true,
                          },
                          type: 'linear',
                        },
                        x: {
                          type: 'timeseries',
                          time: {
                            unit: granularity,
                            displayFormats: {
                              hour: failedSubmissionsData.labels.length > 48 ? 'MMM DD' : 'HH[h]',
                              day: 'MMM DD',
                              week: 'MMM DD',
                              month: 'MMM YYYY',
                            },
                            isoWeekday: true,
                          },
                        },
                      },
                      elements: {line: {tension: 0.05}},
                      plugins: {
                        legend: {labels: {boxWidth: 20}},
                      },
                      animation: {
                        onComplete: () => {
                          if (!dataLoadedForPDF) {
                            const chart = Chart.getChart('failed-submissions-chart-copy');
                            if (chart) setFailedSubmissionsChartImgUrl(chart.toBase64Image());
                          }
                        }
                      },
                    }}
                  />}
                {customEventsTotalData && customEventsChartDataForPdf &&
                <ReactChart
                  id="custom-events-total-chart-copy"
                  type="bar"
                  data={customEventsChartDataForPdf}
                  width={1050}
                  height={100 + (customEventsChartDataForPdf.labels.length * 20)}
                  options={{
                    responsive: false, // Fix size for PDF export
                    indexAxis: 'y',
                    scales: {
                      y: {
                        type: 'category',
                        stacked: true,
                        ticks: {
                          // Different to the visible chart which displays 40 characters. The pdf can display more chars as tooltips aren't possible
                          callback: (value, index, values) => {
                            const label = customEventsChartDataForPdf.labels[index];
                            return (label.length > 70 ? label.substring(0, 67) + '...' : label);
                          },
                        },
                      },
                      x: {
                        title: {
                          text: 'Count',
                          display: true,
                        },
                        type: 'linear',
                        stacked: true,
                      },
                    },
                    plugins: {
                      legend: {
                        labels: {
                          boxWidth: 20
                        }
                      },
                    },
                    animation: {
                      onComplete: () => {
                        if (!dataLoadedForPDF) {
                          const customEventsTotalChartRef = Chart.getChart('custom-events-total-chart-copy');
                          if (customEventsTotalChartRef) setCustomEventsTotalChartImgUrl(customEventsTotalChartRef.toBase64Image());
                        }
                      }
                    },
                  }}
                />}
              </div>
            </Col>
          </Col> :
          <NoFormsMsg mixpanel={mixpanel} page={'FormData'}/>
        }
        <SessionReplayCTAModal show={showSessionReplayCTAModal} organisation={query?.form?.organisation} handleCloseModal={() => setShowSessionReplayCTAModal(false)}/>
      </div>
    </Container>
  );
};

export default FormData;
