import React, { useContext, useState, useEffect } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import commaNumber from 'comma-number';
import eachLimit from 'async/eachLimit';
import asyncify from 'async/asyncify';

import AppContext from '../AppContext';
import NavBar from '../NavBar';
import AppAlerts from '../Components/AppAlerts';
import FeedbackRow from '../Components/FeedbackRow';
import api from '../api';

import './Usage.scss';

const formatNumber = commaNumber.bindWith(',', '.');

const Usage = ({mixpanel}) => {
  const { currentUser, currentUserRoleInOrgs } = useContext(AppContext);
  const history = useHistory();
  const {uuid: userUuid} = useParams();

  const [orgsLoading, setOrgsLoading] = useState(true);
  const [orgsError, setOrgsError] = useState(null);
  const [orgs, setOrgs] = useState([]);
  const [orgsByUuid, setOrgsByUuid] = useState(null);
  const [orgsSessionData, setOrgsSessionData] = useState(null);
  const [orgsSessionDataError, setOrgsSessionDataError] = useState(null);
  const [formsSessionData, setFormsSessionData] = useState(null);
  const [formsSessionDataError, setFormsSessionDataError] = useState(null);
  const [userViewedByAM, setUserViewedByAM] = useState(null);
  const [userViewedByAMError, setUserViewedByAMError] = useState(null);
  const [agenciesByUuid, setAgenciesByUuid] = useState();
  const firstOrg = currentUser.organisations && (currentUser.organisations.find(o => (o.contractType === 'monthly' || o.contractType === 'fixed')) || currentUser.organisations.find(o => o.contractType === 'trial'));

  const showSignUpBtnForOrg = (org) => {
    const currentUserIsStandardInOrg = currentUserRoleInOrgs?.[org.uuid]?.name !== 'admin' ?? true;

    if (
      currentUserIsStandardInOrg ||
      currentUser.organisations?.length === 1 || // Don't show if they are only part of 1 org as it will show in the nav
      org?.type === 'AgencyClient') return false;

    if (org && ((org.subscriptionStatus && org.subscriptionStatus === 'canceled') ||
     (org.contractType && org.contractType === 'trial'))) return true;
  };

  useEffect(() => {
    mixpanel.identify(currentUser.email);
    mixpanel.track('Page View', { page: 'Usage', ...firstOrg ? {
      'Organisation Name': firstOrg.name,
      'Organisation Uuid': firstOrg.uuid,
      'Organisation Contract Type': firstOrg.contractType,
    } : {} });
  }, [mixpanel, currentUser.email, firstOrg]);

  // Simply don't allow a normal user to access another user's usage
  useEffect(() => {
    if (!currentUser.accountManager && userUuid) history.replace(`/users/${currentUser.uuid}/organisations_usage`);
  }, [currentUser.accountManager, currentUser.uuid, userUuid, history]);

  // Fetch full org details
  useEffect(() => {
    const fetchFullOrgs = async () => {
      try {
        const { data: { organisations } } = await api.get(`/users/${userUuid}/organisations`, {
          params: {
            include: ['forms'],
          }
        });

        setOrgs(organisations.sort((a, b) => {
          if (a.type === 'Agency' && b.type !== 'Agency') {
            return -1;
          }

          if (a.type !== 'Agency' && b.type === 'Agency') {
            return 1;
          }

          return 0;
        }));

        const orgsByUuid = {};
        for (const org of organisations) {
          if (!orgsByUuid.hasOwnProperty(org.uuid)) orgsByUuid[org.uuid] = org;
        }

        setOrgsByUuid(orgsByUuid);
        setOrgsLoading(false);
      } catch (e) {
        setOrgsLoading(false);
        if (e.response && e.response.status === 403) {
          history.replace(`/users/${currentUser.uuid}/organisations_usage`);
        } else {
          setOrgsError((e.response && (e.response.status === 404)) ? 'User not found.' :
            (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Oops, something went wrong loading your organisation. Please try again.');
        }
      }
    };

    if (currentUser.accountManager || (userUuid === currentUser.uuid)) fetchFullOrgs();

  }, [userUuid, history, currentUser.accountManager, currentUser.uuid]);

  // Fetch an Agency's clients
  useEffect(() => {
    if (orgs?.some(o => o.type === 'Agency')) {
      (async() => {
        try {
          const agencyOrgsResponses = await Promise.all(orgs.filter(o => o.type === 'Agency')
            .map((org) => api.get(`/organisations/${org.uuid}`, {
              params: {
                include: ['clients'],
              }})));

          setAgenciesByUuid(agencyOrgsResponses.reduce((acc, response) => {
            const org = response.data.organisation;
            acc[org.uuid] = org;
            return acc;
          }, {}));
        } catch (e) { /* Do nothing for now. */ }
      })();
    }
  }, [orgs]);

  // Fetch session data for each org
  useEffect(() => {
    const fetchOrgSessionStats = async (org) => {
      try {
        switch (org.contractType) {
        case 'trial':
        case 'fixed': {
          const {data: {count: sessionUsage}} = await api.get('/data/sessions/stats', {
            params: {
              organisationUuid: org.uuid,
              metric: 'count',
            },
          });
          setOrgsSessionData(prev => ({...prev, [org.uuid]: {sessionUsage} }));

          break;
        }
        default: {
          const {data: {count: sessionUsage}} = await api.get('/data/sessions/stats', {
            params: {
              organisationUuid: org.uuid,
              metric: 'count',
              timePeriod: {start: 'now/M', end: 'now'},
              timeZone: org.timeZone,
            },
          });
          setOrgsSessionData(prev => ({...prev, [org.uuid]: {sessionUsage} }));
        }}
      } catch (e) {
        setOrgsSessionDataError(prev => ({...prev, [org.uuid]: 'Oops, sorry something went wrong fetching session totals. Please try again.'}));
      }
    };

    // Only request org session data for orgs that have forms
    if (orgsByUuid) orgs.filter((org) => (org.hasOwnProperty('forms') && org.forms.length > 0)).map((org) => fetchOrgSessionStats(org));

  }, [orgs, orgsByUuid]);

  // Fetch session data for each org's forms
  useEffect(() => {
    const fetchFormSessionStats = async (form) => {
      try {
        switch (form.organisation.contractType) {
        case 'trial':
        case 'fixed': {
          // For the sessions total
          const {data: {count: totalSessionCount}} = await api.get('/data/sessions/stats', {
            params: {
              formUuid: form.uuid,
              metric: 'count',
            },
          });

          setFormsSessionData(prev => ({...prev, [form.uuid]: {totalSessionCount} }));
          break;
        }
        default: {
          // For the sessions total
          const {data: {count: totalSessionCount}} = await api.get('/data/sessions/stats', {
            params: {
              formUuid: form.uuid,
              metric: 'count',
              timePeriod: {start: 'now/M', end: 'now'},
              timeZone: form.organisation.timeZone,
            },
          });

          setFormsSessionData(prev => ({...prev, [form.uuid]: {totalSessionCount} }));
        }}

      } catch (e) {
        setFormsSessionDataError(prev => ({...prev, [form.uuid]: 'Oops, sorry something went wrong fetching form totals. Please try again.'}));
      }
    };

    if (orgsByUuid) orgs.filter((org) => (org.hasOwnProperty('forms') && org.forms.length > 0)).map((org) => {
      // Limit the number of forms being requested to 10 at any one time
      return eachLimit(org.forms, 10, asyncify((form) => {
        return fetchFormSessionStats({...form, organisation: org});
      }), (err) => {
        if (err) setOrgsSessionDataError(prev => ({...prev, [org.uuid]: 'Oops, sorry something went wrong fetching form session totals. Please try again.'}));
      });
    });

  }, [orgs, orgsByUuid]);

  // For an account manager looking at another user's Usage
  useEffect(() => {
    if (userUuid && (currentUser.uuid !== userUuid) && currentUser.accountManager) {
      const fetchDifferentUser = async () => {
        try {
          const { data: { user } } = await api.get(`/users/${userUuid}`);

          setUserViewedByAM(user);
        } catch (e) {
          if (e.response && e.response.status === 403) {
            history.replace(`/users/${currentUser.uuid}/organisations_usage`);
          } else {
            setUserViewedByAMError('Oops, something went wrong loading this user.');
          }
        }
      };
      fetchDifferentUser();
    }
  }, [currentUser, userUuid, history]);

  return (
    <Container fluid className="page" id="usage-page">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
        <title>Usage</title>
      </Helmet>
      <div className="nav-wrapper">
        <NavBar mixpanel={mixpanel}/>
      </div>
      <div className="main-content">
        <Col className="center-column justify-content-md-center">
          <FeedbackRow
            classList={['allow-scroll-under-nav']}
            mixpanel={mixpanel}
            page={'Usage'}
            org={null}
            messageContent={'this Usage info'} />
          <AppAlerts />
          <Row className="title-row g-0">
            <Col className="p-0">
              <h1 id="usage-title">Usage {userViewedByAM && `for user ${userViewedByAM.email}`}</h1>
            </Col>
          </Row>
          <Col className="p-0">
            {orgsError && <p>{orgsError}</p>}
            {!orgsError && !orgsLoading && userViewedByAMError && <p>{userViewedByAMError}</p>}
            {!orgsError && !orgsLoading && (orgs && orgs.length === 0) && <p>This user is not a member of any organisations.</p>}
            {orgs.length > 0 && orgs.map((org) => (
              <Card key={`org-${org.uuid}`} data-testid={`org-${org.uuid}`}>
                <Card.Body className="pb-0">
                  <Row className="g-0 card-title-row">
                    <Col className="p-0 d-inline-flex">
                      <Card.Title as="h3">
                        {org.type === 'Agency' ? 'Agency | ' : null}
                        {`${org.name} | ${formatNumber(org.sessionLimit)} sessions ${(org.contractType)} allowance`}</Card.Title>
                    </Col>
                    <Col className="p-0 col-auto align-items-center">
                      {showSignUpBtnForOrg(org) &&
                      <Link to={{pathname: `/organisations/${org.uuid}/choose-plan`, state: {organisation: {name: org.name}}}}><Button className="sign-up-btn">Sign Up</Button></Link>}
                    </Col>
                  </Row>
                  <div className="card-vis">
                    {orgsByUuid && orgsByUuid[org.uuid] && orgsByUuid[org.uuid].type !== 'Agency' && !orgsByUuid[org.uuid].name.includes('myshopify.com') &&
                     ((orgsByUuid[org.uuid].forms && orgsByUuid[org.uuid].forms.length <= 0) || (!orgsByUuid[org.uuid].hasOwnProperty('forms'))) &&
                      <p data-testid="no-forms-msg">No forms created yet - please <Link to="/forms/new">add a form</Link>.</p>}
                    {orgsSessionDataError && orgsSessionDataError[org.uuid] && <p className="fw-light">{orgsSessionDataError[org.uuid]}</p>}
                    <Col>
                      {orgsByUuid?.[org.uuid].type !== 'Agency' && orgsByUuid && orgsByUuid[org.uuid] && orgsByUuid[org.uuid].forms && orgsByUuid[org.uuid].forms.length > 0 &&
                      <Row className="g-0 card-table">
                        <Table borderless data-testid={`org-table-${org.uuid}`}>
                          <thead>
                            <tr>
                              <th>Form</th>
                              <th className="text-center">Session Usage</th>
                            </tr>
                          </thead>
                          <tbody>
                            {orgsByUuid[org.uuid].forms.map(({uuid, label}, i) => (
                              <tr key={`form-${uuid}`}>
                                <td>{label}{formsSessionDataError?.[uuid] && <span className="ps-2 fw-light" key={`form-error-${uuid}`}>{formsSessionDataError[uuid]}</span>}</td>
                                {((!formsSessionData && !formsSessionDataError) || ((formsSessionData && !formsSessionData[uuid]) && (!formsSessionDataError || !formsSessionDataError[uuid]))) &&
                                <td className="text-center"><i className="fa fa-circle-o-notch fa-spin fa-fw"/></td>}
                                {formsSessionData && formsSessionData[uuid] && <>
                                  <td className="text-center">{formatNumber(formsSessionData[uuid].totalSessionCount)}</td>
                                </>}
                              </tr>
                            ))}
                            {orgsSessionData && orgsSessionData[org.uuid] &&
                              <tr>
                                <td>Total</td>
                                <td className="text-center">{formatNumber(orgsSessionData[org.uuid].sessionUsage)}</td>
                              </tr>}
                          </tbody>
                        </Table>
                      </Row>}
                      {orgsByUuid?.[org.uuid].type === 'Agency' &&
                        <Row className="g-0 card-table">
                          <Table borderless data-testid={`org-table-${org.uuid}`}>
                            <thead>
                              <tr>
                                <th>Client</th>
                                <th className="text-center">Session Usage</th>
                              </tr>
                            </thead>
                            <tbody>
                              {agenciesByUuid?.[org.uuid]?.clients.map((client, i) => {
                                const clientHasForms = orgsByUuid[client.uuid]?.forms?.length > 0;
                                return (
                                  <tr key={`client-${client.uuid}`}>
                                    <td>{client.name}</td>
                                    <td className="text-center">
                                      {clientHasForms && !orgsSessionData?.[client.uuid] && <i className="fa fa-circle-o-notch fa-spin fa-fw"/>}
                                      {orgsSessionData?.[client.uuid] ? formatNumber(orgsSessionData[client.uuid].sessionUsage) : 0}
                                    </td>
                                  </tr>);
                              }
                              )}
                              {agenciesByUuid?.[org.uuid] &&
                              <tr>
                                <td>Total</td>
                                <td className="text-center">{formatNumber(
                                  agenciesByUuid?.[org.uuid]?.clients
                                    .map(c => orgsSessionData?.[c.uuid]?.sessionUsage)
                                    .reduce((acc, num) => {
                                      return acc + (num || 0);
                                    }, 0))}</td>
                              </tr>}
                            </tbody>
                          </Table>
                        </Row>}
                    </Col>
                  </div>
                </Card.Body>
              </Card>
            ))}
          </Col>
        </Col>
      </div>
    </Container>
  );
};

export default Usage;
