import React, { useState, useMemo } from 'react';
import { useTable, useFlexLayout, useGlobalFilter, useSortBy } from 'react-table';
import Table from 'react-bootstrap/Table';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { BiColumns } from 'react-icons/bi';
import { FaSortDown, FaSortUp } from 'react-icons/fa';
import { formatCellToStrWithPercent, formatCellToSeconds, formatCellToStr } from '../../utils.js';
import ReplayLinkGridItem from '../../Components/ReplayLinkGridItem';
import { emptySessionFilters } from '../SessionReplay/SessionsQueryNav';

import './SummaryTable.scss';

function GlobalFilter({globalFilter, setGlobalFilter}) {
  const [value, setValue] = useState(globalFilter);
  const onChange = (value) => setGlobalFilter(value || undefined);
  return (
    <span className="datatable-search-filter">
      <label className="me-2">Search:</label>
      <input
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
      />
    </span>
  );
}

const headerTooltipText = {
  allSessionsTotal: 'The number of visitors who interacted with a field',
  abandonRate: 'The rate at which visitors abandon on a field',
  percentOfAbandons: 'The percentage of abandons on a field, out of the total abandons on the form',
  allSessionsReturned: 'The proportion of visitors who came back to a field at least once',
  allMeanRtf: 'The number of times that visitors came back to a field, on average',
  allMeanTif: 'The average time spent in a field',
  differenceInReturnRate: 'The difference in return rate between those who complete the form and those who abandon it',
};

const SummaryTable = ({
  data,
  showSessionReplayColumns,
  replayEnabled,
  setShowSessionReplayCTAModal,
  query,
}) => {
  const [showSessionReplayLinkColumns, setShowSessionReplayLinkColumns] = useState(showSessionReplayColumns && replayEnabled);
  const columns = useMemo(() => [
    {Header: 'Order', accessor: 'fieldOrder', width: 80},
    {Header: 'Label', accessor: 'label', width: 240},
    {Header: 'Abandoned on field', accessor: 'session-replay-abandoned', disableSortBy: true, width: 95},
    {Header: 'Returned to field', accessor: 'session-replay-returned', disableSortBy: true, width: 78},
    {Header: 'HTML Tag Name', accessor: 'htmlTagName'},
    {Header: 'HTML Type', accessor: 'htmlType'},
    {Header: 'HTML Name', accessor: 'htmlName', width: 260},
    {Header: 'HTML ID', accessor: 'htmlId', width: 260},
    {Header: 'Sessions Interacted', accessor: 'allSessionsTotal', width: 130, Cell: formatCellToStr, sortDescFirst: true},
    {Header: 'Abandon Rate', accessor: 'abandonRate',  width: 115, Cell: formatCellToStrWithPercent, sortDescFirst: true},
    {Header: '% of Total Abandons', accessor: 'percentOfAbandons', width: 130, Cell: formatCellToStrWithPercent, sortDescFirst: true},
    {Header: 'Return Rate', accessor: 'allSessionsReturned', width: 105, Cell: formatCellToStrWithPercent, sortDescFirst: true},
    {Header: 'Mean Returns', accessor: 'allMeanRtf', width: 115, Cell: formatCellToStr, sortDescFirst: true},
    {Header: 'Mean Time Spent', accessor: 'allMeanTif',  width: 135, Cell: formatCellToSeconds, sortDescFirst: true},
    {Header: 'Behaviour Difference', accessor: 'differenceInReturnRate',  width: 120, Cell: formatCellToStrWithPercent, sortDescFirst: true},
  ], []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    state,
    setGlobalFilter,
    toggleHideColumn,
  } = useTable({
    columns,
    data,
    initialState: {
      hiddenColumns: ['fieldOrder', 'htmlTagName', 'htmlType', 'htmlName', 'htmlId', 'allMeanRtf', 'allMeanTif',
        ...(replayEnabled ? [] : ['session-replay-abandoned', 'session-replay-returned']),
      ],
      sortBy: [{ id: 'allSessionsTotal', desc: true }],
    },
    autoResetHiddenColumns: false, // Retains hidden columns on data changes
    autoResetSortBy: false,  // Retains sort on data changes
  },
  useFlexLayout,
  useGlobalFilter,
  useSortBy,
  );

  return (
    <div id="summary-field-data-table">
      <div className="d-flex justify-content-between table-search-row browser-only align-items-center pb-2">
        <div id="replay-checkbox-wrapper" className="d-inline-flex align-items-center">
          {showSessionReplayColumns && <>
            <Form.Check type="checkbox" checked={showSessionReplayLinkColumns} id="show-hide-session-replay-links"
              onChange={({target: {checked}}) => {
                setShowSessionReplayLinkColumns(checked);
                toggleHideColumn('session-replay-abandoned');
                toggleHideColumn('session-replay-returned');
              }}/>
            <Form.Label className="mb-0 ms-2" htmlFor="show-hide-session-replay-links">Show links to <i>Session Replay</i></Form.Label>
          </>}
        </div>
        <div className="d-flex align-items-center">
          <GlobalFilter globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter}/>
          <OverlayTrigger placement="bottom" trigger={'click'} rootClose overlay={<Popover id="summary-table-column-toggle">
            <Popover.Body>
              {allColumns
                .filter(c => !c.id.includes('session-replay'))
                .map((column) => {
                  return (
                    <div key={column.id} className="mb-2">
                      <label className="d-flex align-items-center">
                        <input type="checkbox"  className="me-2" {...column.getToggleHiddenProps()}
                          disabled={(!state.hiddenColumns.includes(column.id) && (state.hiddenColumns.length + 1 === columns.length))} // Don't allow final column to be hidden
                        />{' '}
                        {column.Header}
                      </label>
                    </div>
                  );
                })}
            </Popover.Body>
          </Popover>}>
            <Button className="px-3 mx-2 d-flex align-items-center toggle-columns-btn" variant="outline-secondary">Select Metrics<BiColumns className="ms-1" size="16px"/></Button>
          </OverlayTrigger>
        </div>
      </div>
      <div className="table-wrap">
        <Table className="datatable mb-0" hover {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr className="table-header-row" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => {
                  return (
                    <th className={`header-row-${column.id} d-flex justify-content-center align-items-center`}
                      {...column.getHeaderProps(column.getSortByToggleProps())} {...column.canSort && {title: "Click to sort"}}>
                      {(headerTooltipText[column.id]) ?
                        <OverlayTrigger placement="top" overlay={<Popover id="table-header-info-popover">
                          <Popover.Body>
                            {column.id === 'differenceInReturnRate' ? <div className="text-start">
                              <p>{headerTooltipText[column.id]}.</p>
                              <h4>Statistically significant differences:</h4>
                              <dl className="d-flex mb-1">
                                <dt className="d-inline"><span className="abandoned-colour-bar"></span></dt>
                                <dd>Abandoned sessions return to this field more than completed sessions</dd>
                              </dl>
                              <dl className="d-flex mb-0">
                                <dt className="d-inline"><span className="completed-colour-bar"></span></dt>
                                <dd>Completed sessions return to this field more than abandoned sessions</dd>
                              </dl>
                            </div> : headerTooltipText[column.id]}
                          </Popover.Body>
                        </Popover>}>
                          <span className="header-text-with-sort"><u className="header-text-more-info">{column.render('Header')}</u></span>
                        </OverlayTrigger> :
                        <span className={`${column.canSort ? 'header-text-with-sort' : ''}`}>{column.render('Header')}</span>}
                      {column.canSort && <div className="table-sort-icons d-flex flex-column justify-content-center">
                        <FaSortUp className={`sort-up ${(column.isSorted && !column.isSortedDesc) ? 'sorted' : ''}`} size="16px"/>
                        <FaSortDown className={`sort-down ${(column.isSorted && column.isSortedDesc) ? 'sorted' : ''}`} size="16px"/>
                      </div>}
                    </th>);
                })}
              </tr>
            ))}
          </thead>
          <tbody className="table-body" {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    const field = row.original;
                    return (
                      <td className={`cell-header-${cell.column.id}`} {...cell.getCellProps()}>
                        {(cell.column?.id === 'session-replay-abandoned' && field.abandonRate) ?
                          <ReplayLinkGridItem
                            loading={false}
                            tooltipText={<>Open <i>Session Replay</i> for sessions that abandoned on {field.label}</>}
                            setShowSessionReplayCTAModal={setShowSessionReplayCTAModal}
                            replayEnabled={replayEnabled}
                            queryStringObj={(query?.form && query?.time) ? {form: query.form, time: query.time, filters: query.filters,
                              sessionOutcomes: [{value: 'abandoned'}],
                              sessionFilters: {
                                ...emptySessionFilters,
                                abandonedFieldIdentifier: field.identifier,
                              }} : null}
                          /> : (cell.column?.id === 'session-replay-returned' && field.allSessionsReturned) ?
                            <ReplayLinkGridItem
                              loading={false}
                              tooltipText={<>Open <i>Session Replay</i> for sessions that returned to {field.label} more than average</>}
                              setShowSessionReplayCTAModal={setShowSessionReplayCTAModal}
                              replayEnabled={replayEnabled}
                              queryStringObj={(query?.form && query?.time) ? {form: query.form, time: query.time, filters: query.filters,
                                sessionOutcomes: [],
                                sessionFilters: {
                                  ...emptySessionFilters,
                                  metrics: [{
                                    for: field.identifier,
                                    operator: 'gte',
                                    type: 'rtf_count',
                                    value: field.allMeanRtf,
                                  }],
                                }} : null}
                            /> :
                            cell.column.id === 'differenceInReturnRate' && (row.original.zScore > 0 || row.original.zScore < 0) ?
                              <OverlayTrigger placement="top" overlay={<Popover>
                                <Popover.Body className="fs-6">
                                  {row.original.zScore > 0 && <p className="fs-5">Abandoned sessions return to this field more</p>}
                                  {row.original.zScore < 0 && <p className="fs-5">Completed sessions return to this field more</p>}
                                  <p className="">{row.original.abandonedSessionsReturned.toLocaleString(undefined, {maximumFractionDigits: 2}) + '%'} abandoned sessions returned to this field</p>
                                  <p className="mb-0">{row.original.completedSessionsReturned.toLocaleString(undefined, {maximumFractionDigits: 2}) + '%'} completed sessions returned to this field</p>
                                </Popover.Body>
                              </Popover>}>
                                <span className={`${cell.column.id === 'differenceInReturnRate' ?
                                  (row.original.zScore > 0 ? 'abandoned-cell' :
                                    row.original.zScore < 0 ? 'completed-cell' : '') : null} text-more-info`}>{cell.value ? cell.render('Cell') : '-'}</span>
                              </OverlayTrigger>
                              : (cell.value ? cell.render('Cell') : '-')}
                      </td>
                    );})}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default React.memo(SummaryTable);
