import React, { useEffect, useState, useContext } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import moment from 'moment-timezone';
import AppContext from '../AppContext';
import { FaInfoCircle } from "react-icons/fa";

import { invalidTimeZones } from '../Page/OrgEdit';

import './OrgDetailsForm.scss';

const OrgDetailsForm = ({org, type, handleSubmit, handleTransitionToInvoice,
  handleResetErrorMessagesForField, errorMessagesForField,
  handleResetSuccessMessage, currentUserIsStandard}) => {
  const {
    currentUser,
  } = useContext(AppContext);
  const [name, setName] = useState();
  const [sessionLimit, setSessionLimit] = useState();
  const [contractType, setContractType] = useState();
  const [replayEnabled, setReplayEnabled] = useState();
  const [reportAccessEnabled, setReportAccessEnabled] = useState();
  const [timeZone, setTimeZone] = useState();
  const [notes, setNotes] = useState();
  const [signUpProduct, setSignUpProduct] = useState();
  const [subscriptionStatus, setSubscriptionStatus] = useState();
  const [saveAttempted, setSaveAttempted] = useState(false);

  useEffect(() => {
    if (org) {
      setName(org.name);
      setContractType(org.contractType);
      setSessionLimit(org.sessionLimit);
      setReplayEnabled(org.replayEnabled);
      setReportAccessEnabled(org.reportAccessEnabled);
      setTimeZone(org.timeZone);
      setSignUpProduct(org.signUpProduct);
      setNotes(org.hasOwnProperty('notes') ? org.notes : '');
      setSubscriptionStatus(org.hasOwnProperty('subscriptionStatus') ? org.subscriptionStatus : undefined);
    }

    if (type === 'create' && !org) {
      setContractType('fixed');
      setTimeZone('UTC');
    }
  },[org, type]);

  return (
    <Form onSubmit={(e) => {
      e.preventDefault();
      if (!saveAttempted) setSaveAttempted(true);

      handleSubmit({name, contractType, sessionLimit, timeZone, notes, signUpProduct, reportAccessEnabled, replayEnabled});
    }} className="zuko-app-form" id="org-details-form">
      <FormGroup className="form-group" controlId="name">
        <Form.Label>Name</Form.Label>
        <Form.Control className={((saveAttempted && !name) || errorMessagesForField?.hasOwnProperty('name')) && "invalid-input"} type="text" value={name || ''}
          readOnly={name?.includes('myshopify.com') || currentUserIsStandard} disabled={name?.includes('myshopify.com') || currentUserIsStandard}
          required maxLength={255} onChange={({target: {value}}) => {
            setName(value);
            handleResetErrorMessagesForField('name');
            if (handleResetSuccessMessage) handleResetSuccessMessage();
          }}/>
        {(saveAttempted && !name) && <div className="invalid-input-feedback">Please add a name.</div>}
      </FormGroup>

      <FormGroup className="form-group" controlId="timeZone">
        <Form.Label>Time Zone</Form.Label>
        <Form.Control as="select" value={timeZone || ''} className="w-50" required
          readOnly={currentUserIsStandard} disabled={currentUserIsStandard}
          onChange={({target: {value}}) => {
            setTimeZone(value);
            handleResetErrorMessagesForField('timeZone');
            if (handleResetSuccessMessage) handleResetSuccessMessage();
          }}>
          <option value="" disabled hidden>Select&hellip;</option>
          {moment.tz.names().map(t => {
            return !invalidTimeZones.includes(t) ? <option value={t} key={t}>{t}</option> : null;
          })}
        </Form.Control>
      </FormGroup>

      {currentUser.accountManager && <>
        <FormGroup className="form-group" controlId="sessionLimit">
          <Form.Label>Session Limit</Form.Label><span className="am-only-input-tag ms-2">AM only</span>
          <Form.Control className={`w-25 ${((saveAttempted && sessionLimit === '') || errorMessagesForField?.hasOwnProperty('sessionLimit')) && "invalid-input"}`} type="number" min={1}
            value={sessionLimit || ''} required onChange={({target: {value}}) => {
              setSessionLimit(value);
              handleResetErrorMessagesForField('sessionLimit');
              if (handleResetSuccessMessage) handleResetSuccessMessage();
            }}/>
          {(saveAttempted && sessionLimit === '') && <div className="invalid-input-feedback">Please add a session limit number.</div>}
        </FormGroup>

        <FormGroup className="form-group" controlId="contractType">
          <Form.Label>Contract Type</Form.Label><span className="am-only-input-tag ms-2">AM only</span>
          <Form.Control as="select" className="w-25" value={contractType || ''} required
            onChange={({target: {value}}) => {
              setContractType(value);
              handleResetErrorMessagesForField('contractType');
              if (handleResetSuccessMessage) handleResetSuccessMessage();
            }}>
            <option value="" disabled hidden>Select&hellip;</option>
            <option value="fixed">Fixed</option>
            <option value="monthly">Monthly</option>
            <option value="trial">Trial</option>
          </Form.Control>
        </FormGroup>

        {(subscriptionStatus !== undefined) &&
          <FormGroup className="form-group" controlId="subscriptionStatus">
            <Form.Label>Subscription Status</Form.Label><span className="am-only-input-tag ms-2">AM only</span>
            <Form.Control type="text" readOnly required value={subscriptionStatus || ''}>
            </Form.Control>
            {currentUser.accountManager && subscriptionStatus && <>
              <Button variant="outline-primary" className="py-2 ms-0 mt-2" onClick={() => handleTransitionToInvoice()}>Remove Subscription</Button>
              <OverlayTrigger placement="top"
                overlay={
                  <Popover>
                    <Popover.Body>
                    To transition an Org from being on a subscription to being invoiced, you can remove the subscription details here.<br></br><br></br>
                    This action will remove all Stripe details from the Org and reset their Contract Type to <i>trial</i>.<br></br><br></br>
                    To start tracking an invoiced Org, ensure the Contract Type is <i>monthly</i> and the Subscription Status has been removed.
                    </Popover.Body>
                  </Popover>}>
                <span><FaInfoCircle size="18px" className="info"/></span>
              </OverlayTrigger>
            </>}
          </FormGroup>}

        {type === 'edit' && <FormGroup className="form-group" controlId="reportAccessEnabled">
          <div className="d-inline-flex align-items-center">
            <Form.Check type="checkbox" checked={reportAccessEnabled}
              onChange={(e) => {
                setReportAccessEnabled(e.target.checked);
              }}/>
            <Form.Label className="mb-0 ms-2">Report Access Enabled</Form.Label><span className="am-only-input-tag ms-2">AM only</span>
          </div>
          <div className="input-feedback">
            <p className="mb-0">When enabled, users in this org can view reports in the app.</p>
          </div>
        </FormGroup>}

        {type === 'edit' && <FormGroup className="form-group" controlId="replayEnabled">
          <div className="d-inline-flex align-items-center">
            <Form.Check type="checkbox" checked={replayEnabled}
              onChange={(e) => {
                setReplayEnabled(e.target.checked);
              }}/>
            <Form.Label className="mb-0 ms-2">Session Replay Enabled</Form.Label><span className="am-only-input-tag ms-2">AM only</span>
          </div>
          <div className="input-feedback">
            <p className="mb-0">With replay enabled, all trackable forms in this Organisation will be able to send recording data.</p>
          </div>
        </FormGroup>}

        <FormGroup className="form-group" controlId="notes">
          <Form.Label>Notes</Form.Label><span className="am-only-input-tag ms-2">AM only</span>
          <Form.Control type="text" value={notes || ''} maxLength={255} onChange={({target: {value}}) => {
            setNotes(value);
            handleResetErrorMessagesForField('notes');
            if (handleResetSuccessMessage) handleResetSuccessMessage();
          }}/>
        </FormGroup>

        {type === 'edit' && <FormGroup className="form-group" controlId="signUpProduct">
          <Form.Label>Sign Up Product</Form.Label><span className="am-only-input-tag ms-2">AM only</span>
          <Form.Control as="select" className="w-25" value={signUpProduct || ''}
            onChange={({target: {value}}) => {
              setSignUpProduct(value);
              handleResetErrorMessagesForField('signUpProduct');
              if (handleResetSuccessMessage) handleResetSuccessMessage();
            }}>
            <option value="" disabled hidden>Select&hellip;</option>
            <option value="analytics">Analytics</option>
            <option value="builder">Builder</option>
          </Form.Control>
        </FormGroup>}
      </>}

      <Row className="form-actions-row">
        {!currentUserIsStandard &&
        <Col className="p-0">
          <Button variant="primary" className="submit m-0" type="submit">Save</Button>
        </Col>}
      </Row>
    </Form>
  );
};

export default OrgDetailsForm;
