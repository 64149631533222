import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Alert from 'react-bootstrap/Alert';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Card from 'react-bootstrap/Card';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import {
  Tooltip, LinearScale,
} from 'chart.js';
import { ReactChart } from 'chartjs-react';
import { Chart } from 'chart.js';
import { SankeyController, Flow } from "chartjs-chart-sankey";
import { VscChromeClose, VscInfo } from "react-icons/vsc";
import { FaCog, FaInfoCircle, FaSpinner } from "react-icons/fa";
import { GrDocumentPdf } from "react-icons/gr";
import 'font-awesome/css/font-awesome.css';
import 'line-awesome/dist/line-awesome/css/line-awesome.css';
import Select from 'react-select';
import FieldFlowChart from '../../images/FieldFlowChart.png';
import FieldFlowSample from '../../images/FieldFlowSample.png';
import CustomEventsImg from '../../images/FieldFlow-custom-events.png';
import TableImg from '../../images/FieldFlowTable.png';
import { generatePdfDownload } from '../../helpers/pdf';

import NavBar from '../../NavBar';
import DataTable from '../../Components/DataTable';
import DatePicker from '../../Components/DatePicker';
import NoFormsMsg from '../../Components/NoFormsMsg';
import AppAlerts from '../../Components/AppAlerts';
import PrintPageHeader from '../../Components/PrintPageHeader';
import ScrollToTop from '../../Components/ScrollToTop';
import FeedbackRow from '../../Components/FeedbackRow';
import CopyUrlIcon from '../../Components/CopyUrlIcon';
import FiltersSelect from '../../Components/Select/FiltersSelect';
import SessionReplayCTAModal from '../../Components/SessionReplayCTAModal';
import AppContext from '../../AppContext';
import api from '../../api';
import { usePrevious, useAppQuery, useAppForms } from '../../hooks';
import {
  compileQueryString,
  formatFiltersForSelectDropDown,
  labelForField,
  defaultAttributes,
  formatFormSelectOptions,
  orgDetailsForMixpanel,
} from '../../utils';
import moment from 'moment-timezone';

import './FieldFlow.scss';
import Forms from '../../forms';

ReactChart.register(Tooltip, LinearScale, SankeyController, Flow);

const sessionOutcomeFilters = [{
  label: 'Session Outcome',
  options: [{
    key: 'sessionOutcome',
    value: 'abandoned',
    label: 'Abandoned',
  }, {
    key: 'sessionOutcome',
    value: 'completed',
    label: 'Completed',
  }]
}];

const FieldFlow = ({mixpanel}) => {
  const history = useHistory();
  useAppForms();
  const {
    currentUser,
    formsGroupedByOrg,
    formsGroupedByFormUuid,
    formsLoading,
    formsLoadingError
  } = useContext(AppContext);

  const {
    setQuery,
  } = useContext(AppContext);

  const {
    query,
    prevQuery,
  } = useAppQuery();
  const {
    form,
    time,
    filters,
    sessionOutcomes,
    fieldFlow: {
      includeCustomEvents,
      flowFieldIdentifier,
    } = {},
  } = query || {};

  const [selectedForm, setSelectedForm] = useState();
  const [selectedTime, setSelectedTime] = useState();
  const [selectedFilters, setSelectedFilters] = useState();
  const [selectedSessionOutcomes, setSelectedSessionOutcomes] = useState();
  const [selectedIncludeCustomEvents, setSelectedIncludeCustomEvents] = useState();
  const [availableFilters, setAvailableFilters] = useState();
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [filtersLoadingError, setFiltersLoadingError] = useState(null);
  const [flowField, setFlowField] = useState();
  const [flowFieldChangeFromChart, setFlowFieldChangeFromChart] = useState(false);

  const [availableFields, setAvailableFields] = useState([]);
  const [cancelButtonEnabled, setCancelButtonEnabled] = useState(false);
  const [applyButtonEnabled, setApplyButtonEnabled] = useState(false);

  const [fieldFlow, setFieldFlow] = useState(null);
  const [fieldFlowLoading, setFieldFlowLoading] = useState(true);
  const [fieldFlowProgress, setFieldFlowProgress] = useState(20);
  const [fieldFlowError, setFieldFlowError] = useState(null);
  const [showInfo, setShowInfo] = useState(null);
  const [customEventsInFlows, setCustomEventsInFlows] = useState(null);
  const [fieldsLoading, setFieldsLoading] = useState(true);
  const [fieldsError, setFieldsError] = useState(null);

  // Image URL and loaded status for PDF export
  const [dataLoaded, setDataLoaded] = useState(false);
  const [chartImgUrl, setChartImgUrl] = useState(null);
  const [pdfRequested, setPdfRequested] = useState(false);

  const [exportError, setExportError] = useState();

  const [showSessionReplayCTAModal, setShowSessionReplayCTAModal] = useState(false);

  const [reportVisible, setReportVisible] = useState(true);

  const prevTime = usePrevious(time);
  const prevForm = usePrevious(form);
  const prevFilters = usePrevious(filters);
  const prevSelectedTime = usePrevious(selectedTime);
  const prevSelectedForm = usePrevious(selectedForm);
  const prevSessionOutcomes = usePrevious(sessionOutcomes);
  const prevFlowField = usePrevious(flowField);
  const prevFieldsLoading = usePrevious(fieldsLoading);
  const prevDataLoaded = usePrevious(dataLoaded);

  const formUuid = form?.uuid;
  const orgName = form?.organisation?.name;
  const { sampleSize, totalSessions } = fieldFlow || {};
  const samplePercent = totalSessions && sampleSize && Math.round((sampleSize/totalSessions)*100);

  const flowFieldIdentifierForQS = useCallback(() => {
    // Provide the field if requesting the same form, otherwise allow it to be unset
    return (!selectedForm || (selectedForm.uuid === form.uuid)) ? flowField?.identifier: null;
  },[selectedForm, form?.uuid, flowField?.identifier]);

  const initiatePdfDownload = useCallback(async () => {
    try {
      const combinedFilters = (filters || []).concat(sessionOutcomes || []);

      await generatePdfDownload({
        page: 'FieldFlow',
        title: 'Field Flow',
        orgName,
        form, time, filters: combinedFilters,
        queryString: compileQueryString({form, time, filters, sessionOutcomes, fieldFlow: {
          flowFieldIdentifier: flowFieldIdentifierForQS(),
          includeCustomEvents,
        }}),
        data: {
          flowChartImgUrl: chartImgUrl, fieldFlow, samplePercent, totalSessions, sampleSize, flowField,
        }
      });
    } catch (e) {
      setExportError(true);
    } finally {
      setPdfRequested(false);
    }
  }, [orgName, form, time, filters, sessionOutcomes, includeCustomEvents, chartImgUrl, fieldFlow, samplePercent, totalSessions, sampleSize, flowField, flowFieldIdentifierForQS]);

  // Remove download error message
  useEffect(() => {
    if (exportError) setTimeout(() => {setExportError(null);}, 4000);
  }, [exportError]);

  useEffect(() => {
    // Identify the mixpanel user on page load for any subsequent tracking
    mixpanel.identify(currentUser.email);
  }, [mixpanel, currentUser.email]);

  // Send the page view mixpanel event each time current query reloads the charts
  useEffect(() => {
    if (form) {
      mixpanel.track('Page View', Object.assign({ page: 'FieldFlow' }, orgDetailsForMixpanel(form?.organisation)));
    }
  }, [mixpanel, form]);

  // Forms error
  useEffect(() => {
    if (!formsLoading && formsLoadingError) {
      setFieldFlowLoading(false);
      setFieldFlowError(formsLoadingError);
    }
  }, [formsLoading, formsLoadingError]);

  // Fields error
  useEffect(() => {
    if (!fieldsLoading && fieldsError) {
      setFieldFlowLoading(false);
      setFieldFlowError(fieldsError);
    }
  }, [fieldsLoading, fieldsError]);

  const loadFieldFlow = useCallback(async () => {
    const progressID = setInterval(() => setFieldFlowProgress((prevProgress) => prevProgress + 20), 2000); // Progress bar will reach 100% in 8 seconds
    try {
      setFieldFlowLoading(true);
      setFieldFlow(null);
      setFieldFlowError(null);
      setFieldFlowProgress(20);
      setChartImgUrl(null);
      setDataLoaded(false);
      setCustomEventsInFlows(null);

      let { data: { fieldFlows, sampleSize, totalSessions } } = await api.get('/field-flows', {
        params: {
          formUuid: form?.uuid,
          time: {start: time?.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'), end: time?.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]')},
          filters: filters?.reduce((acc, {key, label: value}) => {
            if (!acc.hasOwnProperty(key)) acc[key] = [];
            acc[key].push(value);
            return acc;
          }, {}),
          sessionOutcome: sessionOutcomes?.map(({value}) => value),
          targetFieldIdentifier: flowFieldIdentifier || flowField.identifier, // flowField.identifier is only used for a default selection,
          ...includeCustomEvents && {include: ['custom_events']},
        },
      });

      fieldFlows = fieldFlows.filter((flow) => flow.to.hasOwnProperty('hidden') ? !flow.to.hidden : true);

      setFieldFlowProgress(100);
      const isData = (fieldFlows && fieldFlows.length > 0);
      if (!isData) {
        setFieldFlowError('No data to display');
      } else {
        const chartData = [];
        const labels = [];
        const colours = ['#cf2d5d', '#403167', '#6A355C', '#f45583', '#fd8d3c', '#ffa94f', '#f16913', '#931c4b'];
        const colourByIndex = {};
        const totalFlows = fieldFlows.reduce((acc, {flow}) => acc + flow, 0);
        const maxTotalFlowsToDisplay = totalFlows * 0.8; // Only display flows in the top 80%
        const maxNumberOfFlowsToDisplay = 50;
        let currentTotalFlows = 0;

        // Prepare chart data (splitting up flows to display and Other) and create labels array
        fieldFlows.forEach(({from, to, flow}, i) => {
          if (currentTotalFlows <= maxTotalFlowsToDisplay && (i < maxNumberOfFlowsToDisplay)) {
            // A flow's 'to' label cannot be duplicated, so we key a flow using it's index in the array, which is
            // also use to lookup it's label.
            chartData.push({from: labelForField(from), to: i, flow, percentOfFlows: Math.round(((flow/totalFlows)* 100) * 100) / 100});
            labels.push(labelForField(to));

            currentTotalFlows += flow;

            // Set the session state flow colours
            if (to.label === 'Abandoned') colourByIndex[i] = '#DC3545';
            if (to.label === 'Completed') colourByIndex[i] = '#64bA7F';
            if (to.type === 'custom_event') colourByIndex[i] = '#5e64ff';
          } else {
            // Other has already been set, so add up the flow values
            if (labels[labels.length-1] === '-- Other --') {
              const lastItem = chartData[chartData.length-1];
              chartData[chartData.length-1] = {...lastItem, flow: lastItem.flow + flow, percentOfFlows: lastItem.percentOfFlows + Math.round(((flow/totalFlows)* 100) * 100) / 100};
            } else {
              chartData.push({from: labelForField(from), to: '-- Other --', flow, percentOfFlows: Math.round(((flow/totalFlows)* 100) * 100) / 100});
              labels.push('-- Other --');
            }
          }
        });

        const columns = [
          {
            Header: 'Order',
            accessor: 'order',
          },
          {
            Header: `Next Field /${includeCustomEvents && fieldFlows.some(f => f.to.type === 'custom_event') ? ' Next Event /' : ''} Session Outcome`,
            accessor: 'label',
          },
          {
            Header: 'HTML Tag Name',
            accessor: 'htmlTagName',
          },
          {
            Header: 'HTML Type',
            accessor: 'htmlType',
          },
          {
            Header: 'HTML Name',
            accessor: 'htmlName',
          },
          {
            Header: 'HTML ID',
            accessor: 'htmlId',
          },
          {
            Header: `Interaction Count${(sampleSize/totalSessions) < 1 ? ' (from sampled sessions)' : ''}`,
            accessor: 'flow',
            Cell: formatCellToStr,
          },
          {
            Header: `% of Total Interactions ${(sampleSize/totalSessions) < 1 ? ' (from sampled sessions)' : ''}`,
            accessor: 'percentOfFlows',
            Cell: formatCellToStrWithPercent,
          },
        ];

        setFieldFlow({
          sampleSize,
          totalSessions,
          chart: {
            data: isData && {
              datasets: [{
                data: chartData,
                colorFrom: '#FFE46F',
                colorTo: (context) => colourByIndex[context.dataIndex] || (colourByIndex[context.dataIndex] = colours[Object.keys(colourByIndex).length % (colours.length-1)]),
                priority: {
                  'any': 0, // This is the default priority that's applied to all flows that aren't 'Other'
                  '-- Other --': 1, // Sort the 'Other' category to the end
                },
                labels: Object.fromEntries(Object.entries(labels)),
              }],
            },
            labels,
          },
          table: {
            data: fieldFlows.map(({to, flow}) => ({...to, label: labelForField(to), flow, percentOfFlows: Math.round(((flow/totalFlows)* 100) * 100) / 100})),
            columns,
          },
        });
        if (includeCustomEvents) setCustomEventsInFlows(fieldFlows.length > 0 && fieldFlows.some(f => f.to.type === 'custom_event'));
      }
      setFieldFlowLoading(false);
      setFieldFlowProgress(0);
      clearInterval(progressID);
    } catch (e) {
      setFieldFlowError((e.response && (e.response.status === 404)) ? 'Form not found' :
        (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
      setFieldFlowLoading(false);
      setFieldFlowProgress(0);
      clearInterval(progressID);
    }
  },[form?.uuid, filters, sessionOutcomes, time?.end, time?.start, flowFieldIdentifier, flowField?.identifier, includeCustomEvents]);

  // Once a new query has been set - proceed to fetch fields
  useEffect(() => {
    if (
      query?.form?.uuid && query.time?.start && query.time?.end && // Required
      !flowFieldChangeFromChart &&
      prevQuery && (prevQuery !== query)) {
      // NB. Data for the chart is only loaded when the fields have been re-fetched and one been selected
      // Clear the data early to start the progress bar asap, and to ensure the PDF only receives the updated chart img
      setFieldFlowLoading(true);
      setFieldFlow(null);
      setFieldFlowProgress(20);
      setChartImgUrl(null);
      setDataLoaded(false);
      setCustomEventsInFlows(null);

      (async () => {
        try {
          setFieldsLoading(true);
          setFieldsError(null);
          setDataLoaded(false);

          const { form, time, filters } = query;

          let { data: { fields } } = await api.get(`/data/sessions/fields`, {
            params: {
              formUuid: form?.uuid,
              time: {
                start: time?.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
                end: time?.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
              },
              filters: filters?.reduce((acc, {key, label: value}) => {
                if (!acc.hasOwnProperty(key)) acc[key] = [];
                acc[key].push(value);
                return acc;
              }, {}),
            },
          });
          setAvailableFields(
            fields
              .map(field => ({...field, label: labelForField(field), value: field.identifier}))
              .filter(field => field.hasOwnProperty('hidden') ? !field.hidden : true)
              .sort(({order: orderA}, {order: orderB}) => (orderA === null || orderA === '') - (orderB === null || orderB === '') || (orderA > orderB) - (orderA < orderB)));
          setFieldsLoading(false);
        } catch (e) {
          setFieldsLoading(false);
          setFieldsError((e.response && (e.response.status === 404)) ? 'Form not found' :
            (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
        }
      })();
    }
  }, [query, prevQuery, flowFieldChangeFromChart]);

  // Set the field now we have the identifier
  useEffect(() => {
    if (flowFieldIdentifier && availableFields.length &&
        (!flowField || (flowField.identifier !== flowFieldIdentifier))) {
      const fieldsByIdentifier = availableFields.reduce((acc, field) => {
        acc[field.identifier] = field;
        return acc;
      }, {});
      const field = fieldsByIdentifier[flowFieldIdentifier];
      if (field) setFlowField(field);
    }
  }, [availableFields, flowField, flowFieldIdentifier]);

  // The first time a field is set (either set by the defualt or from the session) - load chart
  useEffect(() => {
    if (!prevFlowField && flowField?.identifier && formsGroupedByFormUuid) {
      loadFieldFlow();
    }
  }, [prevFlowField, flowField?.identifier, loadFieldFlow, formsGroupedByFormUuid]);

  // When fields change (availableFields are loaded each time a new query is set), then fetch the field flow chart
  useEffect(() => {
    if (prevFieldsLoading && availableFields.length && flowFieldIdentifier &&
      flowField?.identifier && prevFlowField?.identifier &&
      (flowField.identifier === prevFlowField.identifier) && !fieldFlow) {
      loadFieldFlow();
    }
  }, [availableFields, prevFieldsLoading, loadFieldFlow, flowField, prevFlowField, fieldFlow, flowFieldIdentifier]);

  // When the form has changed - reset selected field
  useEffect(() => {
    if (prevForm?.uuid && form?.uuid && (prevForm.uuid !== form.uuid)) {
      setFlowField(null);
    }
  }, [form?.uuid, prevForm?.uuid]);

  // Select a default field when no field selected
  useEffect(() => {
    if (prevFieldsLoading && !fieldsLoading && availableFields.length > 0 && !flowField &&
    (!flowField || // Either there was none, or the flowFieldIdentifier from params wasn't able to set a valid field
      (flowField && !flowFieldIdentifier)) // The flowFieldIdentifier will have been unset
    ) {
      setFlowField(availableFields.find(field => (field.htmlType === 'submit' || field.label.match(/.*(S|s)ubmit.*/))) || availableFields[0]);
    }
  }, [prevFieldsLoading, fieldsLoading, availableFields, flowField, flowFieldIdentifier]);

  // No fields, so reset the vis
  useEffect(() => {
    if (prevFieldsLoading && !fieldsLoading && !availableFields.length) {
      setFieldFlow(null);
      setFieldFlowLoading(false);
      setFieldFlowProgress(0);
      setFieldFlowError('No data to display');
      setCustomEventsInFlows(null);
    }
  }, [prevFieldsLoading, fieldsLoading, availableFields]);

  const fetchFilters = useCallback(async ({form, time}) => {
    try {
      setFiltersLoading(true);
      setFiltersLoadingError(false);
      const { data: { attributes: filters } } = await api.get(`/data/sessions/attributes`, {
        params: {
          formUuid: form.uuid,
          time: {
            start: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
            end: time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          },
        },
      });
      setAvailableFilters(sessionOutcomeFilters.concat(formatFiltersForSelectDropDown(filters)));
    } catch (e) {
      setFiltersLoadingError('Error when fetching attributes');
    } finally {
      setFiltersLoading(false);
    }
  },[]);

  // Initial load and first selections - fetch new attributes
  useEffect(() => {
    // First loaded page after login - so wait for default time to be set
    if ((!selectedForm?.uuid && !selectedTime?.start && prevForm?.uuid && form?.uuid && (prevForm.uuid === form.uuid)) && (!prevTime && time?.start)) fetchFilters({form, time});
    // Moved to page from another
    if ((!selectedForm?.uuid && !prevForm && form?.uuid) && (!selectedTime?.start && !prevTime && time?.start)) fetchFilters({form, time}); // TODO: bring this fetch up to context-level

    // Form first selected
    if (selectedForm?.uuid && (!prevSelectedForm || (prevSelectedForm.uuid !== selectedForm.uuid)) && !selectedTime) fetchFilters({form: selectedForm, time});

    // Time first selected
    if (!selectedForm && selectedTime?.start && selectedTime?.end && (!prevSelectedTime ||
     (prevSelectedTime.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') !== selectedTime.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') ||
      prevSelectedTime.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') !== selectedTime.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'))
    )) fetchFilters({form, time: selectedTime});
  }, [selectedForm, prevSelectedForm, selectedTime, prevSelectedTime, form, time, fetchFilters, prevForm, prevTime]);

  // When the selected form or time changes - fetch new attributes
  useEffect(() => {
    if (selectedForm?.uuid && selectedTime?.start && selectedTime?.end) fetchFilters({form: selectedForm, time: selectedTime});
  }, [selectedForm, selectedTime, fetchFilters]);


  // Allow PDF to be downloaded once all charts have image URLs
  useEffect(() => {
    if (!prevDataLoaded && chartImgUrl && fieldFlow && fieldFlow.table) setDataLoaded(true);
  },[prevDataLoaded, chartImgUrl, fieldFlow]);

  // Allow PDF to be downloaded even when no data
  useEffect(() => {
    if (!prevDataLoaded && !chartImgUrl && (fieldFlow && !Object.keys(fieldFlow).length) && fieldFlowError) setDataLoaded(true);
  }, [prevDataLoaded, chartImgUrl, fieldFlow, fieldFlowError]);

  // Initiate pdf download if requested, and once charts are ready
  useEffect(() => {
    if (dataLoaded && pdfRequested) initiatePdfDownload();
  }, [dataLoaded, pdfRequested, initiatePdfDownload]);

  // Update selected Form on browser back/forward
  useEffect(() => {
    if (prevForm?.uuid && form?.uuid && (prevForm.uuid !== form.uuid) && selectedForm?.uuid &&  (selectedForm.uuid !== form.uuid)) {
      setSelectedForm(form);
    }
  },[prevForm, form, selectedForm]);


  // Update selected time on browser back/forward
  useEffect(() => {
    if (prevTime?.start && prevTime?.end && time?.start && time?.end &&
      (prevTime.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') !== time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') ||
      (prevTime.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') !== time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'))) &&
      selectedTime && (selectedTime.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') !== time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') ||
      (selectedTime.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') !== time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]')))) {

      setSelectedTime(time);
    }
  },[prevTime, time, selectedTime]);

  // Update selected filters on browser back/forward
  useEffect(() => {
    if (prevFilters && filters && (prevFilters !== filters) && selectedFilters &&  (selectedFilters !== filters)) {
      setSelectedFilters(filters);
    }
  },[prevFilters, filters, selectedFilters]);

  // Update selected session outcomes on browser back/forward
  useEffect(() => {
    if (prevSessionOutcomes && sessionOutcomes && (prevSessionOutcomes !== sessionOutcomes) && selectedSessionOutcomes &&  (selectedSessionOutcomes !== sessionOutcomes)) {
      setSelectedSessionOutcomes(sessionOutcomes);
    }
  },[prevSessionOutcomes, sessionOutcomes, selectedSessionOutcomes]);

  const handleDateTimeRangeChange = (start, end) => {
    const [utcStart, utcEnd] = [start, end].map((moment) => moment.clone().utc());
    mixpanel.track('Selected Time', {
      page: 'FieldFlow',
      start: utcStart.toISOString(),
      end: utcEnd.toISOString(),
      daysDiff: utcEnd.diff(utcStart, 'days'),
      daysFromNow: moment.utc().diff(utcStart, 'days'),
      ...orgDetailsForMixpanel(form?.organisation),
    });
    setSelectedTime({start, end});
    setCancelButtonEnabled(true);
    setApplyButtonEnabled(true);
  };

  const handleFormChange = ({value: uuid}) => {
    mixpanel.track('Selected Form', { page: 'FieldFlow', ...orgDetailsForMixpanel(form?.organisation) });
    setSelectedForm(formsGroupedByFormUuid[uuid]);
    setCancelButtonEnabled(true);
    setApplyButtonEnabled(true);
  };

  const handleFiltersChange = useCallback((attrs) => {
    const defaultSelected = attrs?.filter(({key}) => defaultAttributes.includes(key));
    mixpanel.track('Selected Filters', { page: 'FieldFlow', ...(defaultSelected?.length > 0) && {filters: defaultSelected.map(f => f.value)},
      ...orgDetailsForMixpanel(form?.organisation),
    });
    setSelectedFilters((attrs && attrs.filter(a => a.key !== 'sessionOutcome')) || []);
    setSelectedSessionOutcomes((attrs && attrs.filter(a => a.key === 'sessionOutcome')) || []);
    setCancelButtonEnabled(true);
    setApplyButtonEnabled(true);
  }, [mixpanel, form?.organisation]);

  const handleFlowFieldChange = (flowField) => {
    mixpanel.track('Selected Field - Field Flow', { page: 'FieldFlow', ...orgDetailsForMixpanel(form?.organisation) });
    setFlowFieldChangeFromChart(true);
    setQuery((prevQuery) => ({...prevQuery, fieldFlow: {...prevQuery.fieldFlow, flowFieldIdentifier: flowField.identifier}}));

  };

  const handleIncludeCustomEventsChange = ({currentTarget: {checked}}) => {
    mixpanel.track('Changed Include Custom Events', { page: 'FieldFlow', ...orgDetailsForMixpanel(form?.organisation) });
    setSelectedIncludeCustomEvents(checked);
    setCancelButtonEnabled(true);
    setApplyButtonEnabled(true);
  };

  const cancelQuery = () => {
    setSelectedTime(time);
    setSelectedForm(form);
    setSelectedFilters(filters);
    setSelectedSessionOutcomes(selectedSessionOutcomes);
    setSelectedIncludeCustomEvents(includeCustomEvents);
    setCancelButtonEnabled(false);
    setApplyButtonEnabled(false);
  };

  const handleApply = () => {
    history.push(compileQueryString({
      form: selectedForm || form,
      time: selectedTime || time,
      filters: selectedFilters || filters,
      sessionOutcomes: selectedSessionOutcomes || sessionOutcomes,
      fieldFlow: {
        flowFieldIdentifier: flowFieldIdentifierForQS(),
        includeCustomEvents: selectedIncludeCustomEvents ?? includeCustomEvents,
      },
    }));

    setCancelButtonEnabled(false);
    setApplyButtonEnabled(false);
  };

  // Selected Flow Field has changed, so reload the data
  useEffect(() => {
    if (prevFlowField && flowField && (prevFlowField.identifier !== flowField.identifier)) {
      setFlowFieldChangeFromChart(false);
      loadFieldFlow();
    }
  }, [prevFlowField, flowField, loadFieldFlow]);

  const formatGroupLabel = (group) => {
    const selectedInGroup = selectedFilters?.filter(attr => attr.key === group.label).length;

    return (<div style={{display: "flex", alignItems: "center", justifyContent: "space-between", textTransform: "none"}}>
      <span style={{ color: '#3F4047', fontSize: 14, fontWeight: 500 }}>{group.label}</span>
      <span style={{display: 'inline-flex',alignItems: 'center'}}>
        <span style={{fontSize: 10, textAlign: 'center', paddingRight: '3px'}}>
          {selectedInGroup ? `${selectedInGroup} selected` : null}
        </span>
        <span style={{
          backgroundColor: "#EBECF0", borderRadius: "2em", color: "#172B4D", display: "inline-block", fontSize: 14,
          fontWeight: "normal", lineHeight: "1", minWidth: 1, padding: "0.16666666666667em 0.5em", textAlign: "center"}}>
          {group.options.length}
        </span>
      </span>
    </div>);
  };

  const formFilterOption = ({ data: { orgLabel, orgUuid, label: formLabel, value: formUuid } }, currentSearchValue ) => (
    formLabel
      .toLocaleLowerCase()
      .includes(currentSearchValue.toLocaleLowerCase()) ||
    formUuid
      .toLocaleLowerCase()
      .includes(currentSearchValue.toLocaleLowerCase()) ||
    orgUuid
      .toLocaleLowerCase()
      .includes(currentSearchValue.toLocaleLowerCase()) ||
    orgLabel
      .toLocaleLowerCase()
      .includes(currentSearchValue.toLocaleLowerCase())
  );

  const formatCellToStr = (cell) => cell.value.toLocaleString();

  const formatCellToStrWithPercent = (cell) => cell.value.toLocaleString(undefined, {maximumFractionDigits: 2}) + '%';

  const outComeString = (sessionOutcomes) => {
    if (!sessionOutcomes || !sessionOutcomes.length) return 'all';
    if (sessionOutcomes?.length === 2) return 'abandoned and completed';
    return sessionOutcomes[0].value;
  };

  useEffect(() => {
    if(!currentUser.accountManager) setReportVisible(!!form?.organisation?.reportAccessEnabled);
  }, [currentUser.accountManager, form?.organisation?.reportAccessEnabled]);

  return (
    <Container fluid className="field-flow page">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
        <title>Field Flow</title>
      </Helmet>
      <div className="nav-wrapper browser-only">
        <NavBar mixpanel={mixpanel}/>
        <Row className="g-0 nav-primary">
          <Col className="col-md-auto col-sm-12 pt-0 pe-md-1 mt-1 d-inline-flex" id="datepicker">
            <DatePicker
              startTime={(selectedTime || time)?.start}
              endTime={(selectedTime || time)?.end}
              onApply={handleDateTimeRangeChange}
              timeZone={(form && form?.organisation?.timeZone) || null}
              selectedFormTimeZone={selectedForm?.organisation?.timeZone}
            />
          </Col>
          <Col md={3} className="pt-0 px-md-1 mt-1" id="form-select">
            <Select
              styles={{
                control: (styles, state) => ({...styles,
                  border: '1px solid #EBEDF2',
                }),
                option: (styles, state) => ({...styles,
                  color: '#3f4047',
                  backgroundColor: state.selectProps.value && (state.selectProps.value.uuid === state.value) ? "#E2E5Ec" : null,
                  '&:hover': {backgroundColor: state.isFocused ? '#F4F5F8' : null}
                }),
                menu: (styles, state) => ({...styles,
                  marginTop: '1px',
                  borderRadius: '4px',
                  border: '1px solid #EBEDF2',
                  boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
                }),
                dropdownIndicator: (styles, state) => ({...styles,
                  cursor: 'pointer',
                  transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
                  transition: 'transform .5s ease',
                }),
              }}
              options={formatFormSelectOptions({formsGroupedByOrg, currentForm: form, selectedForm, formsLoadingError, formsLoading})}
              formatGroupLabel={formatGroupLabel}
              onChange={handleFormChange}
              placeholder="Select a form..."
              value={selectedForm?.label ? selectedForm : form?.label ? form : null}
              filterOption={formsGroupedByOrg && Object.values(formsGroupedByOrg).length && formFilterOption}
              isOptionDisabled={option => option.hasOwnProperty('selectable') && !option.selectable}
            />
          </Col>
          <Col className="pt-0 ps-md-1 mt-1">
            <FiltersSelect
              form={form}
              filters={(sessionOutcomes || []).concat(filters || [])}
              selectedFilters={(selectedSessionOutcomes || selectedFilters) && (selectedSessionOutcomes || []).concat(selectedFilters || [])}
              filtersLoading={filtersLoading}
              availableFilters={availableFilters}
              filtersLoadingError={filtersLoadingError}
              handleFiltersChange={handleFiltersChange} />
          </Col>
        </Row>
        <Row className="g-0 nav-secondary justify-content-end">
          <Col className="pt-1 pb-2 px-md-1 col-auto d-flex align-items-center">
            <FormGroup className="form-group d-inline-flex align-items-center mb-0" controlId="include-custom-events">
              <Form.Check inline type="checkbox" className="mb-0" label="Include custom events" checked={selectedIncludeCustomEvents ?? includeCustomEvents} onChange={handleIncludeCustomEventsChange} />
            </FormGroup>
          </Col>
          <Col className="pt-1 pb-2 col-auto">
            <Button variant="outline-secondary" className="cancel me-1 ms-0" disabled={!cancelButtonEnabled}
              onClick={cancelQuery} data-testid="cancel-field-flow-query">Cancel</Button>
            <Button className="load ms-1 me-0" disabled={!applyButtonEnabled} onClick={handleApply}>Apply</Button>
          </Col>
        </Row>
      </div>
      <ScrollToTop />
      <div className="main-content">
        {(formsLoading || formsLoadingError || !formsGroupedByOrg || Forms.length > 0) ?
          <Col className="center-column justify-content-md-center">
            <div className="pb-1">
              <FeedbackRow
                classList={['allow-scroll-under-nav']}
                mixpanel={mixpanel}
                page={'FieldFlow'}
                org={form?.organisation}
                messageContent={'Field Flow'} />
            </div>
            <AppAlerts showOrgAlerts={true} />
            <Row className="title-row g-0 browser-only">
              {orgName && form?.label && <>
                <Col className="p-0">
                  <h1 id="form-title" data-testid="page-title">
                    {`${orgName} | ${form?.label} | `}
                    <a href={form.url} target="_blank" rel="noopener noreferrer">{form.url}</a>
                  </h1>
                </Col>
                <Col className="p-0 text-end my-auto ms-auto col-auto">
                  {exportError && <p className="mb-0 pe-2 error-text d-inline-block">Error exporting data.</p>}
                  {reportVisible && <span className="icon-overlay-container">
                    <GrDocumentPdf size="20px" title="Export to PDF" className={`pdf-export-icon ${pdfRequested ? 'generating' : 'data-loaded'}`}
                      onClick={() => {setPdfRequested(true); mixpanel.track('Clicked to export', { page: 'FieldFlow', exportType: 'PDF', ...orgDetailsForMixpanel(form?.organisation) });}}/>
                    {pdfRequested && <FaSpinner size="18px" className="spinning-icon" title="Generating PDF..."/>}
                  </span>}
                  <CopyUrlIcon
                    queryString={compileQueryString({form, time, filters, sessionOutcomes, fieldFlow: {
                      flowFieldIdentifier: flowFieldIdentifierForQS(),
                      includeCustomEvents,
                    }})}/>
                  <Link to={`/forms/${formUuid}/edit`}><FaCog size="20px" className="grey-icon" title="Form settings"/></Link>
                </Col> </>
              }
            </Row>
            <Col className="chart-content p-0">
              <PrintPageHeader
                pageTitle={'Field Flow'}
                orgName={orgName}
                formLabel={form?.label}
                formUrl={form?.url}
                startTime={time?.start}
                endTime={time?.end}
                timeZone={form?.organisation?.timeZone}
                formFilters={(selectedSessionOutcomes || sessionOutcomes || []).concat(selectedFilters || filters || [])}
                searchParams={form?.uuid && time?.start && time?.end && compileQueryString({form, time, filters: filters, sessionOutcomes, fieldFlow: {
                  flowFieldIdentifier: flowFieldIdentifierForQS(),
                  includeCustomEvents,
                }})}
              />
              <div className="flip-card">
                <Card id="field-flow-vis" className={`flip-card-inner ${(showInfo === true) ? 'perform-flip' : ''}`}>
                  <Card.Body className="p-0 d-flex">
                    <div className={`flip-card-front d-flex flex-column flex-grow-1 ${showInfo === null ? '' : showInfo === true ? 'not-visble' : showInfo === false ?'visible' : ''} ${reportVisible ? '' : 'blurred-report'}`}>
                      <Row className="g-0 card-title-row">
                        <Col className="p-0 col-auto">
                          <Card.Title as="h3">Field Flow</Card.Title>
                        </Col>
                        <Col className={`p-0 ps-2 d-inline-flex align-items-center ${customEventsInFlows ? 'justify-content-between' : ''}`}>
                          {samplePercent && samplePercent < 100 && <>
                            <OverlayTrigger placement="top" overlay={<Popover>
                              <Popover.Body>
                              The data represented in Field Flow is a proportion of total sessions that interacted with the selected field. <br></br><br></br>
                              A maximum of 500 sessions per day is included in the sampled dataset.
                              </Popover.Body>
                            </Popover>}>
                              <p className="mb-0 pe-2"><span className="text-badge">Sampled dataset</span></p>
                            </OverlayTrigger>
                            <p className="mb-0">This data represents&nbsp;</p>
                            <OverlayTrigger placement="top"
                              overlay={
                                <Popover>
                                  <Popover.Body>
                                      Total sessions: {totalSessions.toLocaleString()} <br></br>Sampled sessions: {sampleSize.toLocaleString()}
                                  </Popover.Body>
                                </Popover>}>
                              <u className="text-more-info">{samplePercent}%</u>
                            </OverlayTrigger><p className="mb-0">&nbsp;of all sessions that have interacted with the selected field.</p>
                          </>}
                          {fieldFlow?.chart && fieldFlow.chart.data && samplePercent === 100 &&
                          <OverlayTrigger placement="top" overlay={<Popover>
                            <Popover.Body>
                              The data represented in Field Flow is <strong>all sessions</strong> that interacted with the selected field. <br></br><br></br>
                              The data will be sampled when the number of sessions is more than 500 sessions a day.
                            </Popover.Body>
                          </Popover>}>
                            <p className="my-auto"><span className="text-badge">Full dataset</span></p>
                          </OverlayTrigger>}
                        </Col>
                        <Col className="p-0 text-end card-tooltip">
                          <FaInfoCircle id="first-info-icon" size="20px" className="info-circle-icon browser-only" onClick={() => {setShowInfo(true); mixpanel.track('Clicked Field Flow info', { page: 'FieldFlow',
                            ...orgDetailsForMixpanel(form?.organisation) });}} title="How to use"/>
                        </Col>
                      </Row>
                      <Row className="g-0 justify-content-between">
                        <Col md={8} className="p-0 d-flex">
                          <h4 className="card-tagline my-auto">These are the journeys that visitors take next in {outComeString(sessionOutcomes)} sessions after the selected field.</h4>
                        </Col>
                        <Col md={4} className="p-0 d-flex" id="field-flow-select">
                          <Select
                            styles={{
                              container: (styles, state) => ({...styles,
                                marginTop: 'auto',
                                marginBottom: 'auto',
                                width: '100%',
                              }),
                              option: (styles, state) => ({...styles,
                                color: '#3f4047',
                                backgroundColor: state.selectProps.value && (state.selectProps.value.identifier === state.value) ? "#E2E5EC" : null,
                                '&:hover': {backgroundColor: state.isFocused ? '#F4F5F8' : null}
                              }),
                              menu: (styles, state) => ({...styles,
                                marginTop: '1px',
                                borderRadius: '4px',
                                boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
                              }),
                              dropdownIndicator: (styles, state) => ({...styles,
                                cursor: 'pointer',
                                transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
                                transition: 'transform .5s ease',
                              }),
                            }}
                            options={availableFields}
                            onChange={handleFlowFieldChange}
                            placeholder="Select a field..."
                            value={flowField ? {...flowField, value: flowField.identifier} : null}
                          />
                        </Col>
                      </Row>
                      {customEventsInFlows &&
                        <Row className="mt-1 alert-row g-0 custom-events-alert">
                          <Alert variant={'info'} className="mb-0">
                            <div className="page-alert-svg-icon d-flex"><VscInfo size="100%"/></div>
                            <p className="alert-text m-0">Flows include custom events</p>
                          </Alert>
                        </Row>}
                      {!fieldFlowLoading && fieldFlow?.chart && customEventsInFlows === false &&
                        <Row className="mt-1 alert-row g-0 custom-events-alert">
                          <Alert variant={'info'}>
                            <div className="page-alert-svg-icon d-flex"><VscInfo size="100%"/></div>
                            <p className="alert-text m-0">No custom events found in these flows. Try a different field or query. Learn more about <a target="_blank" rel="noopener noreferrer" href="https://docs.zuko.io/knowledge-base/installation/#tracking-custom-events">setting up custom events</a>.</p>
                          </Alert>
                        </Row>}
                      {fieldFlow?.chart && fieldFlow.chart.data ?
                        <div className="card-vis">
                          <div className="chart-area" data-testid="field-flow-chart-wrapper">
                            <Row className="g-0 justify-content-between flow-chart-header-row">
                              <Col className="pt-1 pe-1 pb-1 ps-0 col-auto"><p className="m-0">Selected Field</p></Col>
                              <Col className="pt-1 ps-1 pb-1 pe-0 col-auto"><p className="m-0">Next Field /{customEventsInFlows ? ' Next Event /' : ''} Session Outcome</p></Col>
                            </Row>
                            <ReactChart
                              id="field-flow-chart"
                              type="sankey"
                              data={fieldFlow.chart.data}
                              height={450}
                              width={1216}
                              options={{
                                plugins: {
                                  tooltip: {
                                    callbacks: {
                                      label: (context) => {
                                        const item = context.dataset.data[context.dataIndex];
                                        const label = fieldFlow.chart.labels[context.dataIndex];
                                        return [ `${item.from} -> ${label}` ,
                                          `Interaction Count: ${item.flow.toLocaleString()}`,
                                          `% of Interactions: ${item.percentOfFlows.toLocaleString()}%`,
                                          ...(((sampleSize/totalSessions) < 1) ? ['(data from sampled sessions)'] : []),
                                        ];
                                      },
                                      labelColor: (context) => ({backgroundColor: context.element.to.color}),
                                    },
                                  },
                                }
                              }}
                            />
                          </div>
                          <div className="mt-3">
                            {fieldFlow?.table.data && <DataTable
                              columns={fieldFlow.table.columns} data={fieldFlow.table.data} type={"fieldFlow"} testId={"field-flow-table"}
                              setShowSessionReplayCTAModal={setShowSessionReplayCTAModal}
                              query={query}
                              flowField={flowField}
                              flowFieldIdentifier={flowFieldIdentifier}
                            />}
                          </div>
                        </div>
                        : fieldFlowError ?
                          <div className="d-flex justify-content-center flex-grow-1">
                            <p className="text-center my-auto" data-testid="field-flow-error">{fieldFlowError}</p>
                          </div>
                          : fieldFlowLoading &&
                            <div className="progress-area d-flex flex-grow-1">
                              <div className="w-100">
                                <ProgressBar className="my-auto" animated now={fieldFlowProgress}/>
                                <p>{fieldFlowProgress >= 120 && (time.end.diff(time.start, 'months') > 1 ? 'For a wider date range, computing the flow data can take longer. Please wait...' : 'The flow data is being computed, please wait...')}</p>
                              </div>
                            </div>
                      }
                    </div>
                    <div className={`flip-card-back ${showInfo === true ? 'visible' : showInfo === false ? 'not-visble' : ''}`}>
                      <div className="card-contents">
                        <Row className="g-0 card-title-row">
                          <Col className="p-0">
                            <Card.Title as="h3">Field Flow</Card.Title>
                          </Col>
                          <Col className="p-0 text-end card-tooltip">
                            <VscChromeClose size="20px" className="grey-icon" onClick={() => setShowInfo(false)} title="Return to graph and table"/>
                          </Col>
                        </Row>
                        <Row className="g-0 text-content">
                          <Col lg={7} className="ps-0">
                            <Card.Text className="mb-3 subtitle">The Field Flow tool is useful to follow how your visitors typically move through your form.</Card.Text>
                            <Card.Text>One of the most important uses for it, though, is to identify your problem fields. You will almost certainly have visitors who fill in the form, click
                               the submit button but don’t successfully complete. These are visitors that you should have converted but, due to an issue with a particular field have not been able to.</Card.Text>
                            <Card.Text>Select a field from your form using the dropdown. The visualisation will then show you the next field that visitors interacted with in their journey or
                                if the visitor then <i>Abandoned</i> or <i>Completed</i> the form. And you can hover over the chart to see the exact number of interactions that took place from the selected field to
                                this next field.</Card.Text>
                            <figure className="text-center img-wrapper">
                              <img id="Field-Flow-Chart" src={FieldFlowChart} alt="Field-Flow-Chart" className="card-info-img"></img>
                            </figure>
                            <Card.Text>The table breaks down the data (including the aggregated <i>-- Other --</i> category) in more detail.</Card.Text>
                            <figure className="text-center img-wrapper">
                              <img id="Field-Flow-Table" src={TableImg} alt="Field-Flow-Table" className="card-info-img"></img>
                            </figure>

                            <Card.Text>You can choose to include custom events to find out which events occurred next.</Card.Text>
                            <figure className="text-center img-wrapper">
                              <img src={CustomEventsImg} alt="Field-Flow-custom-events" width="350px" height="auto" className="card-info-img"/>
                            </figure>
                            <Card.Text>Learn more about <a target="_blank" rel="noopener noreferrer" href="https://docs.zuko.io/knowledge-base/installation/#tracking-custom-events">setting up custom events</a>.</Card.Text>

                            <h4>Sampling</h4>
                            <Card.Text>The data will be sampled when the number of sessions for the date range and applied filters is more than 500 sessions a day. The sample size is displayed if this is the case.</Card.Text>
                            <Card.Text>For example, the dataset shown below contains flows from the <i>Selected Field</i> to the <i>Next Field / Session Outcome</i>, only from the number of <i>Sampled sessions</i>.</Card.Text>
                            <figure className="text-center img-wrapper">
                              <img id="Field-Flow-Sample" src={FieldFlowSample} alt="Field-Flow-Sample" className="card-info-img"></img>
                            </figure>
                          </Col>
                          <Col lg={5} className="py-0">
                            <div className="card-tip-box">
                              <h4 className="pb-3">How to use this and what to look for</h4>
                              <Card.Text>To identify problem fields, simply:</Card.Text>
                              <ol>
                                <li>Select the filter <code>Session Outcome: Abandoned</code> at the top of the page</li>
                                <li>Select your submit button (or the “Next” button if you have a multi-step form) from the fields dropdown just above the chart</li>
                              </ol>
                              <Card.Text>The visualisation and table will then show you which fields that visitors are returning to most after attempting to submit (most likely driven there by an error message).
                              These are the fields that are causing visitor friction and you should look at them closely.</Card.Text>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <div id="hidden-charts-for-pdf-print">
                {fieldFlow?.chart &&
                  <ReactChart
                    id="field-flow-chart-copy"
                    type="sankey"
                    data={fieldFlow.chart.data}
                    height={450}
                    width={1050}
                    options={{
                      responsive: false, // Fix size for PDF export
                      layout: {
                        padding: {
                          right: 0
                        }
                      },
                      animation: {
                        onComplete: () => {
                          if (!dataLoaded) {
                            const chart = Chart.getChart('field-flow-chart-copy');
                            if (chart) setChartImgUrl(chart.toBase64Image());
                          }
                        }
                      },
                    }}
                  />}
              </div>
            </Col>
          </Col> :
          <NoFormsMsg mixpanel={mixpanel} page={'FieldFlow'}/>
        }
        <SessionReplayCTAModal show={showSessionReplayCTAModal} organisation={query?.form?.organisation} handleCloseModal={() => setShowSessionReplayCTAModal(false)}/>
      </div>
    </Container>
  );
};

export default FieldFlow;
