import React, { useContext, useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { BarController, BarElement, CategoryScale, TimeSeriesScale, Tooltip, Legend } from 'chart.js';
import { ReactChart } from 'chartjs-react';
import 'chartjs-adapter-moment';
import 'font-awesome/css/font-awesome.css';
import 'line-awesome/dist/line-awesome/css/line-awesome.css';
import Select from 'react-select';
import AppContext from '../../AppContext';
import api from '../../api';
import './FieldData.scss';
import { saveAs } from 'file-saver';
import { usePrevious, useAppQuery, useAppForms } from '../../hooks';
import Container from 'react-bootstrap/Container';
import { Helmet } from 'react-helmet';
import NavBar from '../../NavBar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DatePicker from '../../Components/DatePicker';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import NoFormsMsg from '../../Components/NoFormsMsg';
import AppAlerts from '../../Components/AppAlerts';
import { Link, useHistory } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import ProgressBar from 'react-bootstrap/ProgressBar';
import DataTable from '../../Components/DataTable';
import FeedbackRow from '../../Components/FeedbackRow';
import CopyUrlIcon from '../../Components/CopyUrlIcon';
import FiltersSelect from '../../Components/Select/FiltersSelect';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import { VscChromeClose, VscInfo } from "react-icons/vsc";
import { FaCog, FaInfoCircle, FaSpinner } from "react-icons/fa";
import { GrDocumentCsv, GrDocumentPdf } from "react-icons/gr";
import AbandonedFieldsImg from '../../images/AbandonedFields.png';
import FieldAbandonsImg from '../../images/FieldAbandons.png';
import FieldReturnsImg from '../../images/FieldReturns.png';
import FieldTimesImg from '../../images/FieldTimes.png';
import FieldReturnsOverviewSignificantDiffImg from '../../images/FieldReturnsOverviewSignificantDiff.png';
import FieldReturnsOverviewSignificantDiffCompletedImg from '../../images/FieldReturnsOverviewSignificantDiff-Completed.png';
import PrintPageHeader from '../../Components/PrintPageHeader';
import ScrollToTop from '../../Components/ScrollToTop';
import ChartTip from '../../Components/ChartTip';
import { generatePdfDownload, maxLabels } from '../../helpers/pdf';
import { Chart } from 'chart.js';
import { acceptedGranularity } from '../../App';
import SessionReplayCTAModal from '../../Components/SessionReplayCTAModal';
import {
  getSession,
  updateSession,
  formatFiltersForSelectDropDown,
  compileQueryString,
  formatMillisecondsToTimeString,
  labelForField,
  htmlAttrsForIdentifier,
  defaultAttributes,
  formatFormSelectOptions,
  orgDetailsForMixpanel,
} from '../../utils';
import moment from 'moment-timezone';
import Forms from '../../forms';
import SummaryTable from './SummaryTable';

ReactChart.register(BarController, BarElement, CategoryScale, Tooltip, Legend, TimeSeriesScale);

/* Compare two proportions of one complete population to find the Z-score (deviation from the mean between the two proportions).
  p1 = proportion in the first sample
  p2 = proportion in the second sample
  p = proportion in the combined sample
  n1 = total number in first sample
  n2 = total number in second sample
  https://www.dummies.com/education/math/statistics/how-to-compare-two-population-proportions/
*/
export const zScoreOfProportions = ({p1, p2, p, n1, n2}) => {
  const zTop = p1-p2;
  const zBottom1 = ((1/n1)+(1/n2))*p*(1-p);
  const zBottom2 = Math.sqrt(zBottom1);
  return zTop/zBottom2;
};

const FieldData = ({mixpanel}) => {
  const history = useHistory();

  useAppForms();

  const {
    currentUser,
    setQuery,
    formsGroupedByOrg,
    formsGroupedByFormUuid,
    formsLoading,
    formsLoadingError
  } = useContext(AppContext);

  const {
    query,
    prevQuery,
  } = useAppQuery();
  const { form, time, granularity, filters } = query || {};

  // Nav/query state
  // Note: Anything prefixed with 'selected' has not been 'applied'
  const [selectedForm, setSelectedForm] = useState();
  const [selectedTime, setSelectedTime] = useState();
  const [filtersLoading, setFiltersLoading] = useState();
  const [filtersLoadingError, setFiltersLoadingError] = useState();
  const [availableFilters, setAvailableFilters] = useState();
  const [selectedFilters, setSelectedFilters] = useState();
  const [availableAbandonedFields, setAvailableAbandonedFields] = useState([]);
  const [abandonedFieldsLoading, setAbandonedFieldsLoading] = useState(false);
  const [selectedAbandonedField, setSelectedAbandonedField] = useState(null);
  const [cancelButtonEnabled, setCancelButtonEnabled] = useState(false);
  const [applyButtonEnabled, setApplyButtonEnabled] = useState(false);
  const [granularityChangeFromChart, setGranularityChangeFromChart] = useState(false);

  // Visualisation state
  const [showFieldsOverviewTableInfo, setShowFieldsOverviewTableInfo] = useState(null);
  const [fieldsOverviewTableLoading, setFieldsOverviewTableLoading] = useState(true);
  const [fieldsOverviewTableData, setFieldsOverviewTableData] = useState(null);
  const [fieldsOverviewTableError, setFieldsOverviewTableError] = useState(null);
  const [fieldsOverviewTableProgress, setFieldsOverviewTableProgress] = useState(20);
  const [abandonedFieldsOverviewVisLoading, setAbandonedFieldsOverviewVisLoading] = useState(true);
  const [abandonedFieldsOverviewVisData, setAbandonedFieldsOverviewVisData] = useState(null);
  //TODO: the columns don't need to be state as such as they never change - find a better place for them
  const [abandonedFieldsOverviewVisColumns, setAbandonedFieldsOverviewVisColumns] = useState(null);
  const [abandonedFieldsOverviewVisProgress, setAbandonedFieldsOverviewVisProgress] = useState(20);
  const [abandonedFieldsOverviewVisError, setAbandonedFieldsOverviewVisError] = useState(null);
  const [showAbandonedFieldsOverviewVisInfo, setShowAbandonedFieldsOverviewVisInfo] = useState(null);
  const [fieldReturnsOverviewLoading, setFieldReturnsOverviewVisLoading] = useState(true);
  const [fieldReturnsOverviewData, setFieldReturnsOverviewVisData] = useState(null);
  const [fieldReturnsOverviewColumns, setFieldReturnsOverviewVisColumns] = useState(null);
  const [fieldReturnsOverviewProgress, setFieldReturnsOverviewVisProgress] = useState(20);
  const [fieldReturnsOverviewError, setFieldReturnsOverviewVisError] = useState(null);
  const [showFieldReturnsOverviewVisInfo, setShowFieldReturnsOverviewVisInfo] = useState(null);
  const [fieldTimesOverviewLoading, setFieldTimesOverviewVisLoading] = useState(true);
  const [fieldTimesOverviewData, setFieldTimesOverviewVisData] = useState(null);
  const [fieldTimesOverviewColumns, setFieldTimesOverviewVisColumns] = useState(null);
  const [fieldTimesOverviewProgress, setFieldTimesOverviewVisProgress] = useState(20);
  const [fieldTimesOverviewError, setFieldTimesOverviewVisError] = useState(null);
  const [showFieldTimesOverviewVisInfo, setShowFieldTimesOverviewVisInfo] = useState(null);
  const [fieldAbandonsLoading, setFieldAbandonsVisLoading] = useState(true);
  const [fieldAbandonsData, setFieldAbandonsVisData] = useState(null);
  const [fieldAbandonsProgress, setFieldAbandonsVisProgress] = useState(20);
  const [fieldAbandonsError, setFieldAbandonsVisError] = useState(null);
  const [showFieldAbandonsVisInfo, setShowFieldAbandonsVisInfo] = useState(null);
  const [totalFieldReturnsLoading, setTotalFieldReturnsVisLoading] = useState(true);
  const [totalFieldReturnsData, setTotalFieldReturnsVisData] = useState(null);
  const [totalFieldReturnsProgress, setTotalFieldReturnsVisProgress] = useState(20);
  const [totalFieldReturnsError, setTotalFieldReturnsVisError] = useState(null);
  const [showTotalFieldReturnsVisInfo, setShowTotalFieldReturnsVisInfo] = useState(null);
  const [showHighFieldCountMsg, setShowHighFieldCountMsg] = useState();

  // Image URLs and charts loaded status for PDF export
  const [dataLoaded, setDataLoaded] = useState(false);
  const [fieldAbandonsChartImgUrl, setFieldAbandonsChartImgUrl] = useState(null);
  const [totalFieldReturnsChartImgUrl, setTotalFieldReturnsChartImgUrl] = useState(null);
  const [pdfRequested, setPdfRequested] = useState(false);

  const [csvRequested, setCsvRequested] = useState();
  const [exportError, setExportError] = useState();

  // Chart tip
  const [fieldsOverviewTableTip, setFieldsOverviewTableTip] = useState();

  const [showSessionReplayCTAModal, setShowSessionReplayCTAModal] = useState(false);

  const [reportVisible, setReportVisible] = useState(true);

  // Previous query state
  const prevTime = usePrevious(time);
  const prevForm = usePrevious(form);
  const prevFilters = usePrevious(filters);
  const prevGranularity = usePrevious(granularity);
  const prevSelectedTime = usePrevious(selectedTime);
  const prevSelectedForm = usePrevious(selectedForm);
  const prevSelectedAbandonedField = usePrevious(selectedAbandonedField);
  const prevAbandonedFieldsLoading = usePrevious(abandonedFieldsLoading);
  const prevDataLoaded = usePrevious(dataLoaded);

  const [hasScrolledToFieldReturnsOverview, setHasScrolledToFieldReturnsOverview] = useState(false);
  const [hasScrolledToFieldTimesOverview, setHasScrolledToFieldTimesOverview] = useState(false);
  const fieldReturnsOverviewRef = useRef();
  const fieldTimesOverviewRef = useRef();

  const formUuid = form?.uuid;
  const orgName = form?.organisation?.name;

  const initiatePdfDownload = useCallback(async () => {
    try {
      await generatePdfDownload({
        page: 'FieldData',
        title: 'Field Data',
        orgName,
        form, time, filters,
        queryString: compileQueryString({form, time, granularity, filters}),
        data: {
          fieldsOverviewTableData, abandonedFieldsOverviewVisData,
          fieldAbandonsChartImgUrl, selectedAbandonedField, totalFieldReturnsChartImgUrl, totalFieldReturnsData,
          fieldReturnsOverviewData, fieldTimesOverviewData,
        }
      });
    } catch (e) {
      setExportError(true);
    } finally {
      setPdfRequested(false);
    }
  }, [orgName, form, time, granularity, filters, fieldsOverviewTableData, abandonedFieldsOverviewVisData,
    fieldAbandonsChartImgUrl, selectedAbandonedField, totalFieldReturnsChartImgUrl, totalFieldReturnsData,
    fieldReturnsOverviewData, fieldTimesOverviewData]);

  const handleCsvDownload = async () => {
    try {
      const response = await api.get('/export/field_aggregate', {
        params: {
          form_uuid: form.uuid,
          time_period: {
            start: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
            end: time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          },
          filters: (filters || []).reduce((acc, {key, label: value}) => {
            if (!acc.hasOwnProperty(key)) acc[key] = [];
            acc[key].push(value);
            return acc;
          }, {}),
        },
      }
      );
      saveAs(new Blob([response.data]),  `${form.label}_${time.start.format('YYYY-MM-DD')}_${time.end.format('YYYY-MM-DD')}.csv`);
    } catch (e) {
      setExportError(true);
    } finally {
      setCsvRequested(false);
    }
  };

  const loadAbandonedFieldsOverview = useCallback(async () => {
    const progressID = setInterval(() =>
      setAbandonedFieldsOverviewVisProgress((prevProgress) => prevProgress <= 100 ?
        prevProgress + 20 : prevProgress), 100); // Progress bar will reach 100% in 400ms

    try {
      setAbandonedFieldsOverviewVisLoading(true);
      setAbandonedFieldsOverviewVisError(null);
      setAbandonedFieldsOverviewVisData(null);
      setAbandonedFieldsOverviewVisProgress(20);
      setDataLoaded(false);

      const res = await api.get('/visualisations/abandoned-fields', {
        params: {
          formUuid: form?.uuid,
          startTime: time?.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          endTime: time?.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          filter: filters?.reduce((acc, {key, label: value}) => {
            if (!acc.hasOwnProperty(key)) acc[key] = [];
            acc[key].push(value);
            return acc;
          }, {}),
        },
      });

      const columns = [
        {Header: 'Order', accessor: 'fieldOrder'},
        {Header: 'Label', accessor: 'label', disableSortBy: true},
        {Header: 'HTML Tag Name', accessor: 'htmlTagName'},
        {Header: 'HTML Type', accessor: 'htmlType'},
        {Header: 'HTML Name', accessor: 'htmlName'},
        {Header: 'HTML ID', accessor: 'htmlId'},
        {Header: 'Abandon Count', accessor: 'abandonCount', Cell: formatCellToStr},
        {Header: '% of Total Abandons', accessor: 'percentOfAbandons', Cell: formatCellToStrWithPercent},
        {Header: 'Abandon Rate', accessor: 'abandonRate', Cell: formatCellToStrWithPercent},
      ];
      const data = res.data;
      const isData = (data && Array.isArray(data) && data.length > 0) ? true : false;

      clearInterval(progressID);
      setAbandonedFieldsOverviewVisProgress(100);
      setAbandonedFieldsOverviewVisLoading(false);
      setAbandonedFieldsOverviewVisColumns(columns);
      setAbandonedFieldsOverviewVisData(isData ? sortFieldOrder(data
        .map(field => {
          field.label = field.fieldLabel;
          return {...field, label: labelForField(field)};
        })
        .filter(field => field.hasOwnProperty('hidden') ? !field.hidden : true)
      ): []);
      setAbandonedFieldsOverviewVisError(!isData && 'No data to display');
      setAbandonedFieldsOverviewVisProgress(0);
    } catch (e) {
      clearInterval(progressID);
      setAbandonedFieldsOverviewVisProgress(100);
      setAbandonedFieldsOverviewVisLoading(false);
      setAbandonedFieldsOverviewVisData(null);
      switch (e.response?.status) {
      case 401:
        setAbandonedFieldsOverviewVisError('Not logged in');
        setFieldsOverviewTableError('Not logged in');
        break;
      case 404:
        setAbandonedFieldsOverviewVisError('Form not found');
        setFieldsOverviewTableError('Form not found');
        break;
      default:
        setAbandonedFieldsOverviewVisError('Something went wrong');
        setFieldsOverviewTableError('Something went wrong');
      }
      setAbandonedFieldsOverviewVisProgress(0);
    }
  }, [form?.uuid, filters, time?.end, time?.start]);

  const loadFieldReturnsOverview = useCallback(async () => {
    const progressID = setInterval(() =>
      setFieldReturnsOverviewVisProgress((prevProgress) => prevProgress <= 100 ?
        prevProgress + 20 : prevProgress), 100); // Progress bar will reach 100% in 800ms

    try {
      setFieldReturnsOverviewVisLoading(true);
      setFieldReturnsOverviewVisError(null);
      setFieldReturnsOverviewVisData(null);
      setFieldReturnsOverviewVisProgress(20);
      setDataLoaded(false);

      const res = await api.get('/visualisations/return-to-field', {
        params: {
          formUuid: form?.uuid,
          startTime: time?.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          endTime: time?.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          filter: filters?.reduce((acc, {key, label: value}) => {
            if (!acc.hasOwnProperty(key)) acc[key] = [];
            acc[key].push(value);
            return acc;
          }, {}),
        },
      });

      const columns = [
        {Header: 'Order', accessor: 'fieldOrder'},
        {Header: 'Label', accessor: 'label', disableSortBy: true},
        {Header: 'HTML Tag Name', accessor: 'htmlTagName'},
        {Header: 'HTML Type', accessor: 'htmlType'},
        {Header: 'HTML Name', accessor: 'htmlName'},
        {Header: 'HTML ID', accessor: 'htmlId'},
        {
          Header: 'Number of Sessions Interacted', columns: [
            {Header: 'All', accessor: 'allSessionsTotal', Cell: formatCellToStr},
            {Header: 'Abandoned', accessor: 'abandonedSessionsTotal', Cell: formatCellToStr},
            {Header: 'Completed', accessor: 'completedSessionsTotal', Cell: formatCellToStr}
          ]
        },
        {
          Header: '% Sessions Returned', columns: [
            {Header: 'All', accessor: 'allSessionsReturned', Cell: formatCellToStrWithPercent},
            {Header: 'Abandoned', accessor: 'abandonedSessionsReturned', Cell: formatCellToStrWithPercent},
            {Header: 'Completed', accessor: 'completedSessionsReturned', Cell: formatCellToStrWithPercent}
          ]
        },
        {
          Header: 'Mean Returns to Field', columns: [
            {Header: 'All', accessor: 'allMeanRtf', Cell: formatCellToStr,},
            {Header: 'Abandoned', accessor: 'abandonedMeanRtf', Cell: formatCellToStr},
            {Header: 'Completed', accessor: 'completedMeanRtf', Cell: formatCellToStr}
          ]
        }
      ];

      const data = res.data;
      const isData = (data && Array.isArray(data) && data.length > 0) ? true : false;

      const dataInclSignificantDiff = isData && data.map((row) => {
        const p1 = row.abandonedSessionsReturned/100;
        const p2 = row.completedSessionsReturned/100;
        const p = row.allSessionsReturned/100;
        const n1 = row.abandonedSessionsTotal;
        const n2 = row.completedSessionsTotal;
        const zScore = zScoreOfProportions({p1, p2, p, n1, n2});
        const significantDiff = zScore <= -1.65 || zScore >= 1.65;

        return {
          ...row,
          significantDiff,
          ...significantDiff && {zScore},
        };
      });

      clearInterval(progressID);
      setFieldReturnsOverviewVisProgress(100);
      setFieldReturnsOverviewVisLoading(false);
      setFieldReturnsOverviewVisColumns(columns);
      setFieldReturnsOverviewVisData(isData ? sortFieldOrder(dataInclSignificantDiff
        .map(field => {
          field.label = field.fieldLabel;
          return {...field, label: labelForField(field)};
        })
        .filter(field => field.hasOwnProperty('hidden') ? !field.hidden : true)
      ) : []);
      setFieldReturnsOverviewVisError(!isData && 'No data to display');
      setFieldReturnsOverviewVisProgress(0);
    } catch (e) {
      clearInterval(progressID);
      setFieldReturnsOverviewVisProgress(100);
      setFieldReturnsOverviewVisLoading(false);
      setFieldReturnsOverviewVisData(null);
      switch (e.response?.status) {
      case 401:
        setFieldReturnsOverviewVisError('Not logged in');
        setFieldsOverviewTableError('Not logged in');
        break;
      case 404:
        setFieldReturnsOverviewVisError('Form not found');
        setFieldsOverviewTableError('Form not found');
        break;
      default:
        setFieldReturnsOverviewVisError('Something went wrong');
        setFieldsOverviewTableError('Something went wrong');
      }
      setFieldReturnsOverviewVisProgress(0);
    }
  }, [form?.uuid, filters, time?.end, time?.start]);

  const loadFieldTimesOverview = useCallback(async () => {
    const progressID = setInterval(() =>
      setFieldTimesOverviewVisProgress((prevProgress) => prevProgress <= 100 ?
        prevProgress + 20 : prevProgress), 100); // Progress bar will reach 100% in 400ms

    try {
      setFieldTimesOverviewVisLoading(true);
      setFieldTimesOverviewVisError(null);
      setFieldTimesOverviewVisData(null);
      setFieldTimesOverviewVisProgress(20);
      setDataLoaded(false);

      const res = await api.get('/visualisations/time-in-field', {
        params: {
          formUuid: form?.uuid,
          startTime: time?.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          endTime: time?.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          filter: filters?.reduce((acc, {key, label: value}) => {
            if (!acc.hasOwnProperty(key)) acc[key] = [];
            acc[key].push(value);
            return acc;
          }, {}),
        },
      });

      const columns = [
        {Header: 'Order', accessor: 'fieldOrder'},
        {Header: 'Label', accessor: 'label', disableSortBy: true},
        {Header: 'HTML Tag Name', accessor: 'htmlTagName'},
        {Header: 'HTML Type', accessor: 'htmlType'},
        {Header: 'HTML Name', accessor: 'htmlName'},
        {Header: 'HTML ID', accessor: 'htmlId'},
        {
          Header: 'Mean Time Spent in Field',
          columns: [
            {Header: 'All', accessor: 'allMeanTif', Cell: formatCellToSeconds},
            {Header: 'Abandoned', accessor: 'abandonedMeanTif', Cell: formatCellToSeconds},
            {Header: 'Completed', accessor: 'completedMeanTif', Cell: formatCellToSeconds},
          ]
        },
      ];

      const data = res.data;
      const isData = (data && Array.isArray(data) && data.length > 0) ? true : false;

      clearInterval(progressID);
      setFieldTimesOverviewVisProgress(100);
      setFieldTimesOverviewVisLoading(false);
      setFieldTimesOverviewVisColumns(columns);
      setFieldTimesOverviewVisData(isData ? sortFieldOrder(data
        .map(field => {
          field.label = field.fieldLabel;
          return {...field, label: labelForField(field)};
        })
        .filter(field => field.hasOwnProperty('hidden') ? !field.hidden : true)
      ) : []);
      setFieldTimesOverviewVisError(!isData && 'No data to display');
      setFieldTimesOverviewVisProgress(0);
    } catch (e) {
      clearInterval(progressID);
      setFieldTimesOverviewVisProgress(100);
      setFieldTimesOverviewVisLoading(false);
      setFieldTimesOverviewVisData(null);
      switch (e.response?.status) {
      case 401:
        setFieldTimesOverviewVisError('Not logged in');
        setFieldsOverviewTableError('Not logged in');
        break;
      case 404:
        setFieldTimesOverviewVisError('Form not found');
        setFieldsOverviewTableError('Form not found');
        break;
      default:
        setFieldTimesOverviewVisError('Something went wrong');
        setFieldsOverviewTableError('Something went wrong');
      }
      setFieldTimesOverviewVisProgress(0);
    }
  }, [form?.uuid, filters, time?.end, time?.start]);

  const loadTotalFieldReturns = useCallback(async () => {
    const progressID = setInterval(() =>
      setTotalFieldReturnsVisProgress((prevProgress) => prevProgress <= 100 ?
        prevProgress + 20 : prevProgress), 100); // Progress bar will reach 100% in 400ms

    try {
      setTotalFieldReturnsVisLoading(true);
      setTotalFieldReturnsVisError(null);
      setTotalFieldReturnsVisData(null);
      setTotalFieldReturnsVisProgress(20);
      setTotalFieldReturnsChartImgUrl(null);
      setDataLoaded(false);

      const { data } = await api.get('/visualisations/return-to-field-totals', {
        params: {
          formUuid: form?.uuid,
          startTime: time?.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          endTime: time?.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          filter: filters?.reduce((acc, {key, label: value}) => {
            if (!acc.hasOwnProperty(key)) acc[key] = [];
            acc[key].push(value);
            return acc;
          }, {}),
        },
      });

      if (data?.fields?.length) {
        const indicesToExclude = data.fields.reduce((acc, field, index) => {
          if (field.hasOwnProperty('hidden') && field.hidden) acc.push(index);
          return acc;
        }, []);

        if (indicesToExclude?.length) {
          for (const [key, value] of Object.entries(data)) {
            if (key !== 'metrics') data[key] = value.filter((item, index) => !indicesToExclude.includes(index));
          }
        }
      }

      const isData = data?.fields?.length > 0;

      data.fields = data.fields.map(field => field.label || labelForField({...htmlAttrsForIdentifier(field.identifier)}));

      clearInterval(progressID);
      setTotalFieldReturnsVisProgress(100);
      setTotalFieldReturnsVisLoading(false);
      setTotalFieldReturnsVisData(isData && formatTotalFieldReturns(data));
      setTotalFieldReturnsVisError(!isData && 'No data to display');
      setTotalFieldReturnsVisProgress(0);
    } catch (e) {
      clearInterval(progressID);
      setTotalFieldReturnsVisProgress(100);
      setTotalFieldReturnsVisLoading(false);
      setTotalFieldReturnsVisData(null);
      setTotalFieldReturnsVisError((e.response && (e.response.status === 404)) ? 'Form not found' :
        (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
      setTotalFieldReturnsVisProgress(0);
    }
  }, [form?.uuid, filters, time?.end, time?.start]);

  const loadAbandonedFields = useCallback(async () => {
    try {
      setAbandonedFieldsLoading(true);
      setAvailableAbandonedFields([]);

      const { data: { fields } } = await api.get(`/fields/abandoned`, {
        params: {
          formUuid: form?.uuid,
          time: {
            start: time?.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
            end: time?.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          },
          filters: filters?.reduce((acc, {key, label: value}) => {
            if (!acc.hasOwnProperty(key)) acc[key] = [];
            acc[key].push(value);
            return acc;
          }, {}),
        },
      });

      setAvailableAbandonedFields(fields
        .map(field => {
          field = {...field, ...htmlAttrsForIdentifier(field.value)};
          return {...field, label: labelForField(field)};
        })
        .filter(field => field.hasOwnProperty('hidden') ? !field.hidden : true));
      setAbandonedFieldsLoading(false);
    } catch (e) {
      setAbandonedFieldsLoading(false);
    }
  }, [form?.uuid, filters, time?.end, time?.start]);

  const loadFieldAbandons = useCallback(async () => {
    const progressID = setInterval(() =>
      setTotalFieldReturnsVisProgress((prevProgress) => prevProgress <= 100 ?
        prevProgress + 20 : prevProgress), 100); // Progress bar will reach 100% in 200ms

    try {
      setFieldAbandonsVisLoading(true);
      setFieldAbandonsVisError(null);
      setFieldAbandonsVisData(null);
      setFieldAbandonsVisProgress(60);
      setFieldAbandonsChartImgUrl(null);
      setDataLoaded(false);

      const res = await api.get('/visualisations/abandon-rate-for-field', {
        params: {
          formUuid: form?.uuid,
          startTime: time?.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          endTime: time?.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          timeZone: form?.organisation?.timeZone,
          filter: filters?.reduce((acc, {key, label: value}) => {
            if (!acc.hasOwnProperty(key)) acc[key] = [];
            acc[key].push(value);
            return acc;
          }, {}),
          fieldIdentifier: selectedAbandonedField.value,
          granularity,
        },
      });

      const data = res.data;
      const isData = (data && data.range.length > 0);

      clearInterval(progressID);
      setFieldAbandonsVisProgress(100);
      setFieldAbandonsVisLoading(false);
      setFieldAbandonsVisError(!isData && 'No data to display');
      setFieldAbandonsVisData(isData && formatFieldAbandons(data));
      setFieldAbandonsVisProgress(0);
    } catch (e) {
      clearInterval(progressID);
      setFieldAbandonsVisProgress(100);
      setFieldAbandonsVisLoading(false);
      setFieldAbandonsVisError((e.response && (e.response.status === 404)) ? 'Form not found' :
        (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
      setFieldAbandonsVisData(null);
      setFieldAbandonsVisProgress(0);
    }
  }, [form?.uuid,  form?.organisation?.timeZone, time?.start, time?.end, filters, selectedAbandonedField?.value, granularity]);

  const formatCellToStr = (cell) => (cell.value === null || cell.value === undefined) ? cell.value : cell.value.toLocaleString();

  const formatCellToStrWithPercent = (cell) => (cell.value === null || cell.value === undefined) ? cell.value : cell.value.toLocaleString(undefined, {maximumFractionDigits: 2}) + '%';

  const formatCellToSeconds = (cell) => (cell.value === null || cell.value === undefined || cell.value === 'N/A') ? cell.value : formatMillisecondsToTimeString(cell.value);

  const formatTotalFieldReturns = (resp) => ({
    labels: resp.fields,
    datasets: [
      {
        label: resp.metrics[0],
        data: resp.abandonedTotals,
        backgroundColor: 'rgb(204, 44, 92, 0.8)',
        borderColor: 'rgb(204, 44, 92, 0.6)',
        maxBarThickness: 100,
      },
      {
        label: resp.metrics[1],
        data: resp.completedTotals,
        backgroundColor: 'rgb(240, 118, 74, 0.8)',
        borderColor: 'rgb(240, 118, 74, 0.6)',
        maxBarThickness: 100,
      },
    ]
  });

  const formatFieldAbandons = (data) => ({
    labels: data.range,
    datasets: [
      {
        label: 'Rate %',
        data: data.abandonRate,
        backgroundColor: '#ea2e5d',
        borderColor: '#ea2e5d',
        fill: false,
        type: 'line',
        yAxisID: 'first-y-axis',
        borderWidth: 1,
        pointRadius: 1,
        order: 0,
      },
      {
        label: 'Count',
        data: data.abandonCount,
        backgroundColor: 'rgba(128,229,252,0.6)',
        hoverBackgroundColor: 'rgba(128,229,252, 0.8)',
        borderColor: 'rgba(128,229,252,0.6)',
        hoverBorderColor: 'rgba(128,229,252, 0.8)',
        type: 'bar',
        yAxisID: 'second-y-axis',
        maxBarThickness: 100,
        order: 1,
      },
    ]
  });

  const formatGroupLabel = (group) => {
    const selectedInGroup = selectedFilters?.filter(attr => attr.key === group.label).length;

    return (<div style={{display: "flex", alignItems: "center", justifyContent: "space-between", textTransform: "none"}}>
      <span style={{ color: '#3F4047', fontSize: 14, fontWeight: 500 }}>{group.label}</span>
      <span style={{display: 'inline-flex',alignItems: 'center'}}>
        <span style={{fontSize: 10, textAlign: 'center', paddingRight: '3px'}}>
          {selectedInGroup ? `${selectedInGroup} selected` : null}
        </span>
        <span style={{
          backgroundColor: "#EBECF0", borderRadius: "2em", color: "#172B4D", display: "inline-block", fontSize: 14,
          fontWeight: "normal", lineHeight: "1", minWidth: 1, padding: "0.16666666666667em 0.5em", textAlign: "center"}}>
          {group.options.length}
        </span>
      </span>
    </div>);
  };

  const formFilterOption = ({ data: { orgLabel, orgUuid, label: formLabel, value: formUuid } }, currentSearchValue ) => (
    formLabel
      .toLocaleLowerCase()
      .includes(currentSearchValue.toLocaleLowerCase()) ||
    formUuid
      .toLocaleLowerCase()
      .includes(currentSearchValue.toLocaleLowerCase()) ||
    orgUuid
      .toLocaleLowerCase()
      .includes(currentSearchValue.toLocaleLowerCase()) ||
    orgLabel
      .toLocaleLowerCase()
      .includes(currentSearchValue.toLocaleLowerCase())
  );

  const handleAbandonedFieldChange = (selectedAbandonedField) => {
    mixpanel.track('Selected Field - Field Abandons', { page: 'FieldData', ...orgDetailsForMixpanel(form?.organisation) });
    setSelectedAbandonedField(selectedAbandonedField);
  };

  const handleApply = () => {
    setCancelButtonEnabled(false);
    setApplyButtonEnabled(false);
    history.push(compileQueryString({
      form: selectedForm || form,
      time: selectedTime || time,
      granularity,
      filters: selectedFilters || filters,
    }));
  };

  const handleFiltersChange = useCallback((selectedFilters) => {
    setCancelButtonEnabled(true);
    setApplyButtonEnabled(true);
    const defaultSelected = selectedFilters?.filter(({key}) => defaultAttributes.includes(key));
    mixpanel.track('Selected Filters', { page: 'FieldData', ...(defaultSelected?.length > 0) && {filters: defaultSelected.map(f => f.value)},
      ...orgDetailsForMixpanel(form?.organisation)});
    setSelectedFilters(selectedFilters || []);
  }, [mixpanel, form?.organisation]);

  const handleDateTimeRangeChange = (start, end) => {
    setCancelButtonEnabled(true);
    setApplyButtonEnabled(true);
    const [utcStart, utcEnd] = [start, end].map((moment) => moment.clone().utc());
    mixpanel.track('Selected Time', {
      page: 'FieldData',
      start: utcStart.toISOString(),
      end: utcEnd.toISOString(),
      daysDiff: utcEnd.diff(utcStart, 'days'),
      daysFromNow: moment.utc().diff(utcStart, 'days'),
      ...orgDetailsForMixpanel(form?.organisation),
    });
    setSelectedTime({start, end});
  };

  const handleFormChange = ({value: uuid}) => {
    setCancelButtonEnabled(true);
    setApplyButtonEnabled(true);
    mixpanel.track('Selected Form', { page: 'FieldData', ...orgDetailsForMixpanel(form?.organisation) });
    setSelectedForm(formsGroupedByFormUuid[uuid]);
  };

  const handleGranularityChange = ({target: {value: selectedFieldAbandonGranularity}}) => {
    mixpanel.track('Selected Granularity - Field Abandons', { page: 'FieldData', ...orgDetailsForMixpanel(form?.organisation) });
    setGranularityChangeFromChart(true);
    setQuery((prevQuery) => ({...prevQuery, granularity: selectedFieldAbandonGranularity}));
  };

  const resetQuery = () => {
    setCancelButtonEnabled(false);
    setApplyButtonEnabled(false);
    setSelectedTime(time);
    setSelectedForm(form);
    setSelectedFilters(filters);
  };

  // Sort field order null/'' to bottom of the data array
  const sortFieldOrder = (data) => data.sort((rowA,rowB) => {
    const a = rowA.fieldOrder;
    const b = rowB.fieldOrder;
    return (a === null || a === '') - (b === null || b === '') || (a > b) - (a < b);
  });

  // Identify the mixpanel user only on page load
  useEffect(() => {
    mixpanel.identify(currentUser.email);
  }, [mixpanel, currentUser.email]);

  // Track query load in Mixpanel
  useEffect(() => {
    if (form) {
      mixpanel.track('Page View', Object.assign({ page: 'FieldData' }, orgDetailsForMixpanel(form?.organisation)));
    }
  }, [mixpanel, form]);

  // Allow PDF to be downloaded once all charts have image URLs
  useEffect(() => {
    if (!prevDataLoaded && totalFieldReturnsChartImgUrl && fieldReturnsOverviewData && fieldsOverviewTableData) {
      // NB. If there are no field returns, the Field Returns table and chart still displays

      if ((fieldTimesOverviewData || (!fieldTimesOverviewData && !fieldTimesOverviewLoading)) &&
        ((abandonedFieldsOverviewVisData && fieldAbandonsChartImgUrl) ||
        // NB. If there are no abandoned fields, neither the table nor chart will display
        ((!abandonedFieldsOverviewVisData && !abandonedFieldsOverviewVisLoading) && (!fieldAbandonsData && !fieldAbandonsLoading)))) setDataLoaded(true);
    }
  },[prevDataLoaded, fieldsOverviewTableData, abandonedFieldsOverviewVisData, abandonedFieldsOverviewVisLoading, fieldAbandonsChartImgUrl, totalFieldReturnsChartImgUrl, fieldReturnsOverviewData,
    fieldAbandonsLoading, fieldAbandonsData, fieldTimesOverviewData, fieldTimesOverviewLoading]);

  // Allow PDF to be downloaded even when no data
  useEffect(() => {
    // No data for Fields Overview means there won't be any other data either.
    if (!prevDataLoaded && !fieldsOverviewTableData && fieldsOverviewTableError && !fieldsOverviewTableLoading) setDataLoaded(true);
  }, [prevDataLoaded, fieldsOverviewTableData, fieldsOverviewTableError, fieldsOverviewTableLoading]);

  // Initiate pdf download if requested, and once charts are ready
  useEffect(() => {
    if (dataLoaded && pdfRequested) initiatePdfDownload();
  }, [dataLoaded, pdfRequested, initiatePdfDownload]);

  // Remove download error message
  useEffect(() => {
    if (exportError) setTimeout(() => {setExportError(null);}, 4000);
  }, [exportError]);

  // There was an error loading forms so end all chart loading
  useEffect(() => {
    if (!formsLoading && formsLoadingError) {
      setFieldsOverviewTableLoading(false);
      setFieldsOverviewTableError(formsLoadingError);
      setAbandonedFieldsOverviewVisLoading(false);
      setAbandonedFieldsOverviewVisError(formsLoadingError);
      setFieldReturnsOverviewVisLoading(false);
      setFieldReturnsOverviewVisError(formsLoadingError);
      setFieldTimesOverviewVisLoading(false);
      setFieldTimesOverviewVisError(formsLoadingError);
      setFieldAbandonsVisLoading(false);
      setFieldAbandonsVisError(formsLoadingError);
      setTotalFieldReturnsVisLoading(false);
      setTotalFieldReturnsVisError(formsLoadingError);
    }
  }, [formsLoading, formsLoadingError]);

  // The current query has been updated either by: selecting incoming query / Apply / navigating
  useEffect(() => {
    if ((prevQuery && query && (prevQuery !== query)) &&
      !granularityChangeFromChart &&
      query.form?.uuid && query.form.organisation?.timeZone && query.time?.start && query.time?.end && query.granularity  // Required
    ) {
      setShowHighFieldCountMsg(null);
      setFieldsOverviewTableLoading(true);
      setFieldsOverviewTableData(null);
      setFieldsOverviewTableProgress(20);
      setFieldsOverviewTableError(null);
      loadAbandonedFieldsOverview();
      loadFieldReturnsOverview();
      loadFieldTimesOverview();
      loadTotalFieldReturns();

      // NB. Data for Field Abandons Over Time is only loaded when the abandoned fields have loaded and one been selected
      // Clear the data early to start the progress bar asap, and to ensure the PDF only receives the updated chart img
      setFieldAbandonsVisLoading(true);
      setFieldAbandonsVisData(null);
      setFieldAbandonsVisProgress(20);
      setFieldAbandonsChartImgUrl(null);
      setDataLoaded(false);
      loadAbandonedFields();
    }
  }, [loadAbandonedFieldsOverview, loadFieldReturnsOverview, loadAbandonedFields, loadFieldTimesOverview, loadTotalFieldReturns,
    prevQuery, query, granularityChangeFromChart]);

  // Compile fields overview table data
  useEffect(() => {
    if (!abandonedFieldsOverviewVisLoading && abandonedFieldsOverviewVisData &&
      !fieldReturnsOverviewLoading && fieldReturnsOverviewData &&
      !fieldTimesOverviewLoading && fieldTimesOverviewData
    ) {
      setFieldsOverviewTableProgress(100);

      const compiledData = Object.values(abandonedFieldsOverviewVisData.concat(fieldReturnsOverviewData).concat(fieldTimesOverviewData)
        .reduce((acc, item) => {
          const identifier = item.identifier;
          if (item.hidden) return acc;
          if (!acc[identifier]) acc[identifier] = {
            htmlTagName: item.htmlTagName,
            htmlType: item.htmlType,
            htmlName: item.htmlName,
            htmlId: item.htmlId,
            identifier: item.identifier,
            fieldOrder: item.fieldOrder,
            label: labelForField({...item, label: item.fieldLabel}),
          };

          if (item.hasOwnProperty('allSessionsTotal')) acc[identifier].allSessionsTotal = item.allSessionsTotal;
          if (item.hasOwnProperty('abandonRate')) acc[identifier].abandonRate = item.abandonRate;
          if (item.hasOwnProperty('percentOfAbandons')) acc[identifier].percentOfAbandons = item.percentOfAbandons;
          if (item.hasOwnProperty('allSessionsReturned')) {
            acc[identifier].allSessionsReturned = item.allSessionsReturned;
            acc[identifier].abandonedSessionsReturned = item.abandonedSessionsReturned;
            acc[identifier].completedSessionsReturned = item.completedSessionsReturned;
            acc[identifier].differenceInReturnRate = item.abandonedSessionsReturned ? item.abandonedSessionsReturned - item.completedSessionsReturned : null;
          }
          if (item.hasOwnProperty('significantDiff')) {
            acc[identifier].significantDiff = item.significantDiff;
            acc[identifier].zScore = item.zScore;
          }
          if (item.hasOwnProperty('allMeanRtf')) acc[identifier].allMeanRtf = item.allMeanRtf;
          if (item.hasOwnProperty('allMeanTif')) acc[identifier].allMeanTif = item.allMeanTif;

          return acc;
        }, {}));

      if (!compiledData.length) setFieldsOverviewTableError('No data in selected timeframe');
      if (compiledData.length) {
        setShowHighFieldCountMsg(compiledData.length >= 500);
        setFieldsOverviewTableData(sortFieldOrder(compiledData));
      }

      setFieldsOverviewTableTip({
        highestAbandonedField: compiledData.reduce((acc, item) => {
          if (!item.hasOwnProperty('percentOfAbandons')) return acc;
          return acc.percentOfAbandons > item.percentOfAbandons ? acc : item;
        }, {}),
      });
      setFieldsOverviewTableLoading(false);
    }
  }, [abandonedFieldsOverviewVisLoading, abandonedFieldsOverviewVisData,
    fieldReturnsOverviewLoading, fieldReturnsOverviewData,
    fieldTimesOverviewLoading, fieldTimesOverviewData,
  ]);

  // Handle fields overview table progress bar
  useEffect(() => {
    let interval;
    if (fieldsOverviewTableProgress < 100) {
      interval = setInterval(() => setFieldsOverviewTableProgress((prevProgress) => prevProgress + 20), 200);
    }
    return () => clearInterval(interval);
  }, [fieldsOverviewTableProgress]);

  // Reset fields overview table progress bar
  useEffect(() => {
    if (fieldsOverviewTableData) setFieldsOverviewTableProgress(0);
  }, [fieldsOverviewTableData]);

  // Select the Field Abandons field from the session
  useEffect(() => {
    const { fields } = getSession();
    if (fields?.abandonedField) setSelectedAbandonedField(fields.abandonedField);
  }, []);

  // Select Field Abandons field when fields have loaded
  useEffect(() => {
    if (prevAbandonedFieldsLoading && !abandonedFieldsLoading && availableAbandonedFields.length > 0 && !selectedAbandonedField) {
      setSelectedAbandonedField(availableAbandonedFields[0]);
    }
  }, [prevAbandonedFieldsLoading, abandonedFieldsLoading, availableAbandonedFields, selectedAbandonedField]);

  // When the form has changed - reset selected field
  useEffect(() => {
    if (prevForm?.uuid && form?.uuid && (prevForm.uuid !== form.uuid)) {
      setSelectedAbandonedField(null);
    }
  }, [form?.uuid, prevForm?.uuid]);

  // No abandoned fields, so reset the vis
  useEffect(() => {
    if (prevAbandonedFieldsLoading && !abandonedFieldsLoading && !availableAbandonedFields.length) {
      setFieldAbandonsVisProgress(0);
      setFieldAbandonsVisLoading(false);
      setFieldAbandonsVisError('No data to display');
      setFieldAbandonsVisData(null);
    }
  }, [prevAbandonedFieldsLoading, abandonedFieldsLoading, availableAbandonedFields]);

  // When fields change (loadAbandonedFields is called each time a new query is set) - load field abandons chart
  useEffect(() => {
    if (prevAbandonedFieldsLoading && availableAbandonedFields.length && selectedAbandonedField?.value && prevSelectedAbandonedField?.value &&
      (selectedAbandonedField.value === prevSelectedAbandonedField.value) && !fieldAbandonsData) loadFieldAbandons();
  }, [availableAbandonedFields, prevAbandonedFieldsLoading, loadFieldAbandons, selectedAbandonedField, prevSelectedAbandonedField, fieldAbandonsData, fieldAbandonsLoading]);

  // The first time a selected abandoned field is set (either set by the defualt or from the session) - load field abandons chart
  useEffect(() => {
    if (!prevSelectedAbandonedField && selectedAbandonedField?.value && formsGroupedByFormUuid) loadFieldAbandons();
  }, [prevSelectedAbandonedField, selectedAbandonedField, loadFieldAbandons, formsGroupedByFormUuid]);

  // When there was already a field selected and the field or granularity changes - load field abandons chart
  useEffect(() => {
    if (prevSelectedAbandonedField && selectedAbandonedField && (((prevSelectedAbandonedField.value !== selectedAbandonedField.value) && (prevGranularity === granularity)) ||
    ((prevGranularity !== granularity) && (prevSelectedAbandonedField.value === selectedAbandonedField.value)))) {
      loadFieldAbandons();
      setGranularityChangeFromChart(false);

      // Update session with the newly selected field
      const session = getSession();
      if (!session.hasOwnProperty('fields')) session.fields = {};
      session.fields.abandonedField = selectedAbandonedField;
      session.granularity = granularity;
      updateSession(session);
    }
  }, [prevSelectedAbandonedField, selectedAbandonedField, loadFieldAbandons, granularity, prevGranularity]);


  // Update selected Form on browser back/forward
  useEffect(() => {
    if (prevForm?.uuid && form?.uuid && (prevForm.uuid !== form.uuid) && selectedForm?.uuid &&  (selectedForm.uuid !== form.uuid)) {
      setSelectedForm(form);
    }
  },[prevForm, form, selectedForm]);

  // Update selected time on browser back/forward
  useEffect(() => {
    if (prevTime?.start && prevTime?.end && time?.start && time?.end &&
        (prevTime.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') !== time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') ||
        (prevTime.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') !== time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'))) &&
        selectedTime && (selectedTime.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') !== time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') ||
        (selectedTime.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') !== time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]')))) {

      setSelectedTime(time);
    }
  },[prevTime, time, selectedTime]);

  // Update selected filters on browser back/forward
  useEffect(() => {
    if (prevFilters && filters && (prevFilters !== filters) && selectedFilters &&  (selectedFilters !== filters)) {
      setSelectedFilters(filters);
    }
  },[prevFilters, filters, selectedFilters]);

  const fetchFilters = useCallback(async ({form, time}) => {
    try {
      setFiltersLoading(true);
      setFiltersLoadingError(false);
      const { data: { attributes } } = await api.get(`/data/sessions/attributes`, {
        params: {
          formUuid: form.uuid,
          time: {
            start: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
            end: time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          },
        },
      });
      setAvailableFilters(formatFiltersForSelectDropDown(attributes));
    } catch (e) {
      setFiltersLoadingError('Error when fetching attributes');
    } finally {
      setFiltersLoading(false);
    }
  },[]);

  // Initial load and first selections - fetch filters
  useEffect(() => {
    // First loaded page after login - so wait for default time to be set
    if ((!selectedForm?.uuid && !selectedTime?.start && prevForm?.uuid && form?.uuid && (prevForm.uuid === form.uuid)) && (!prevTime && time?.start)) fetchFilters({form, time});
    // Moved to page from another
    if ((!selectedForm?.uuid && !prevForm && form?.uuid) && (!selectedTime?.start && !prevTime && time?.start)) fetchFilters({form, time});

    // Form first selected
    if (selectedForm?.uuid && (!prevSelectedForm || (prevSelectedForm.uuid !== selectedForm.uuid)) && !selectedTime) fetchFilters({form: selectedForm, time});

    // Time first selected
    if (!selectedForm && selectedTime?.start && selectedTime?.end && (!prevSelectedTime ||
     (prevSelectedTime.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') !== selectedTime.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') ||
      prevSelectedTime.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') !== selectedTime.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'))
    )) fetchFilters({form, time: selectedTime});
  }, [selectedForm, prevSelectedForm, selectedTime, prevSelectedTime, form, time, fetchFilters, prevForm, prevTime]);

  // When the selected form or time changes - fetch filters
  useEffect(() => {
    if (selectedForm?.uuid && selectedTime?.start && selectedTime?.end) fetchFilters({form: selectedForm, time: selectedTime});
  }, [selectedForm, selectedTime, fetchFilters]);

  const observer = useMemo(() => new IntersectionObserver((entries) => {
    const entry = entries[0];
    if (entry.isIntersecting) {
      if (entry.target.innerText === 'Field Returns Overview') setHasScrolledToFieldReturnsOverview(true);
      if (entry.target.innerText === 'Field Times Overview') setHasScrolledToFieldTimesOverview(true);
    }
  }), []);

  useEffect(() => {
    if (fieldReturnsOverviewRef.current) observer.observe(fieldReturnsOverviewRef.current);
  }, [fieldReturnsOverviewRef, observer]);

  useEffect(() => {
    if (fieldTimesOverviewRef.current) observer.observe(fieldTimesOverviewRef.current);
  }, [fieldTimesOverviewRef, observer]);

  useEffect(() => {
    if (hasScrolledToFieldReturnsOverview) mixpanel.track('Scrolled', { page: 'FieldData', vis: 'Field Returns Overview', ...orgDetailsForMixpanel(form?.organisation)});
  }, [hasScrolledToFieldReturnsOverview, mixpanel, form]);

  useEffect(() => {
    if (hasScrolledToFieldTimesOverview) mixpanel.track('Scrolled', { page: 'FieldData', vis: 'Field Times Overview', ...orgDetailsForMixpanel(form?.organisation)});
  }, [hasScrolledToFieldTimesOverview, mixpanel, form]);

  const horizontalBarChartMaxPdfData = (data) => {
    return {
      datasets: data.datasets.map(d => ({...d, data: d.data.slice(0, maxLabels)})),
      labels: data.labels.slice(0, maxLabels),
    };
  };

  const totalFieldReturnsDataForPdf =  totalFieldReturnsData && horizontalBarChartMaxPdfData(totalFieldReturnsData);

  useEffect(() => {
    if(!currentUser.accountManager) setReportVisible(!!form?.organisation?.reportAccessEnabled);
  }, [currentUser.accountManager, form?.organisation?.reportAccessEnabled]);

  return (
    <Container fluid className="field-data page">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
        <title>Field Data</title>
      </Helmet>
      <div className="nav-wrapper browser-only">
        <NavBar mixpanel={mixpanel}/>
        <Row className="g-0 nav-primary">
          <Col className="col-md-auto col-sm-12 pt-0 pe-md-1 mt-1 d-inline-flex" id="datepicker">
            <DatePicker
              startTime={(selectedTime || time)?.start}
              endTime={(selectedTime || time)?.end}
              onApply={handleDateTimeRangeChange}
              timeZone={form?.organisation?.timeZone}
              selectedFormTimeZone={selectedForm?.organisation?.timeZone}
            />
          </Col>
          <Col md={3} className="pt-0 px-md-1 mt-1" id="form-select">
            <Select
              styles={{
                control: (styles, state) => ({...styles,
                  border: '1px solid #EBEDF2',
                }),
                option: (styles, state) => ({...styles,
                  color: '#3f4047',
                  backgroundColor: state.selectProps.value && (state.selectProps.value.uuid === state.value) ? "#E2E5Ec" : null,
                  '&:hover': {backgroundColor: state.isFocused ? '#F4F5F8' : null}
                }),
                menu: (styles, state) => ({...styles,
                  marginTop: '1px',
                  borderRadius: '4px',
                  border: '1px solid #EBEDF2',
                  boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
                }),
                dropdownIndicator: (styles, state) => ({...styles,
                  cursor: 'pointer',
                  transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
                  transition: 'transform .5s ease',
                }),
              }}
              options={formatFormSelectOptions({formsGroupedByOrg, currentForm: form, selectedForm, formsLoadingError, formsLoading})}
              formatGroupLabel={formatGroupLabel}
              onChange={handleFormChange}
              placeholder="Select a form..."
              value={selectedForm?.label ? selectedForm : form?.label ? form : null}
              filterOption={formsGroupedByOrg && Object.values(formsGroupedByOrg).length && formFilterOption}
              isOptionDisabled={option => option.hasOwnProperty('selectable') && !option.selectable}
            />
          </Col>
          <Col className="pt-0 ps-md-1 mt-1">
            <FiltersSelect
              form={form}
              filters={filters}
              selectedFilters={selectedFilters}
              filtersLoading={filtersLoading}
              availableFilters={availableFilters}
              filtersLoadingError={filtersLoadingError}
              handleFiltersChange={handleFiltersChange} />
          </Col>
        </Row>
        <Row className="g-0 nav-secondary justify-content-end">
          <Col className="pt-1 pb-2 col-auto">
            <Button variant="outline-secondary" className="cancel me-1 ms-0" disabled={!cancelButtonEnabled}
              onClick={resetQuery} data-testid="cancel-field-data-query">Cancel</Button>
            <Button className="load ms-1 me-0" disabled={!applyButtonEnabled} onClick={handleApply}>Apply</Button>
          </Col>
        </Row>
      </div>
      <ScrollToTop />
      <div className="main-content">
        {(formsLoading || formsLoadingError || !formsGroupedByOrg || Forms.length > 0) ?
          <Col className="center-column justify-content-md-center">
            <div className="pb-1">
              <FeedbackRow
                classList={['allow-scroll-under-nav']}
                mixpanel={mixpanel}
                page={'FieldData'}
                org={form?.organisation}
                messageContent={'Field Data'} />
            </div>
            <AppAlerts showOrgAlerts={true} />
            <Row className="title-row g-0 browser-only">
              {orgName && form?.label && <>
                <Col className="p-0">
                  <h1 id="form-title" data-testid="page-title">
                    {`${orgName} | ${form?.label} | `}
                    <a href={form.url} target="_blank" rel="noopener noreferrer">{form.url}</a>
                  </h1>
                </Col>
                <Col className="p-0 text-end my-auto ms-auto col-auto">
                  {exportError && <p className="mb-0 pe-2 error-text d-inline-block">Error exporting data.</p>}
                  {reportVisible && <span className="icon-overlay-container">
                    {!pdfRequested ? <>
                      <GrDocumentPdf size="20px" title="Export to PDF" className="pdf-export-icon data-loaded"
                        onClick={() => {setPdfRequested(true); mixpanel.track('Clicked to export', { page: 'FieldData', exportType: 'PDF', ...orgDetailsForMixpanel(form?.organisation) });}}/> </> :
                      pdfRequested ? <>
                        <GrDocumentPdf size="20px" title="Export to PDF" className="pdf-export-icon generating"/>
                        <FaSpinner size="18px" className="spinning-icon" title="Generating PDF..."/> </> : null
                    }
                  </span>}
                  {form?.uuid && time?.start && time?.end && reportVisible &&
                    <span className="icon-overlay-container">
                      {!csvRequested ?
                        <GrDocumentCsv size="20px" title="Export to CSV" className="csv-export-icon data-loaded"
                          onClick={() => {handleCsvDownload(); setCsvRequested(true); mixpanel.track('Clicked to export', { page: 'FieldData', exportType: 'CSV', ...orgDetailsForMixpanel(form?.organisation) });}}
                        /> :
                        csvRequested ? <>
                          <GrDocumentCsv size="20px" title="Export to CSV" className="csv-export-icon generating"/>
                          <FaSpinner size="18px" className="spinning-icon" title="Generating CSV..."/> </>: null
                      }
                    </span>}
                  <CopyUrlIcon
                    queryString={compileQueryString({form, time, granularity, filters})}/>
                  <Link to={`/forms/${formUuid}/fields`} style={{marginLeft: 5, marginRight: 10}}>Label & Order Fields</Link>
                  <Link to={`/forms/${formUuid}/edit`}><FaCog size="20px" className="grey-icon" title="Form settings"/></Link>
                </Col> </>
              }
            </Row>
            {showHighFieldCountMsg === true &&
              <Row className="alert-row g-0 info-alert">
                <Alert variant="info" className="mb-3">
                  <div className="page-alert-svg-icon d-flex"><VscInfo size="100%"/></div>
                  <p className="alert-text m-0">You have reached a maximum number of fields that can be displayed. If your form contains <OverlayTrigger placement="top" overlay={<Popover>
                    <Popover.Body>
                      A field is dynamic when it appears multiple times in the data. It will be a single field in the form, but a HTML attribute may change for each visitor. <br></br><br></br>
                      Fields like this can be merged so that it can be treated as a single field.
                    </Popover.Body>
                  </Popover>}>
                    <u className="text-more-info">dynamic fields</u>
                  </OverlayTrigger>, you can merge them in <Link to={`/forms/${formUuid}/fields`}>Label & Order Fields</Link>, or speak to <a href="mailto:support@zuko.io">Zuko support</a>.</p>
                </Alert>
              </Row>}
            <Col className={`chart-content p-0 ${reportVisible ? '' : 'blurred-report'}`}>
              <PrintPageHeader
                pageTitle={'Field Data'}
                orgName={orgName}
                formLabel={form?.label}
                formUrl={form?.url}
                startTime={time?.start}
                endTime={time?.end}
                timeZone={form?.organisation?.timeZone}
                formFilters={filters}
                searchParams={form?.uuid && time?.start && time?.end && granularity && compileQueryString({form, time, granularity, filters})}
              />
              <div className="flip-card">
                <Card id="fields-overview" className={`flip-card-inner ${(showFieldsOverviewTableInfo === true) ? 'perform-flip' : ''}`}>
                  <Card.Body className="p-0 d-flex">
                    <div className={`flip-card-front d-flex flex-column flex-grow-1 ${showFieldsOverviewTableInfo === null ? '' : showFieldsOverviewTableInfo === true ? 'not-visble' : showFieldsOverviewTableInfo === false ? 'visible' : ''}`}>
                      <Row className="g-0 card-title-row">
                        <Col className="p-0">
                          <Card.Title as="h3">Fields Overview</Card.Title>
                        </Col>
                        <Col className="p-0 text-end card-tooltip">
                          <ChartTip
                            dataLoading={fieldsOverviewTableLoading}
                            isData={fieldsOverviewTableData?.length > 0}
                            mixpanel={mixpanel}
                            organisation={form?.organisation}
                            page={'FieldData'}
                            vis={'Fields Overview'}>
                            {fieldsOverviewTableTip?.highestAbandonedField?.label && <>
                              <p>In this data, the biggest point of abandonment on your form is the field labelled:</p>
                              <p className="tip-field">{fieldsOverviewTableTip?.highestAbandonedField?.label}</p>
                              <p>This represents <span className="tip-stat">{fieldsOverviewTableTip?.highestAbandonedField?.percentOfAbandons.toLocaleString(undefined, {maximumFractionDigits: 2}) + '%'}</span> of your form's
                              total abandons.</p>
                              <p className="mb-0 pt-2">
                                <Link to={`/field-flow${(form && time) ? compileQueryString({form, time, filters, sessionOutcomes: [{value: 'abandoned'}], fieldFlow: {
                                  flowFieldIdentifier: fieldsOverviewTableTip?.highestAbandonedField?.identifier,
                                }}) : ''}`} target="_blank" onClick={() => {mixpanel.track('Clicked Quick Tip Link', { page: 'FieldData', vis: 'Fields Overview', ...orgDetailsForMixpanel(form?.organisation) });}}>Go to the <i>Field Flow</i> report</Link> to find out what visitors in abandoned sessions are doing after this field.</p>
                            </>}
                            {!fieldsOverviewTableTip?.highestAbandonedField?.label &&
                              <p className="mb-0">This tip looks at abandoned fields, but there are no abandons in your data.</p>}
                          </ChartTip>
                          <FaInfoCircle id="first-info-icon" size="20px" className="info-circle-icon browser-only" onClick={() => {setShowFieldsOverviewTableInfo(true); mixpanel.track('Clicked Fields Overview Table info', { page: 'FieldData', ...orgDetailsForMixpanel(form?.organisation) });}} title="How to use"/>
                        </Col>
                      </Row>
                      {fieldsOverviewTableData ?
                        <div className="card-vis" data-testid="fields-overview-table">
                          <SummaryTable
                            data={fieldsOverviewTableData}
                            showSessionReplayColumns={true}
                            replayEnabled={form?.organisation?.replayEnabled}
                            setShowSessionReplayCTAModal={setShowSessionReplayCTAModal}
                            query={query}
                          />
                        </div>
                        : fieldsOverviewTableError ?
                          <div className="d-flex justify-content-center flex-grow-1">
                            <p className="text-center my-auto" data-testid="fields-overview-table-error">{fieldsOverviewTableError}</p>
                          </div>
                          : fieldsOverviewTableLoading &&
                            <div className="progress-area d-flex flex-grow-1">
                              <div className="w-100">
                                <ProgressBar className="my-auto" animated now={fieldsOverviewTableProgress}/>
                                <p>{fieldsOverviewTableProgress >= 100 && 'The data is being computed, please wait...'}</p>
                              </div>
                            </div>
                      }
                    </div>
                    <div className={`flip-card-back ${showFieldsOverviewTableInfo === true ? 'visible' : showFieldsOverviewTableInfo === false ? 'not-visble' : ''}`}>
                      <div className="card-contents">
                        <Row className="g-0 card-title-row">
                          <Col className="p-0">
                            <Card.Title as="h3">Fields Overview</Card.Title>
                          </Col>
                          <Col className="p-0 text-end card-tooltip">
                            <VscChromeClose size="20px" className="grey-icon" onClick={() => setShowFieldsOverviewTableInfo(false)} title="Return to chart"/>
                          </Col>
                        </Row>
                        <Row className="g-0 text-content">
                          <Col lg={7} className="ps-0">
                            <Card.Text className="mb-3 subtitle">This table gives you a high-level overview of how your fields are performing relatively. It displays a combination of total session count, abandon data, time spent and field returns that each field comprises.</Card.Text>
                            <dl>
                              <dt>Sessions Interacted</dt>
                              <dd>A count of sessions where the field was interacted with at least once.</dd>
                              <dt>Abandon Rate</dt>
                              <dd>The rate at which an abandonment occurs on this field. This is calculated using the abandon count for this field compared to the amount of sessions
                                that interacted with it.</dd>
                              <dt>Percent of Total Abandons</dt>
                              <dd>The percentage of abandons on each field out of the total abandons on the form.</dd>
                              <dt>Return Rate</dt>
                              <dd>The proportion of visitors who came back to a field at least once.</dd>
                              <dt>Mean Returns</dt>
                              <dd>The average (mean) amount of returns to each field.</dd>
                              <dt>Mean Time Spent</dt>
                              <dd>The average (mean) time that visitors spend interacting with each field. This metric is in seconds.</dd>
                              <dt>Behaviour Difference</dt>
                              <dd>This is the difference in return rate percentage between visitors who abandon the form and those who complete it. A
                                positive number means that abandoners return to make corrections at a higher rate than those who successfully complete
                                (indicating that there is a user issue that is probably related to abandonment). If the number is negative then the
                                reverse situation is true.</dd>
                            </dl>
                            <p className="mb-0">Zuko will highlight when this number is statistically significant:</p>
                            <dl className="d-flex mb-0">
                              <dt className="d-inline"><span className="abandoned-colour-bar"></span></dt>
                              <dd className="mb-0">for a positive number, i.e. more abandoned sessions returned</dd>
                            </dl>
                            <dl className="d-flex mb-0">
                              <dt className="d-inline"><span className="completed-colour-bar"></span></dt>
                              <dd>for a negative number, i.e. more completed sessions returned</dd>
                            </dl>
                            <Card.Text>You can sort any column by the highest values and compare with other datapoints.</Card.Text>
                          </Col>
                          <Col lg={5} className="py-0">
                            <div className="card-tip-box">
                              <h4 className="pb-3">How to use this and what to look for</h4>
                              <Card.Text>First you can see which fields are most commonly interacted with, this gives you a feel for how visitors progress through the form.
                                Then sort on <i>% of Total Abandons</i> to review which fields are causing visitors to abandon. The higher the level, alongside the rate and return
                                 rate can highlight the fields causing the most friction.</Card.Text>
                              <Card.Text>In order to come to firm conclusions you should also look at the more detailed breakdowns in the charts below.</Card.Text>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <div className="flip-card">
                <Card id="abandoned-fields-overview" className={`flip-card-inner ${(showAbandonedFieldsOverviewVisInfo === true) ? 'perform-flip' : ''}`}>
                  <Card.Body className="p-0 d-flex">
                    <div className={`flip-card-front d-flex flex-column flex-grow-1 ${showAbandonedFieldsOverviewVisInfo === null ? '' : showAbandonedFieldsOverviewVisInfo === true ? 'not-visble' : showAbandonedFieldsOverviewVisInfo === false ? 'visible' : ''}`}>
                      <Row className="g-0 card-title-row">
                        <Col className="p-0">
                          <Card.Title as="h3">Abandoned Fields Overview</Card.Title>
                        </Col>
                        <Col className="p-0 text-end card-tooltip">
                          <FaInfoCircle size="20px" className="info-circle-icon browser-only" onClick={() => {setShowAbandonedFieldsOverviewVisInfo(true); mixpanel.track('Clicked Abandoned Overview info', { page: 'FieldData', ...orgDetailsForMixpanel(form?.organisation) });}} title="How to use"/>
                        </Col>
                      </Row>
                      {abandonedFieldsOverviewVisData?.length > 0 ?
                        <div className="card-vis">
                          <DataTable columns={abandonedFieldsOverviewVisColumns} data={abandonedFieldsOverviewVisData} type={"fieldData"} testId={"abandoned-fields-overview-table"}/>
                        </div>
                        : abandonedFieldsOverviewVisError ?
                          <div className="d-flex justify-content-center flex-grow-1">
                            <p className="text-center my-auto"  data-testid="abandoned-fields-overview-table-error">{abandonedFieldsOverviewVisError}</p>
                          </div>
                          : abandonedFieldsOverviewVisLoading &&
                            <div className="progress-area d-flex flex-grow-1">
                              <div className="w-100">
                                <ProgressBar className="my-auto" animated now={abandonedFieldsOverviewVisProgress}/>
                                <p>{abandonedFieldsOverviewVisProgress >= 110 && 'The data is being computed, please wait...'}</p>
                              </div>
                            </div>
                      }
                    </div>
                    <div className={`flip-card-back ${showAbandonedFieldsOverviewVisInfo === true ? 'visible' : showAbandonedFieldsOverviewVisInfo === false ? 'not-visble' : ''}`}>
                      <div className="card-contents">
                        <Row className="g-0 card-title-row">
                          <Col className="p-0">
                            <Card.Title as="h3">Abandoned Fields Overview</Card.Title>
                          </Col>
                          <Col className="p-0 text-end card-tooltip">
                            <VscChromeClose size="20px" className="grey-icon" onClick={() => setShowAbandonedFieldsOverviewVisInfo(false)} title="Return to graph"/>
                          </Col>
                        </Row>
                        <Row className="g-0 text-content">
                          <Col lg={7} className="ps-0">
                            <Card.Text className="mb-3 subtitle">This table shows the fields where visitors abandon, how many times visitors have abandoned on that field, the percentage of total abandons that field makes up and the rate at which visitors abandon on the field.</Card.Text>
                            <Card.Text>A field is counted as the abandoned field if it was the last input a visitor interacted with before leaving your form.</Card.Text>
                            <Card.Text>You can also choose to order the rows by any of the metrics: <i>Abandon Count</i>, <i>% of Total Abandons</i>, <i>Abandon Rate</i>. For example, ordering on <i>Abandon Count</i> will show you the most abandoned fields first. The default is to show them according to the
                            custom <i>Order</i> you have set in the <Link to={`/forms/${formUuid}/fields`}>Field Labelling</Link> settings.</Card.Text>
                            <figure className="text-center img-wrapper">
                              <img id="Abandoned-Fields-Img" src={AbandonedFieldsImg} alt="Abandoned-Fields-Table" className="card-info-img"></img>
                            </figure>
                          </Col>
                          <Col lg={5} className="py-0">
                            <div className="card-tip-box">
                              <h4 className="pb-3">How to use this and what to look for</h4>
                              <Card.Text>Use this data to see which fields have a high abandonment volume / rate. The higher these figures are, the more likely a field is causing problems for visitors and potentially driving them to drop out.</Card.Text>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <div className="flip-card">
                <Card id="field-abandons-over-time" className={`flip-card-inner ${(showFieldAbandonsVisInfo === true) ? 'perform-flip' : ''}`}>
                  <Card.Body className="p-0 d-flex">
                    <div className={`flip-card-front d-flex flex-column flex-grow-1 ${showFieldAbandonsVisInfo === null ? '' : showFieldAbandonsVisInfo === true ? 'not-visble' : showFieldAbandonsVisInfo === false ? 'visible' : ''}`}>
                      <Row className="g-0 card-title-row">
                        <Col className="p-0">
                          <Card.Title as="h3">Field Abandons Over Time</Card.Title>
                        </Col>
                        <Col className="p-0 text-end card-tooltip">
                          <FaInfoCircle size="20px" className="info-circle-icon browser-only" onClick={() => {setShowFieldAbandonsVisInfo(true); mixpanel.track('Clicked Field Abandons info', { page: 'FieldData', ...orgDetailsForMixpanel(form?.organisation) });}} title="How to use"/>
                        </Col>
                      </Row>
                      <Row className="g-0 field-select justify-content-between">
                        <Col className="p-0 d-flex align-items-center">
                          <div className="granularity-buttons">
                            <fieldset>
                              <FormGroup className="form-group d-flex mb-0" controlId="granularity">
                                <Form.Label className="form-check-inline align-middle">Granularity: </Form.Label>
                                {acceptedGranularity.map((item) => (
                                  <Form.Check inline type="radio" id={item} label={item} value={item} data-testid={`${item}-radio`} checked={item === granularity}
                                    onChange={handleGranularityChange} key={item}/>
                                ))}
                              </FormGroup>
                            </fieldset>
                          </div>
                        </Col>
                        <Col className="p-0 col-auto" id="abandon-field-select">
                          <Select
                            styles={{
                              option: (styles, state) => ({...styles,
                                color: '#3f4047',
                                backgroundColor: state.selectProps.value && (state.selectProps.value.value === state.value) ? "#E2E5EC" : null,
                                '&:hover': {backgroundColor: state.isFocused ? '#F4F5F8' : null}
                              }),
                              menu: (styles, state) => ({...styles,
                                marginTop: '1px',
                                borderRadius: '4px',
                                boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
                              }),
                              dropdownIndicator: (styles, state) => ({...styles,
                                cursor: 'pointer',
                                transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
                                transition: 'transform .5s ease',
                              }),
                            }}
                            options={availableAbandonedFields}
                            onChange={handleAbandonedFieldChange}
                            placeholder="Select a field..."
                            value={selectedAbandonedField}
                          />
                        </Col>
                      </Row>
                      {fieldAbandonsData ?
                        <div className="card-vis">
                          <div data-testid="field-abandons-chart-wrapper">
                            <ReactChart
                              id="field-abandons-chart"
                              type="bar"
                              data={fieldAbandonsData}
                              width={1216}
                              height={350}
                              options={{
                                maintainAspectRatio: false,
                                indexAxis: 'x',
                                grid: {
                                  offset: false,
                                },
                                scales: {
                                  'first-y-axis': {
                                    title: {
                                      text: 'Abandon rate for field',
                                      display: true,
                                    },
                                    type: 'linear',
                                    beginAtZero: false,
                                    position: 'left',
                                    ticks: {
                                      callback: (value) => value.toLocaleString(undefined, {maximumFractionDigits: 2}) + '%',
                                    },
                                  },
                                  'second-y-axis': {
                                    title: {
                                      text: 'Abandon count for field',
                                      display: true,
                                    },
                                    beginAtZero: true,
                                    position: 'right',
                                    ticks: {
                                      callback: (value) => value.toLocaleString(),
                                    },
                                    grid: {
                                      display: false,
                                    },
                                  },
                                  x: {
                                    type: 'timeseries',
                                    time: {
                                      unit: granularity,
                                      displayFormats: {
                                        hour: fieldAbandonsData.labels.length > 48 ? 'MMM DD' : 'HH[h]',
                                        day: 'MMM DD',
                                        week: 'MMM DD',
                                        month: 'MMM YYYY',
                                      },
                                      isoWeekday: true,
                                      tooltipFormat: granularity === 'hour' ? 'MMM D, HH:mm' : granularity === 'month' ?
                                        'MMM YYYY' : 'MMM DD'
                                    },
                                  },
                                },
                                elements: {
                                  line: {
                                    tension: 0.05
                                  }
                                },
                                plugins: {
                                  legend: {
                                    labels: {
                                      boxWidth: 20
                                    }
                                  },
                                  tooltip: {
                                    position: 'nearest',
                                  },
                                },
                              }}
                            />
                          </div>
                        </div>
                        : fieldAbandonsError ?
                          <div className="d-flex justify-content-center flex-grow-1">
                            <p className="text-center my-auto" data-testid="field-abandons-error">{fieldAbandonsError}</p>
                          </div>
                          : fieldAbandonsLoading &&
                            <div className="progress-area d-flex flex-grow-1">
                              <div className="w-100">
                                <ProgressBar className="my-auto" animated now={fieldAbandonsProgress}/>
                                <p>{fieldAbandonsProgress >= 110 && 'The data is being computed, please wait...'}</p>
                              </div>
                            </div>
                      }
                    </div>
                    <div className={`flip-card-back ${showFieldAbandonsVisInfo === true ? 'visible' : showFieldAbandonsVisInfo === false ? 'not-visble' : ''}`}>
                      <div className="card-contents">
                        <Row className="g-0 card-title-row">
                          <Col className="p-0">
                            <Card.Title as="h3">Field Abandons Over Time</Card.Title>
                          </Col>
                          <Col className="p-0 text-end card-tooltip">
                            <VscChromeClose size="20px" className="grey-icon" onClick={() => setShowFieldAbandonsVisInfo(false)} title="Return to graph"/>
                          </Col>
                        </Row>
                        <Row className="g-0 text-content">
                          <Col lg={7} className="ps-0">
                            <Card.Text className="mb-3 subtitle">This graph plots the field <i>Abandon Count</i> and <i>% Rate</i> together over time for any chosen field.</Card.Text>
                            <Card.Text>Simply select the field you’d like to see from the dropdown at the top right of the graph. You can group data by <i>Hour</i>, <i>Day</i> (default), <i>Week</i> or <i>Month</i> using
                            the granularity options at the top left of the graph.</Card.Text>
                            <Card.Text>You can choose to view each metric in isolation by excluding the other by clicking on the legend. This is toggleable.</Card.Text>
                            <figure className="text-center img-wrapper">
                              <img id="Field-Abandons-Img" src={FieldAbandonsImg} alt="Field-Abandons-chart" className="card-info-img"></img>
                            </figure>
                          </Col>
                          <Col lg={5} className="py-0">
                            <div className="card-tip-box">
                              <h4 className="pb-3">How to use this and what to look for</h4>
                              <Card.Text>This graph is useful to see how the number of people abandoning a field changes over time.</Card.Text>
                              <Card.Text>For instance, if you make a change to a field's functionality, instructional copy or error message, you can see if this has had a positive or negative effect on abandonment rates.</Card.Text>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <div className="flip-card">
                <Card id="total-field-returns" className={`flip-card-inner ${(showTotalFieldReturnsVisInfo === true) ? 'perform-flip' : ''}`}>
                  <Card.Body className="p-0 d-flex">
                    <div className={`flip-card-front d-flex flex-column flex-grow-1 ${showTotalFieldReturnsVisInfo === null ? '' : showTotalFieldReturnsVisInfo === true ? 'not-visble' : showTotalFieldReturnsVisInfo === false ? 'visible' : ''}`}>
                      <Row className="g-0 card-title-row">
                        <Col className="p-0">
                          <Card.Title as="h3">Total Field Returns</Card.Title>
                        </Col>
                        <Col className="p-0 text-end card-tooltip">
                          <FaInfoCircle size="20px" className="info-circle-icon browser-only" onClick={() => {setShowTotalFieldReturnsVisInfo(true); mixpanel.track('Clicked Total Returns info', { page: 'FieldData', ...orgDetailsForMixpanel(form?.organisation) });}} title="How to use"/>
                        </Col>
                      </Row>
                      {totalFieldReturnsData ?
                        <div className="card-vis">
                          <div className="scrollable-chart-wrapper" data-testid="total-field-returns-chart-wrapper">
                            <div>
                              <ReactChart
                                id="total-field-returns-chart"
                                type="bar"
                                data={totalFieldReturnsData}
                                width={1216}
                                height={totalFieldReturnsData.labels.length * 22 > 350 ?
                                  totalFieldReturnsData.labels.length * 22 : 350}
                                options={{
                                  maintainAspectRatio: false,
                                  indexAxis: 'y',
                                  scales: {
                                    y: {
                                      title: {
                                        text: 'Fields',
                                        display: true,
                                      },
                                      type: 'category',
                                      stacked: true,
                                      ticks: {
                                        callback: (value, index, values) => {
                                          const label = totalFieldReturnsData.labels[index];
                                          return (label.length > 60 ? label.substring(0, 57) + '...' : label);
                                        },
                                      },
                                    },
                                    x: {
                                      title: {
                                        text: 'Count',
                                        display: true,
                                      },
                                      type: 'linear',
                                      stacked: true,
                                    },
                                  },
                                  plugins: {
                                    legend: {
                                      labels: {
                                        boxWidth: 20
                                      }
                                    },
                                    tooltip: {
                                      callbacks: {
                                        afterBody: (items) => {
                                          const total = items.reduce((acc, item) => (acc + item.parsed.x), 0);
                                          return 'Total: ' + total.toLocaleString();
                                        }
                                      }
                                    },
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        : totalFieldReturnsError ?
                          <div className="d-flex justify-content-center flex-grow-1">
                            <p className="text-center my-auto" data-testid="total-field-returns-error">{totalFieldReturnsError}</p>
                          </div>
                          : totalFieldReturnsLoading &&
                            <div className="progress-area d-flex flex-grow-1">
                              <div className="w-100">
                                <ProgressBar className="my-auto" animated now={totalFieldReturnsProgress}/>
                                <p>{totalFieldReturnsProgress >= 110 && 'The data is being computed, please wait...'}</p>
                              </div>
                            </div>
                      }
                    </div>
                    <div className={`flip-card-back ${showTotalFieldReturnsVisInfo === true ? 'visible' : showTotalFieldReturnsVisInfo === false ? 'not-visble' : ''}`}>
                      <div className="card-contents">
                        <Row className="g-0 card-title-row">
                          <Col className="p-0">
                            <Card.Title as="h3">Total Field Returns</Card.Title>
                          </Col>
                          <Col className="p-0 text-end card-tooltip">
                            <VscChromeClose size="20px" className="grey-icon" onClick={() => setShowTotalFieldReturnsVisInfo(false)} title="Return to chart"/>
                          </Col>
                        </Row>
                        <Row className="g-0 text-content">
                          <Col lg={7} className="ps-0">
                            <Card.Text className="mb-3 subtitle">This chart shows how many times visitors return to the fields in your form (after their first interaction)</Card.Text>
                            <Card.Text>The total field returns to a field are split by those that took place in sessions that were ultimately <i>Abandoned</i> and those from sessions that were <i>Completed</i>.</Card.Text>
                            <Card.Text>Note:</Card.Text>
                            <ul>
                              <li>These figures are aggregated - if a visitor returns to a field multiple times, each instance will be counted.</li>
                              <li>The figures indicate that a visitor ultimately <i>Completed</i> or <i>Abandoned</i>, NOT that they abandoned on that field.</li>
                            </ul>
                            <figure className="text-center img-wrapper">
                              <img id="Field-Returns-Img" src={FieldReturnsImg} alt="Field-Returns-chart" className="card-info-img"></img>
                            </figure>
                          </Col>
                          <Col lg={5} className="py-0">
                            <div className="card-tip-box">
                              <h4 className="pb-3">How to use this and what to look for</h4>
                              <Card.Text>Use this chart to quickly see which fields get the most returns and if they are linked to abandoned sessions. This can be an indicator of visitor frustration with a particular
                                field that leads to abandonment.</Card.Text>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <div className="flip-card">
                <Card id="field-returns-overview" className={`flip-card-inner ${(showFieldReturnsOverviewVisInfo=== true) ? 'perform-flip' : ''}`}>
                  <Card.Body className="p-0 d-flex">
                    <div className={`flip-card-front d-flex flex-column flex-grow-1 ${showFieldReturnsOverviewVisInfo === null ? '' : showFieldReturnsOverviewVisInfo === true ? 'not-visble' : showFieldReturnsOverviewVisInfo === false ? 'visible' : ''}`}>
                      <Row className="g-0 card-title-row">
                        <Col className="p-0">
                          <Card.Title as="h3" ref={fieldReturnsOverviewRef}>Field Returns Overview</Card.Title>
                        </Col>
                        <Col className="p-0 text-end card-tooltip">
                          <FaInfoCircle size="20px" className="info-circle-icon browser-only" onClick={() => {setShowFieldReturnsOverviewVisInfo(true); mixpanel.track('Clicked Returns Overview info', { page: 'FieldData', ...orgDetailsForMixpanel(form?.organisation) });}} title="How to use"/>
                        </Col>
                      </Row>
                      {fieldReturnsOverviewData && fieldReturnsOverviewData.some(r => r.significantDiff) && <Row className="g-0">
                        <p className="mb-0">The report highlights where there is a statistically significant difference in the proportion of visitors returning to a field between those who complete the form and those who ultimately abandon it (a large difference may indicate a problem field).</p>
                      </Row>}
                      {fieldReturnsOverviewData?.length > 0 ?
                        <div className="card-vis">
                          <DataTable columns={fieldReturnsOverviewColumns} data={fieldReturnsOverviewData} type={"fieldData"} testId={"field-returns-overview-table"}/>
                        </div>
                        : fieldReturnsOverviewError ?
                          <div className="d-flex justify-content-center flex-grow-1">
                            <p className="text-center my-auto" data-testid="field-returns-error">{fieldReturnsOverviewError}</p>
                          </div>
                          : fieldReturnsOverviewLoading &&
                            <div className="progress-area d-flex flex-grow-1">
                              <div className="w-100">
                                <ProgressBar className="my-auto" animated now={fieldReturnsOverviewProgress}/>
                                <p>{fieldReturnsOverviewProgress >= 110 && 'The data is being computed, please wait...'}</p>
                              </div>
                            </div>
                      }
                    </div>
                    <div className={`flip-card-back ${showFieldReturnsOverviewVisInfo === true ? 'visible' : showFieldReturnsOverviewVisInfo === false ? 'not-visble' : ''}`}>
                      <div className="card-contents">
                        <Row className="g-0 card-title-row">
                          <Col className="p-0">
                            <Card.Title as="h3">Field Returns Overview</Card.Title>
                          </Col>
                          <Col className="p-0 text-end card-tooltip">
                            <VscChromeClose size="20px" className="grey-icon" onClick={() => setShowFieldReturnsOverviewVisInfo(false)} title="Return to table"/>
                          </Col>
                        </Row>
                        <Row className="g-0 text-content">
                          <Col lg={6} className="ps-0">
                            <Card.Text className="mb-3 subtitle">This table gives the raw field return data for your form.</Card.Text>
                            <Card.Text>You can see the number of sessions that interacted with specific fields, the percentage of those sessions that returned to that field and then the average number
                              of returns to that field. These metrics are then split out into <i>Abandoned</i> and <i>Completed</i> sessions.</Card.Text>
                            <Card.Text>Working from left to right, you can read this table in the following way:</Card.Text>
                            <dl>
                              <dt>Number of Sessions Interacted</dt>
                              <dd className="mb-0">How many visitors interacted with this field at some point in their session?</dd>
                              <dd>Of these, how many of those sessions successfully <i>Completed</i> the form and how many <i>Abandoned</i>?</dd>
                              <dt>% Sessions Returned</dt>
                              <dd className="mb-0">Of those that interacted with this field, what proportion had to come back at least once?</dd>
                              <dd>How does that differ for <i>Abandoned</i> and <i>Completed</i> sessions?</dd>
                              <dt>Field Returns</dt>
                              <dd className="mb-0">For those that came back to the field, how many times did they come back on average?</dd>
                              <dd>What is the breakdown for <i>Abandoned</i> and <i>Completed</i> segments?</dd>
                            </dl>
                            <Card.Text className="mb-0">Note:</Card.Text>
                            <Card.Text>You may find that certain fields have a much lower number of sessions that interact with them than you might expect. This is often because abandoned sessions don’t
                              get that far through the form and therefore never interact with those fields. It may also be because they are optional fields, or that signed in visitors do not have to interact
                              with them at all.</Card.Text>
                            <h4>Problem fields</h4>
                            <Card.Text>The report automatically highlights fields in red where the proportion of visitors in abandoned sessions who return to the field is higher than the proportion of visitors
                              who return in completed sessions at a statistically significant level. This significance is calculated to a 95% confidence level.</Card.Text>
                            <Card.Text>Where the reverse situation is true (completed sessions return significantly more than abandoned sessions), the field is highlighted blue.
                            </Card.Text>
                            <Card.Text>If the difference between the two figures is not statistically significant there is no highlighting.</Card.Text>
                          </Col>
                          <Col lg={6} className="py-0">
                            <div className="card-tip-box">
                              <h4 className="pb-3">How to use this and what to look for</h4>
                              <Card.Text>This data is useful to identify which fields visitors are having to return to.</Card.Text>
                              <Card.Text>This may be an indication of visitor frustration but not necessarily so. Some fields may naturally have high return rates (think personal statements on university
                                applications where applicants come back to hone their answer).</Card.Text>
                              <Card.Text>The greatest insight from it often comes when looking at the difference between the <i>Abandoned</i> and <i>Completed</i> figures. If the field return figure is significantly
                                greater for <i>Abandoned</i> sessions than <i>Completed</i> sessions, this is a clear indication that the field is disproportionately causing visitor struggles that ultimately lead to abandonment (when this occurs,
                                the report highlights the field in red).</Card.Text>
                              <figure className="text-center img-wrapper">
                                <img id="Field-Returns-Significant-Diff-Img" src={FieldReturnsOverviewSignificantDiffImg} alt="Field-Returns-Significant-Diff" className="card-info-img"></img>
                              </figure>
                              <Card.Text>If the reverse situation is true (<i>Completed</i> sessions are returning to the field at a greater rate than <i>Abandoned</i> sessions - the report will highlight the field in blue) this is still an
                              indication that the field has its issues (potentially linked to the required format of the answer or the clarity of explanation / error message) but that there is a subset of visitors sufficiently motivated to get
                              past that and make the necessary corrections before pressing on to complete the form.</Card.Text>
                              <figure className="text-center img-wrapper">
                                <img id="Field-Returns-Significant-Diff-Img" src={FieldReturnsOverviewSignificantDiffCompletedImg} alt="Field-Returns-Significant-Diff-Completed" className="card-info-img"></img>
                              </figure>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <div className="flip-card">
                <Card id="field-times-overview" className={`flip-card-inner ${(showFieldTimesOverviewVisInfo === true) ? 'perform-flip' : ''}`}>
                  <Card.Body className="p-0 d-flex">
                    <div className={`flip-card-front d-flex flex-column flex-grow-1 ${showFieldTimesOverviewVisInfo === null ? '' : showFieldTimesOverviewVisInfo === true ? 'not-visble' : showFieldTimesOverviewVisInfo === false ? 'visible' : ''}`}>
                      <Row className="g-0 card-title-row">
                        <Col className="p-0">
                          <Card.Title as="h3" ref={fieldTimesOverviewRef}>Field Times Overview</Card.Title>
                        </Col>
                        <Col className="p-0 text-end card-tooltip">
                          <FaInfoCircle size="20px" className="info-circle-icon browser-only" onClick={() => {setShowFieldTimesOverviewVisInfo(true); mixpanel.track('Clicked Times Overview info', { page: 'FieldData', ...orgDetailsForMixpanel(form?.organisation) });}} title="How to use"/>
                        </Col>
                      </Row>
                      {fieldTimesOverviewData?.length > 0 ?
                        <div className="card-vis">
                          <DataTable columns={fieldTimesOverviewColumns} data={fieldTimesOverviewData} type={"fieldData"} testId={"field-times-overview-table"}/>
                        </div>
                        : fieldTimesOverviewError ?
                          <div className="d-flex justify-content-center flex-grow-1">
                            <p className="text-center my-auto" data-testid="field-times-error">{fieldTimesOverviewError}</p>
                          </div>
                          : fieldTimesOverviewLoading &&
                            <div className="progress-area d-flex flex-grow-1">
                              <div className="w-100">
                                <ProgressBar className="my-auto" animated now={fieldTimesOverviewProgress}/>
                                <p>{(fieldTimesOverviewProgress !== null && fieldTimesOverviewProgress >= 110) && 'The data is being computed, please wait...'}</p>
                              </div>
                            </div>
                      }
                    </div>
                    <div className={`flip-card-back ${showFieldTimesOverviewVisInfo === true ? 'visible' : showFieldTimesOverviewVisInfo === false ? 'not-visble' : ''}`}>
                      <div className="card-contents">
                        <Row className="g-0 card-title-row">
                          <Col className="p-0">
                            <Card.Title as="h3">Field Times Overview</Card.Title>
                          </Col>
                          <Col className="p-0 text-end card-tooltip">
                            <VscChromeClose size="20px" className="grey-icon" onClick={() => setShowFieldTimesOverviewVisInfo(false)} title="Return to table"/>
                          </Col>
                        </Row>
                        <Row className="g-0 text-content">
                          <Col lg={7} className="ps-0">
                            <Card.Text className="mb-3 subtitle">This table shows the average time spent in each of your form’s fields and is split by <i>Abandoned</i> and <i>Completed</i> sessions.</Card.Text>
                            <Card.Text>The <i>Time Spent</i> is a total of all time spent entering data in a particular field. For this reason, this data best represents time in <code>INPUT</code> or <code>TEXTAREA</code> tags.</Card.Text>
                            <Card.Text>You will find that the time spent in <code>BUTTON</code> or <code>SELECT</code> tags is minimal.</Card.Text>
                            <figure className="text-center img-wrapper">
                              <img id="Field-Times-Img" src={FieldTimesImg} alt="Field-Times-table" className="card-info-img"></img>
                            </figure>
                          </Col>
                          <Col lg={5} className="py-0">
                            <div className="card-tip-box">
                              <h4 className="pb-3">How to use this and what to look for</h4>
                              <Card.Text>Use this data to sense check how long visitors are taking to complete each field. If they are taking longer than you might expect, this may be an indication they are having trouble completing the field successfully.</Card.Text>
                              <Card.Text>Most importantly, look at the difference in time taken between <i>Abandoned</i> and <i>Completed</i> sessions. If visitors who abandon their session take significantly longer on a field than those who complete, it is very likely that there
                                is an issue with that field that is driving abandonment.</Card.Text>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <div id="hidden-charts-for-pdf-print">
                {/* NB. A page can only display up to 70 fields in the horizontal chart image without squashing it. We limit Custom Events, but are not limiting here ATM */}
                {fieldAbandonsData &&
                  <ReactChart
                    id="field-abandons-chart-copy"
                    type="bar"
                    data={fieldAbandonsData}
                    width={1050}
                    height={400}
                    options={{
                      responsive: false, // Fix size for PDF export
                      indexAxis: 'x',
                      grid: {
                        offset: false,
                      },
                      scales: {
                        'first-y-axis': {
                          title: {
                            text: 'Abandon rate for field',
                            display: true,
                          },
                          type: 'linear',
                          beginAtZero: false,
                          position: 'left',
                          ticks: {
                            callback: (value) => value.toLocaleString(undefined, {maximumFractionDigits: 2}) + '%',
                          },
                        },
                        'second-y-axis': {
                          title: {
                            text: 'Abandon count for field',
                            display: true,
                          },
                          beginAtZero: true,
                          position: 'right',
                          ticks: {
                            callback: (value) => value.toLocaleString(),
                          },
                          grid: {
                            display: false,
                          },
                        },
                        x: {
                          type: 'timeseries',
                          time: {
                            unit: granularity,
                            displayFormats: {
                              hour: fieldAbandonsData.labels.length > 48 ? 'MMM DD' : 'HH[h]',
                              day: 'MMM DD',
                              week: 'MMM DD',
                              month: 'MMM YYYY',
                            },
                            isoWeekday: true,
                          },
                        },
                      },
                      elements: {
                        line: {
                          tension: 0.05
                        }
                      },
                      plugins: {
                        legend: {
                          labels: {
                            boxWidth: 20
                          }
                        },
                      },
                      animation: {
                        onComplete: () => {
                          if (!dataLoaded) {
                            const chart = Chart.getChart('field-abandons-chart-copy');
                            if (chart) setFieldAbandonsChartImgUrl(chart.toBase64Image());
                          }
                        }
                      }
                    }}
                  />}
                {totalFieldReturnsData &&
                  <ReactChart
                    id="total-field-returns-chart-copy"
                    type="bar"
                    data={totalFieldReturnsDataForPdf}
                    width={1050}
                    height={100 + (totalFieldReturnsDataForPdf.labels.length * 20)}
                    options={{
                      responsive: false, // Fix size for PDF export
                      indexAxis: 'y',
                      scales: {
                        y: {
                          title: {
                            text: 'Fields',
                            display: true,
                          },
                          type: 'category',
                          stacked: true,
                          ticks: {
                            callback: (value, index, values) => {
                              const label = totalFieldReturnsDataForPdf.labels[index];
                              return (label.length > 60 ? label.substring(0, 57) + '...' : label);
                            },
                          },
                        },
                        x: {
                          title: {
                            text: 'Count',
                            display: true,
                          },
                          type: 'linear',
                          stacked: true,
                        },
                      },
                      plugins: {
                        legend: {
                          labels: {
                            boxWidth: 20
                          }
                        },
                      },
                      animation: {
                        onComplete: () => {
                          if (!dataLoaded) {
                            const chart = Chart.getChart('total-field-returns-chart-copy');
                            if (chart) setTotalFieldReturnsChartImgUrl(chart.toBase64Image());
                          }
                        }
                      }
                    }}
                  />}
              </div>
            </Col>
          </Col> :
          <NoFormsMsg mixpanel={mixpanel} page={'FieldData'}/>
        }
        <SessionReplayCTAModal show={showSessionReplayCTAModal} organisation={query?.form?.organisation} handleCloseModal={() => setShowSessionReplayCTAModal(false)}/>
      </div>
    </Container>
  );
};

export default FieldData;
