import React, { useState, useRef, useEffect } from 'react';
import { useTable, useGlobalFilter, useSortBy, usePagination,useFlexLayout, useColumnOrder } from 'react-table';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import ProgressBar from 'react-bootstrap/ProgressBar';
import arrayMove from 'array-move';
import { FaPlus } from 'react-icons/fa';
import { BiColumns } from 'react-icons/bi';
import { FiMinimize2, FiMaximize2 } from 'react-icons/fi';

import './SegmentComparisonFieldTable.scss';

import SegmentConfigField from './SegmentConfigField';

const calculateStickyWidthForCell = ({cells, cellIndex}) => {
  let width = 0;
  for (let x = 0; x < cellIndex; x++) {
    width += (cells[x].column || cells[x]).width;
  }
  return width;
};

function GlobalFilter({globalFilter, setGlobalFilter}) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = (value) => setGlobalFilter(value || undefined);

  React.useEffect(() => {
    // Apply the filter on a new metric
    if (value && !globalFilter) setGlobalFilter(value);
  }, [value, globalFilter, setGlobalFilter]);

  return (
    <span className="datatable-search-filter">
      <label className="me-2 mb-0">Search:</label>
      <input
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
      />
    </span>
  );
}

const maxSegmentsLength = 20;

const SegmentComparisonFieldTable = ({
  tableError,
  tableLoading,
  tableLoadingProgress,
  showProgressBar,
  columns,
  data,
  filtersLoading,
  availableFilters,
  filtersError,
  handleCreateTempSegment,
  handleCancelTempSegment,
  handleCreateNewSegment,
  handleDeleteSegment,
  handleSaveEditedSegment,
  handleReorderSegment,
  comparisonForm,
  handleCreateDuplicateSegment,
}) => {
  // Add custom sort to ensure null/'' orders are positioned at the bottom of the table
  if (columns?.length) columns.find((col) => col.accessor === 'order').sortType = (rowA, rowB, columnID, desc) => {
    const a = rowA.values[columnID];
    const b = rowB.values[columnID];
    return desc ? (b === null || b === '') - (a === null || a === '') : (a === null || a === '') - (b === null || b === '');
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    nextPage,
    previousPage,
    setPageSize,
    state,
    rows,
    prepareRow,
    setColumnOrder,
    allColumns,
    setGlobalFilter,
    setHiddenColumns,
  } = useTable({
    columns,
    data,
    initialState: {
      pageSize: 10,
      hiddenColumns: ['order', 'htmlTagName', 'htmlType', 'htmlName', 'htmlId'],
      sortBy: [{ id: 'all', desc: true }],
    },
    autoResetHiddenColumns: false, // Retains hidden columns on data changes
    autoResetSortBy: false,  // Retains sort on data changes
  },
  useColumnOrder,
  useFlexLayout,
  useGlobalFilter,
  useSortBy,
  usePagination,
  );

  const currentColOrder = useRef();
  const tableScrollRef = useRef();
  const [draggingOver, setDraggingOver] = useState();
  const [dragging, setDragging] = useState();
  const [dragCompletedOn, setDragCompletedOn] = useState();
  const nonSegmentColumnIds = ['order', 'label', 'htmlTagName', 'htmlType', 'htmlName', 'htmlId', 'all'];
  const [endTempSegment, setEndTempSegment] = useState();
  const segmentCount = columns.filter(c => c.type === 'temp' || c.type === 'segment')?.length;
  const dataFetchedForSegmentUuids = data?.length && Object.keys(data[0]).filter(k => !nonSegmentColumnIds.includes(k));
  const [expandIDRow, setExpandIDRow] = useState(false);
  const [expandNameRow, setExpandNameRow] = useState(false);

  useEffect(() => {
    if (dragCompletedOn) setTimeout(() => {setDragCompletedOn(null);}, 900);
  }, [dragCompletedOn]);

  // Set the latest temp segment to scroll to
  useEffect(() => {
    if (columns) {
      const tempSegments = columns.filter(c => c.type === 'temp');
      setEndTempSegment(tempSegments.length ? tempSegments.slice(-1)[0].uuid : null);
    }
  }, [columns]);

  // Scroll to the latest temp segment
  useEffect(() => {
    if (tableScrollRef.current && endTempSegment) tableScrollRef.current.scrollLeft = tableScrollRef.current.scrollWidth;
  }, [tableScrollRef, endTempSegment]);

  return (
    <div className="segment-comparison-field-table">
      {columns?.length > 0 &&
      <div className="d-flex flex-wrap justify-content-end table-search-row browser-only align-items-center pb-2">
        <div className="d-flex flex-wrap align-items-center">
          <GlobalFilter globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter}/>
          <OverlayTrigger placement="bottom" trigger={'click'} rootClose overlay={<Popover id="datatable-column-toggle">
            <Popover.Body>
              {allColumns.map((column) => {
                const canBeHidden = ['order', 'htmlTagName', 'htmlType', 'htmlName', 'htmlId', 'all'];
                const hiddenDisplayName = {
                  order: 'Order',
                  all: 'All Sessions',
                  htmlTagName: 'HTML Tag Name',
                  htmlType: 'HTML Type',
                  htmlName: 'HTML Name',
                  htmlId: 'HTML ID',
                };

                if (!canBeHidden.includes(column.id)) return null;
                return (
                  <div key={column.id}>
                    <label>
                      <input type="checkbox" onChange={() => {
                        // Using the expected ...column.getToggleHiddenProps() clashed with the autoResetHiddenColumns setting so we manually hide:
                        if (state.hiddenColumns.includes(column.id)) setHiddenColumns(state.hiddenColumns.filter(c => c !== column.id));
                        if (!state.hiddenColumns.includes(column.id)) setHiddenColumns(state.hiddenColumns.concat(column.id));
                      }} checked={!state.hiddenColumns.includes(column.id)}/>{' '}
                      {hiddenDisplayName[column.id]}
                    </label>
                  </div>
                );
              })}
            </Popover.Body>
          </Popover>}>
            <Button className="px-3 mx-2 d-flex align-items-center toggle-columns-btn" variant="outline-secondary"><BiColumns className="me-1 plus-inside-btn" size="16px"/>Manage Columns</Button>
          </OverlayTrigger>
          {segmentCount < (maxSegmentsLength + 1) && <Button variant="outline-primary" className="px-3 d-flex align-items-center" onClick={handleCreateTempSegment} data-testid="add-segment-btn"><FaPlus className="me-1" />Add Segment</Button>}
          {segmentCount >= (maxSegmentsLength + 1) &&
            <OverlayTrigger overlay={<Tooltip id="disabled-add-stage-tooltip">Max {maxSegmentsLength} segments</Tooltip>}>
              <span className="d-inline-block">
                <Button variant="outline-primary" className="px-3 d-flex align-items-center" style={{ pointerEvents: 'none' }} disabled={true}><FaPlus className="me-1" />Add Segment</Button>
              </span>
            </OverlayTrigger>}
        </div>
      </div>}
      <div className="paginated-table-wrap">
        <div className="table paginated-table" {...getTableProps()} ref={tableScrollRef}>
          {tableError && <p className="error-message">{tableError}</p>}

          {headerGroups.map((headerGroup, i) => {
            const nonSegmentHeaders = headerGroup.headers.filter(column => nonSegmentColumnIds.includes(column.id));
            const segmentHeaders = headerGroup.headers.filter(column => !nonSegmentColumnIds.includes(column.id));
            return (
              <DragDropContext
                key={`header-group${i}`}
                onBeforeDragStart={() => {setDragging(true); setDragCompletedOn(null);}}
                onDragStart={() => {
                  currentColOrder.current = allColumns?.map((o) => o.id); // Save current column order to allow drop into right place
                }}
                onDragUpdate={(dragUpdateObj) => {
                  if (dragUpdateObj.destination !== null && currentColOrder.current) setDraggingOver(currentColOrder.current[dragUpdateObj.destination.index + (nonSegmentColumnIds.length)]);
                }}
                onDragEnd={(result) => {
                  setDraggingOver(null);
                  setDragging(false);
                  if (!result.destination) return;
                  if (currentColOrder.current) {
                    const increaseIndexBy = (nonSegmentColumnIds.length);
                    const destinationIndex = result.destination.index + increaseIndexBy;

                    // Don't allow drop onto temp segments
                    if (currentColOrder.current[destinationIndex].includes('temp')) return;

                    const newCols = arrayMove(currentColOrder.current, (result.source.index + increaseIndexBy), destinationIndex);
                    setColumnOrder(newCols); // Reorder first in situ before then saving the new order
                    setDragCompletedOn(newCols[destinationIndex]);
                    handleReorderSegment(newCols.filter(c => (!nonSegmentColumnIds.includes(c) && !c.includes('temp'))));
                  }
                }}>
                <div
                  {...headerGroup.getHeaderGroupProps()}
                  className="row flex-nowrap g-0 header-group align-items-end">
                  <div className={`non-segment-container d-inline-flex align-items-stretch align-self-stretch`}>
                    {nonSegmentHeaders.map((column, index) => (
                      <div {...column.getHeaderProps()} style={{
                        minWidth: ((column.id === 'htmlName' && expandNameRow) || (column.id === 'htmlId' && expandIDRow)) ? column.width + 50 : column.width,
                        width: ((column.id === 'htmlName' && expandNameRow) || (column.id === 'htmlId' && expandIDRow)) ? null : column.width,
                        left: calculateStickyWidthForCell({cells: nonSegmentHeaders, cellIndex: index})}}
                      className="cell header d-flex align-items-end justify-content-center">
                        <div className={`centre-header-with-right-icon ${column.id}`}>
                          {column.render("Header")}
                          {(column.id === 'htmlName') &&
                            <span onClick={() => setExpandNameRow(!expandNameRow)} className="px-2 pb-1 expand-column-icon">
                              {expandNameRow ? <FiMinimize2 size="14px" title="Minimise column"/> : <FiMaximize2 size="14px" title="Expand column"/>}
                            </span>}
                          {(column.id === 'htmlId') &&
                              <span onClick={() => setExpandIDRow(!expandIDRow)} className="px-2 pb-1 expand-column-icon">
                                {expandIDRow ? <FiMinimize2 size="14px" title="Minimise column"/> : <FiMaximize2 size="14px" title="Expand column"/>}
                              </span>}
                        </div>
                        <div {...column.getSortByToggleProps()} title="Sort this column" className={`table-sort-icons d-flex flex-column ${column.id === 'order' ? 'py-2' : 'p-2'} browser-only`}>
                          {column.canSort && <>
                            <i className={`fa fa-sort-up ${(column.isSorted && !column.isSortedDesc) ? 'sorted' : ''}`}></i>
                            <i className={`fa fa-sort-down ${(column.isSorted && column.isSortedDesc) ? 'sorted' : ''}`}></i>
                          </>}
                        </div>
                      </div>
                    ))}
                  </div>
                  <Droppable droppableId="droppable" direction="horizontal">
                    {(provided, snapshot) => (<>
                      <div ref={provided.innerRef} className="segment-container d-inline-flex align-items-stretch">
                        {segmentHeaders.map((column, index) => (
                          <Draggable key={column.id} draggableId={column.id} index={index} isDragDisabled={!column.accessor || column.type === 'temp'}>
                            {(provided, snapshot) => {
                              return (<>
                                <div {...column.getHeaderProps()} style={{minWidth: column.width, width: column.width}}
                                  className={`cell header mt-2 d-flex flex-column align-items-end justify-content-center
                                          ${(dragging === true && column.id === draggingOver) ? 'drag-over' : ''}
                                          ${(dragging === false && column.id === dragCompletedOn) ? 'drag-completed' : ''}
                                          ${!nonSegmentColumnIds.includes(column.id) ? 'segment-col' : ''}`}>
                                  <div {...provided.draggableProps} ref={provided.innerRef}
                                    style={{...provided.draggableProps.style,
                                      width: snapshot.isDragging ? null : '100%',
                                      height: snapshot.isDragging ? null : '100%',
                                      background: snapshot.isDragging ? '#c6d2f3' : null,
                                      zIndex:  snapshot.isDragging ? 3 : 1,
                                      ...(!snapshot.isDragging && {transform: 'translate(0,0)'}), // Prevents the DnD default switch animation
                                      ...(snapshot.isDropAnimating && {transitionDuration: '0.1s'})
                                    }}>
                                    <div className="column segment">
                                      <SegmentConfigField
                                        dragHandleProps={provided.dragHandleProps}
                                        column={column}
                                        index={index}
                                        handleDeleteSegment={handleDeleteSegment}
                                        availableFilters={availableFilters}
                                        filtersError={filtersError}
                                        filtersLoading={filtersLoading}
                                        handleSaveEditedSegment={handleSaveEditedSegment}
                                        handleCancelTempSegment={handleCancelTempSegment}
                                        handleCreateNewSegment={handleCreateNewSegment}
                                        segmentCount={segmentCount}
                                        comparisonForm={comparisonForm}
                                        handleCreateDuplicateSegment={handleCreateDuplicateSegment}
                                      />
                                    </div>
                                  </div>
                                  <div>
                                    <div {...column.getSortByToggleProps()} title="Sort this column" className="table-sort-icons d-flex flex-column px-2 pb-2 pt-3 browser-only">
                                      {column.canSort && !snapshot.isDragging && <>
                                        <i className={`fa fa-sort-up ${(column.isSorted && !column.isSortedDesc) ? 'sorted' : ''}`}></i>
                                        <i className={`fa fa-sort-down ${(column.isSorted && column.isSortedDesc) ? 'sorted' : ''}`}></i>
                                      </>}
                                    </div>
                                  </div>
                                </div>
                              </>);
                            }}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    </>
                    )}
                  </Droppable>
                </div>
              </DragDropContext>
            );
          })}

          {!showProgressBar &&
           <div className="table-body rows" {...getTableBodyProps()}>
             {page.map(
               (row, i) =>
                 prepareRow(row) || (
                   <div {...row.getRowProps()} className="row flex-nowrap g-0 body" data-testid={`field-row-${i}`}>
                     {row.cells.map((cell, i) => (
                       <div {...cell.getCellProps()} key={i} title={(cell.column.id === 'htmlName' || cell.column.id === 'htmlId') ? cell.value : null}
                         className={`cell align-items-center d-flex justify-content-center
                          ${nonSegmentColumnIds.includes(cell.column.id) ? 'non-segment-col' : ''}
                          ${!nonSegmentColumnIds.includes(cell.column.id) ? 'segment-col' : ''}
                          ${dataFetchedForSegmentUuids.includes(cell.column.id) ? 'data-fetched' : ''}
                          ${(dragging === true && cell.column.id === draggingOver) ? 'drag-over' : ''}
                          ${(dragging === false && cell.column.id === dragCompletedOn) ? 'drag-completed' : ''}`}
                         style={{
                           minWidth: ((cell.column.id === 'htmlName' && expandNameRow) || (cell.column.id === 'htmlId' && expandIDRow)) ?  cell.column.width + 50 : cell.column.width,
                           width: ((cell.column.id === 'htmlName' && expandNameRow) || (cell.column.id === 'htmlId' && expandIDRow)) ? null : cell.column.width,
                           ...nonSegmentColumnIds.includes(cell.column.id) && {
                             left: calculateStickyWidthForCell({cells: row.cells, cellIndex: i})}
                         }}>
                         {(cell.column.id === 'htmlName' || cell.column.id === 'htmlId') ?
                           <div className={`ellipsis-cell-text ${((cell.column.id === 'htmlName' && expandNameRow) || (cell.column.id === 'htmlId' && expandIDRow)) ? 'expand' : ''} `}>
                             {cell.render("Cell")}
                           </div> :
                           cell.render("Cell")}
                       </div>
                     ))}
                   </div>
                 )
             )}
           </div>}

          {tableLoading && showProgressBar &&
          <div className="progress-area d-flex flex-grow-1">
            <ProgressBar  className="my-auto" animated now={tableLoadingProgress}/>
          </div>}

          {!tableLoading && !data.length > 0 && columns.length > 0 && columns.filter(c => c.type === 'segment').length > 0 &&
            <div className="d-flex data-error align-items-center justify-content-center">
              <p>No data to display</p>
            </div>}
        </div>

        {!showProgressBar && rows.length > 0 &&
          <div className="d-flex justify-content-between align-items-center paginate-info-row">
            <FormGroup className="d-flex align-items-center" controlId="show-rows">
              <p className="mb-0">Fields per page:</p>
              <Form.Select value={state.pageSize} aria-label="Fields per page" onChange={e => setPageSize(Number(e.target.value))} className="ms-2">
                {[10, 25, 50, 'all'].map((size) => (<option key={size} value={(size === 'all') ? rows.length : size}>{size}</option>))}
              </Form.Select>
            </FormGroup>
            <div className="pagination p-2 justify-content-center">
              {rows?.length > 10 && <>
                <div className={`page-item ${!canPreviousPage ? 'disabled' : ''}`}>
                  <button className="page-link" onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</button>
                </div>
                <div className={`page-item ${!canNextPage ? 'disabled' : ''}`}>
                  <button className="page-link" onClick={() => nextPage()} disabled={!canNextPage}>Next</button>
                </div>
              </>}
            </div>
            <div>
              <p className="mb-0">Showing page {state.pageIndex +1} of {pageCount} pages</p>
            </div>
          </div>}
      </div>
    </div>
  );
};

export default React.memo(SegmentComparisonFieldTable);
