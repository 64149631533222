import React, { useContext, useState, useEffect } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Select from 'react-select';
import { FaInfoCircle } from "react-icons/fa";

import AppContext from '../AppContext';
import NavBar from '../NavBar';
import NoFormsMsg from '../Components/NoFormsMsg';
import AppAlerts from '../Components/AppAlerts';
import FeedbackRow from '../Components/FeedbackRow';
import api from '../api';
import { usePrevious } from '../hooks';
import { orgDetailsForMixpanel } from '../utils';
import AlertsInfoOverlay from './AlertsInfoOverlay';

import './Alerts.scss';

const Alerts = ({mixpanel}) => {
  const { currentUser, currentOrg, setCurrentOrg, formsGroupedByOrg } = useContext(AppContext);
  const history = useHistory();
  const {uuid: orgUuid} = useParams();

  const [orgsLoading, setOrgsLoading] = useState(false);
  const [orgsError, setOrgsError] = useState(null);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [orgsByUuid, setOrgsByUuid] = useState({});
  const [forms, setForms] = useState([]);
  const [formsLoading, setFormsLoading] = useState(true);
  const [formsError, setFormsError] = useState(null);
  const [alertsByFormUuid, setAlertsByFormUuid] = useState({});
  const [loadAlertsError, setLoadAlertsError] = useState(null);
  const [showInfo, setShowInfo] = useState(null);
  const [reportVisible, setReportVisible] = useState(true);

  const prevSelectedOrg = usePrevious(selectedOrg);

  // Identify the mixpanel user on page load for any subsequent tracking
  useEffect(() => {
    mixpanel.identify(currentUser.email);
  }, [mixpanel, currentUser.email]);

  // Load all available orgs for the user
  useEffect(() => {
    const fetchOrgs = async () => {
      try {
        setOrgsLoading(true);
        const { data: { organisations } } = await api.get('/organisations');

        const orgsByUuid = {};
        for (const org of organisations) {
          if (!orgsByUuid.hasOwnProperty(org.uuid)) orgsByUuid[org.uuid] = org;
        }
        setOrgsByUuid(orgsByUuid);
        setOrgsLoading(false);
      } catch (e) {
        setOrgsError('Something went wrong fetching organisations.');
        setOrgsLoading(false);
      }
    };

    fetchOrgs();
  }, [currentUser]);

  // Once available organisations have loaded - select incoming
  useEffect(() => {
    if (!selectedOrg && Object.keys(orgsByUuid).length) {
      const incomingOrg = orgsByUuid[orgUuid];
      setSelectedOrg(incomingOrg);

      if (incomingOrg) {
        // Set the app-wide organisation
        if ((!currentOrg && incomingOrg) ||
        (currentOrg?.uuid && incomingOrg.uuid && (incomingOrg.uuid !== currentOrg.uuid))) setCurrentOrg(incomingOrg);
      }
    }
  }, [mixpanel, orgUuid, orgsByUuid, selectedOrg, currentOrg, setCurrentOrg]);

  // Org selected so send mixpanel page view
  useEffect(() => {
    if (selectedOrg) {
      mixpanel.track('Page View', { page: 'Alerts', ...orgDetailsForMixpanel(selectedOrg)});
    }
  }, [mixpanel, selectedOrg]);

  // Load selected orgs alerts
  useEffect(() => {
    const loadAlertsPerForm = async () => {
      try {
        setAlertsByFormUuid({});
        const { data: { alerts } } = await api.get('alerts', {
          params: {
            organisationUuid: orgUuid,
          }
        });

        setAlertsByFormUuid(alerts.reduce((acc, alert) => {
          if (!acc[alert.form.uuid]) acc[alert.form.uuid] = [];
          acc[alert.form.uuid].push(alert);
          return acc;
        }, {}));
      } catch (e) {
        setLoadAlertsError('Oops, something went wrong fetching alerts. Please try again.');
      }
    };

    loadAlertsPerForm();
  }, [orgUuid]);

  // Once the organisation is selected, fetch the forms
  useEffect(() => {
    const loadForms = async () => {
      try {
        setFormsLoading(true);
        setFormsError(null);
        setForms([]);

        const { data: { forms } } = await api.get(`/organisations/${selectedOrg.uuid}/forms`);
        if (forms.length) setForms(forms);
        setFormsLoading(false);
      } catch (e) {
        setFormsLoading(false);
        setFormsError('Oops, something went wrong loading forms.');
      }
    };

    if (selectedOrg) loadForms();

  }, [selectedOrg]);

  // Selected organisation changed - so update app-wide
  useEffect(() => {
    if (prevSelectedOrg?.uuid && selectedOrg?.uuid && (prevSelectedOrg.uuid !== selectedOrg.uuid)) {
      setCurrentOrg(selectedOrg);
    }
  }, [prevSelectedOrg, selectedOrg, setCurrentOrg, formsGroupedByOrg]);

  const handleOrgChange = (org) => {
    setSelectedOrg(org);
    history.push(`/organisations/${org.uuid}/alerts`);
    mixpanel.track('Selected Organisation', { page: 'Alerts', ...orgDetailsForMixpanel(selectedOrg) });
  };

  const displayAlertType = (alert) => {
    if (alert.event) return `${alert.event} count`;
    if (alert.sessionOutcome) return `${alert.sessionOutcome} session count`;
    if (alert.metric) return `${alert.metric.replace('_', ' ')} %`;
  };

  useEffect(() => {
    if(!currentUser.accountManager) setReportVisible(!!currentOrg?.reportAccessEnabled);
  }, [currentOrg?.reportAccessEnabled, currentUser.accountManager]);

  return (
    <Container fluid className="page" id="alerts-page">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
        <title>Alerts</title>
      </Helmet>
      <div className="nav-wrapper">
        <NavBar mixpanel={mixpanel}/>
        <Row className="g-0 nav-primary">
          <Col md={3} className="pt-0 pb-2 mt-1" id="organisation-select">
            <Select
              styles={{
                control: (styles, state) => ({...styles,
                  border: '1px solid #EBEDF2',
                }),
                option: (styles, state) => ({...styles,
                  color: '#3f4047',
                  backgroundColor: state.selectProps.value && (state.selectProps.value.uuid === state.value) ? "#E2E5EC" : null,
                  '&:hover': {backgroundColor: state.isFocused ? '#F4F5F8' : null}
                }),
                menu: (styles, state) => ({...styles,
                  marginTop: '1px',
                  borderRadius: '4px',
                  border: '1px solid #EBEDF2',
                  boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
                }),
                dropdownIndicator: (styles, state) => ({...styles,
                  cursor: 'pointer',
                  transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
                  transition: 'transform .5s ease',
                }),
              }}
              options={Object.values(orgsByUuid).length ? Object.values(orgsByUuid).map((org) => ({...org, value: org.uuid, label: org.name})) : orgsError || (!orgsLoading && !Object.keys(orgsByUuid).length) ? [] :
                [{ selectable: false, value: null, label: <><i className="fa fa-circle-o-notch fa-spin fa-fw" /> Loading...</>}]}
              isOptionDisabled={option => option.hasOwnProperty('selectable') && !option.selectable}
              onChange={handleOrgChange}
              placeholder="Select an organisation..."
              value={selectedOrg && selectedOrg.name && {...selectedOrg, label: selectedOrg.name, value: selectedOrg.uuid}}
            />
          </Col>
          <Col md={5} className="pt-0 pb-2 mt-1 ps-0 d-flex align-items-center">
            {loadAlertsError && <p className="error-message m-0">{loadAlertsError}</p>}
          </Col>
        </Row>
      </div>
      <div className="main-content">
        {(!formsLoading && !formsError && !forms.length > 0) ?
          <NoFormsMsg mixpanel={mixpanel} page={'Alerts'}/> :
          <Col className="center-column justify-content-md-center">
            <div className="pb-1">
              <FeedbackRow
                classList={['allow-scroll-under-nav']}
                mixpanel={mixpanel}
                page={'Alerts'}
                org={selectedOrg}
                messageContent={'Alerts'} />
            </div>
            <AppAlerts showOrgAlerts={true} />
            <Row className="title-row g-0">
              <Col className={`p-0 original-content ${showInfo ? 'background' : ''}`}>
                {selectedOrg && selectedOrg.name && <h1 id="alerts-title">{`${selectedOrg.name} | Alerts`}</h1>}
              </Col>
              <Col className="p-0 text-end title-tooltips align-self-center">
                <FaInfoCircle id="first-info-icon" size="20px" className="info-circle-icon" onClick={() => {setShowInfo(true); mixpanel.track('Clicked Alerts info', { page: 'Alerts',  ...orgDetailsForMixpanel(selectedOrg) });}} title="How to use"/>
              </Col>
              <AlertsInfoOverlay
                showInfo={showInfo}
                setShowInfo={setShowInfo}
                mixpanel={mixpanel}
              />
            </Row>
            <Col className={`p-0 original-content ${showInfo ? 'background' : ''}`}>
              <p className="page-tagline">Alerts can be configured to inform you when there are low numbers of completions, or high numbers of abandoned sessions on your forms.</p>
              {formsLoading && <p>Loading...</p>}
              {formsError && <p className="text-center">{formsError}</p>}
              {forms && forms.length > 0 && forms.map((form) => (
                <Card key={`form-${form.uuid}-alerts`}>
                  <Card.Body className={reportVisible ? '' : 'blurred-report'}>
                    <Row className="g-0 card-title-row">
                      <Col className="p-0">
                        <Card.Title as="h3">{form.label}</Card.Title>
                      </Col>
                    </Row>
                    {Object.keys(alertsByFormUuid).length > 0 && alertsByFormUuid[form.uuid] &&
                      <Row className="g-0 card-table">
                        <Table borderless hover>
                          <thead className="table-header">
                            <tr>
                              <th>Alert description</th>
                              <th></th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.values(alertsByFormUuid[form.uuid]).map((alert) => (
                              <tr onClick={() => history.push(`/alerts/${alert.uuid}/edit`)} key={`table-row-${alert.uuid}`}>
                                <td className={!alert.enabled ? 'alert-disabled' : ''}>{`${displayAlertType(alert)} is ${alert.comparator === '>' ? 'more than' : alert.comparator === '<' && 'less than'} ${alert.threshold} per hour`}{alert.alertFilters?.length ? <>, for filters:
                                  {alert.alertFilters?.map(({key, value}) => <span className="filter-badge" key={`${key}-${value}`}>{key}: {value}</span>)}
                                </>: ''}</td>
                                <td className={`w-1 ${!alert.enabled ? 'alert-disabled' : ''}`}><span id="alert-enabled-status">{alert.enabled ? 'Enabled' : 'Disabled'}</span></td>
                                <td className="w-1"><Link to={`/alerts/${alert.uuid}/edit`} data-testid={`edit-alert-${alert.uuid}`}>Edit</Link></td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Row>}
                    <Row className="g-0">
                      <Col className="p-0 d-flex justify-content-end">
                        <Link to={`/alerts/new?form[uuid]=${form.uuid}`}>
                          <Button className="pill-btn" data-testid={`add-alert-for-form-${form.uuid}`}>Add</Button>
                        </Link>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              ))}
            </Col>
          </Col>
        }
      </div>
    </Container>
  );
};

export default Alerts;
